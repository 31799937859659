<template>
  <div @click="done" id="generator-export">
    <div class="generator-export-content">
      <template v-if="!showPdfExport && !showXlsExport && !showCsvExport">
        <p>Format für Datenexport auswählen:</p>
        <div class="gs-buttons my-1">
          <a href="#" @click.prevent="showPdfExport = true">PDF</a>
          <!-- <a href="#" @click.prevent="showXlsExport = true">XLS</a>
          <a href="#" @click.prevent="showCsvExport = true">CSV</a> -->
        </div>
      </template>
      <p v-else>Datenexport ...</p>
    </div>
    <GeneratorExportPdf @done="done" :daten="daten" v-if="showPdfExport" />
    <!-- <GeneratorExportRaw @done="done" :daten="daten" :csv="showCsvExport" v-if="showXlsExport || showCsvExport" /> -->
  </div>
</template>

<script>
import GeneratorExportPdf from './GeneratorExportPdf';
// import GeneratorExportRaw from './GeneratorExportRaw';

export default {
  name: 'GeneratorExport',
  props: {
    daten: Object,
  },
  data () {
    return {
      showPdfExport: false,
      showXlsExport: false,
      showCsvExport: false,
    }
  },
  mounted () {
    this.showPdfExport = true
  },
  methods: {
    done (e) {
      if (!e || e.target.id === 'generator-export') {
        this.showPdfExport = false
        this.showXlsExport = false
        this.showCsvExport = false
        this.$emit('done')
      }
    }
  },
  computed: {
  },
  watch: {
  },
  components: {
    GeneratorExportPdf,
    // GeneratorExportRaw
  }
}
</script>

<style scoped>
#generator-export {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0,0,0,0.3);
  z-index: 90;
}
.generator-export-content {
  background: #fff;
  padding: 1rem 2rem;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.75);
  font-size: 2rem;
  text-align: center;
}
.gs-buttons > a {
  display: inline-block;
  width: 30%;
  margin-right: 2%;
  color: #fff;
  padding: 1rem;
  text-align: center;
  font-weight: 700;
  border: 2px solid #0099e7;
  background-color: #0099e7;
  font-size: 1.8rem;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
.gs-buttons > a:not(.disabled):hover, .gs-buttons > a:not(.disabled):focus {
  color: #0099e7;
  background-color: #fff;
}
</style>
