<template>
  <div id="generator-select-frm" :class="closed ? 'closed' : 0">
    <div id="generator-select">
      <button @click="showOptionen = !showOptionen" class="icon-btn g-opt-icon" title="Einstellungen"><img :src="daten.sys.publicPath + 'diagrammgenerator/cog.svg'" class="g-oico"></button>
      <GeneratorOptionen :daten="daten" :show="showOptionen" />
      <div class="gs-auswahl">
        <SelectField v-model="daten.auswahl.bereich" :daten="daten" :options="bereiche" title="Bereich wählen" schritt="1" />
        <SelectField v-model="daten.auswahl.indikator" :daten="daten" :options="indikatoren" title="Indikator wählen" schritt="2" v-if="indikatoren" />
        <SelectField v-model="daten.auswahl.auspraegung" :daten="daten" :options="auspraegungen" title="Ausprägung wählen" schritt="3" v-if="auspraegungen" />
        <SelectField v-model="daten.auswahl.bezug" :daten="daten" :options="bezuege" title="Bezug wählen" schritt="4" v-if="bezuege" />
        <SelectField v-model="daten.auswahl.raumbezug" :daten="daten" :options="raumbezuege" title="Raumbezug wählen" :schritt="bezuege ? 5 : 4" v-if="raumbezuege" />
        <SelectField v-model="daten.auswahl.zeitbezug" :daten="daten" :options="zeitbezuege" title="Zeitbezug wählen" :schritt="bezuege ? 6 : 5" v-if="zeitbezuege" />
        <SelectField v-model="daten.auswahl.darstellung" :daten="daten" :darstellung="darstellungenObj" title="Darstellung wählen" :schritt="bezuege ? 7 : 6" v-if="darstellungenObj" />
        <div class="gs-buttons">
          <a href="#" @click.prevent="exportieren" :class="darstellungenObj && daten.auswahl.darstellung ? '' : 'disabled'">Exportieren</a>
          <a href="#" @click.prevent="reset" :class="indikatoren ? '' : 'disabled'">Zurücksetzen</a>
        </div>
      </div>
    </div>
    <button @click="closed = !closed" class="icon-btn generator-select-opener" title="Auswahl"><img :src="daten.sys.publicPath + 'diagrammgenerator/' + (closed ? 'sort-reverse-variant.svg' : 'close.svg')" class="g-oico"></button>
  </div>
</template>

<script>
import SelectField from './GeneratorSelect/SelectField.vue'
import GeneratorOptionen from './GeneratorOptionen.vue'

export default {
  name: 'GeneratorSelect',
  props: {
    daten: Object,
  },
  data () {
    return {
      showOptionen: false,
      closed: false
    }
  },
  mounted () {
  },
  methods: {
    exportieren () {
      if (this.darstellungenObj && this.daten.auswahl.darstellung) {
        this.$emit('exportieren')
      }
    },
    reset () {
      this.$set(this.daten, 'auswahl', {
        bereich: null,
        indikator: null,
        auspraegung: null,
        bezug: null,
        raumbezug: null,
        zeitbezug: null,
        darstellung: null
      })
    },
    getProp (obj, props) {
      if (props.length > 1) {
        if (obj[props[0]]) {
          return this.getProp(obj[props[0]], props.slice(1))
        } else {
          return undefined
        }
      } else {
        return obj[props[0]]
      }
    },
    ObjArrayToObj (arr, prop) {
      let aObj = {}
      arr.forEach(arrObj => {
        aObj[arrObj[prop]] = arrObj
      })
      return aObj
    },
    sortedDataList (obj) {
      return Object.keys(obj).map(aKey => {
        return { id: aKey, title: aKey, sort: obj[aKey].sort }
      }).sort((a, b) => ((a.sort || a.sort === 0) && (b.sort || b.sort === 0)) ? ((a.sort > b.sort) ? 1 : (a.sort === b.sort) ? ((a.id > b.id) ? 1 : -1) : -1) : ((a.id > b.id) ? 1 : -1))
    },
    sortedByDateDataList (obj) {
      return Object.keys(obj).map(aKey => {
        return { id: aKey, title: aKey, sort: obj[aKey].sort }
      }).sort((a, b) => ((a.title === 'Mehrere Jahre' || a.title < b.title) ? 1 : (a.title > b.title) ? -1 : 0))
    }
  },
  computed: {
    bereiche () {
      return this.sortedDataList(this.daten.mainData.akt.bereiche)
    },
    bereicheObj () {
      if (this.bereiche) {
        return this.ObjArrayToObj(this.bereiche, 'id')
      }
      return null
    },
    indikatoren () {
      let aObj = this.getProp(this.daten.mainData.akt.bereiche, [this.daten.auswahl.bereich])
      if (aObj) {
        return this.sortedDataList(aObj.indikatoren)
      }
      return null
    },
    indikatorenObj () {
      if (this.indikatoren) {
        return this.ObjArrayToObj(this.indikatoren, 'id')
      }
      return null
    },
    auspraegungen () {
      let aObj = this.getProp(this.daten.mainData.akt.bereiche, [this.daten.auswahl.bereich, 'indikatoren', this.daten.auswahl.indikator])
      if (aObj) {
        let aAuspraegungen = []
        let aAuspraegungenKeys = []
        Object.keys(aObj.auspraegungen).forEach(aAuspraegung => {
          let aAuspraegungKey = aAuspraegung.indexOf('|') > -1 ? aAuspraegung.split('|')[0] + '|' : aAuspraegung
          if (aAuspraegungenKeys.indexOf(aAuspraegungKey) < 0) {
            aAuspraegungenKeys.push(aAuspraegungKey)
            aAuspraegungen.push({ id: aAuspraegungKey, title: aAuspraegung.indexOf('|') > -1 ? aAuspraegung.split('|')[0] : aAuspraegung, sort: aObj.auspraegungen[aAuspraegung].sort })
          }
        })
        return aAuspraegungen.sort((a, b) => ((a.sort || a.sort === 0) && (b.sort || b.sort === 0)) ? ((a.sort > b.sort) ? 1 : (a.sort === b.sort) ? ((a.id > b.id) ? 1 : -1) : -1) : ((a.id > b.id) ? 1 : -1))
      }
      return null
    },
    auspraegungenObj () {
      if (this.auspraegungen) {
        return this.ObjArrayToObj(this.auspraegungen, 'id')
      }
      return null
    },
    bezuege () {
      if (this.daten.auswahl.auspraegung && this.daten.auswahl.auspraegung.indexOf('|') > -1) {
        let aObj = this.getProp(this.daten.mainData.akt.bereiche, [this.daten.auswahl.bereich, 'indikatoren', this.daten.auswahl.indikator])
        if (aObj) {
          let aBezuege = []
          Object.keys(aObj.auspraegungen).forEach(aAuspraegung => {
            if (aAuspraegung.indexOf(this.daten.auswahl.auspraegung) === 0) {
              aBezuege.push({ id: aAuspraegung, title: aAuspraegung.split('|')[1], sort: aObj.auspraegungen[aAuspraegung].sort })
            }
          })
          return aBezuege.sort((a, b) => ((a.sort || a.sort === 0) && (b.sort || b.sort === 0)) ? ((a.sort > b.sort) ? 1 : (a.sort === b.sort) ? ((a.id > b.id) ? 1 : -1) : -1) : ((a.id > b.id) ? 1 : -1))
        }
      }
      return null
    },
    bezuegeObj () {
      if (this.bezuege) {
        return this.ObjArrayToObj(this.bezuege, 'id')
      }
      return null
    },
    raumbezuege () {
      let aObj = this.getProp(this.daten.mainData.akt.bereiche, [this.daten.auswahl.bereich, 'indikatoren', this.daten.auswahl.indikator, 'auspraegungen', (this.daten.auswahl.bezug || this.daten.auswahl.auspraegung)])
      if (aObj) {
        return this.sortedDataList(aObj.raumbezuege)
      }
      return null
    },
    raumbezuegeObj () {
      if (this.raumbezuege) {
        return this.ObjArrayToObj(this.raumbezuege, 'id')
      }
      return null
    },
    zeitbezuege () {
      let aObj = this.getProp(this.daten.mainData.akt.bereiche, [this.daten.auswahl.bereich, 'indikatoren', this.daten.auswahl.indikator, 'auspraegungen', (this.daten.auswahl.bezug || this.daten.auswahl.auspraegung), 'raumbezuege', this.daten.auswahl.raumbezug])
      if (aObj) {
        return this.sortedByDateDataList(aObj.zeitbezuege)
      }
      return null
    },
    zeitbezuegeObj () {
      if (this.zeitbezuege) {
        return this.ObjArrayToObj(this.zeitbezuege, 'id')
      }
      return null
    },
    darstellungen () {
      let aObj = this.getProp(this.daten.mainData.akt.bereiche, [this.daten.auswahl.bereich, 'indikatoren', this.daten.auswahl.indikator, 'auspraegungen', (this.daten.auswahl.bezug || this.daten.auswahl.auspraegung), 'raumbezuege', this.daten.auswahl.raumbezug, 'zeitbezuege', this.daten.auswahl.zeitbezug])
      if (aObj) {
        return this.sortedDataList(aObj.darstellungen)
      }
      return null
    },
    darstellungenObj () {
      if (this.darstellungen) {
        return this.ObjArrayToObj(this.darstellungen, 'id')
      }
      return null
    }
  },
  watch: {
    'daten.auswahl.bereich' () {
      this.daten.auswahl.indikator = null
    },
    auspraegungen () {
      this.daten.auswahl.auspraegung = this.auspraegungen ? this.auspraegungen[0].id : null
    },
    bezuege () {
      this.daten.auswahl.bezug = this.bezuege ? this.bezuege[0].id : null
    },
    raumbezuege () {
      this.daten.auswahl.raumbezug = this.raumbezuege ? (this.raumbezuegeObj[this.daten.auswahl.raumbezug] ? this.raumbezuegeObj[this.daten.auswahl.raumbezug].id : this.raumbezuege[0].id) : null
    },
    zeitbezuege () {
      this.daten.auswahl.zeitbezug = this.zeitbezuege ? (this.zeitbezuegeObj[this.daten.auswahl.zeitbezug] ? this.zeitbezuegeObj[this.daten.auswahl.zeitbezug].id : this.zeitbezuege[0].id) : null
    },
    darstellungen () {
      this.daten.auswahl.darstellung = this.darstellungen ? (this.darstellungenObj[this.daten.auswahl.darstellung] ? this.darstellungenObj[this.daten.auswahl.darstellung].id : this.darstellungen[0].id) : null
    }
  },
  components: {
    SelectField,
    GeneratorOptionen
  }
}
</script>

<style scoped>
#generator-select-frm {
  position: relative;
  width: 33.3vw;
  min-width: 38rem;
}
#generator-select {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  padding: 3rem 7rem;
  min-width: 36rem;
  background-color: #d9f0fb;
}
.gs-buttons > a {
  display: inline-block;
  width: 48%;
  margin-right: 2%;
  color: #fff;
  padding: 1rem;
  text-align: center;
  font-weight: 700;
  border: 2px solid #0099e7;
  background-color: #0099e7;
  font-size: 1.8rem;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
.gs-buttons > a.disabled {
  opacity: 0.5;
  cursor: default;
}
.gs-buttons > a:not(.disabled):hover, .gs-buttons > a:not(.disabled):focus {
  color: #0099e7;
  background-color: #fff;
}
.g-opt-icon {
  position: absolute;
  right: 2rem;
  top: 2rem;
  z-index: 7;
}
.generator-select-opener {
  display: none;
}
@media (max-width: 1680px) {
  #generator-select-frm {
    min-width: 34rem;
  }
  #generator-select {
    min-width: 34rem;
  }
  #generator-select {
    padding: 3rem;
  }
}
@media (max-width: 1280px) {
  #generator-select-frm {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    min-width: 28rem;
    transition: width 1s ease-in-out, min-width 1s ease-in-out;
  }
  #generator-select {
    min-width: 28rem;
  }
  #generator-select-frm.closed {
    width: 0rem;
    min-width: 0rem;
  }
  #generator-select {
    padding: 5rem 2rem;
  }
  .gs-buttons > a {
    font-size: 1.4rem;
  }
  .generator-select-opener {
    display: block;
    position: absolute;
    left: 2rem;
    top: 2rem;
    z-index: 8;
    background-color: #fff;
    transform: translate(0, 0);
    transition: left 1s ease-in-out, transform 1s ease-in-out, background-color 1s ease-in-out;
  }
  #generator-select-frm.closed .generator-select-opener {
    left: -2rem;
    transform: translate(-100%, 0);
    background-color: #d9f0fb;
  }
}
</style>
