<template>
  <div ref="svgContainer" class="svgContainer" :style="'min-height:' + height + 'px;'">
    <svg ref="svg" :width="width" :height="height" v-if="active && !refresh && diagrammData" :viewBox="'0 0 ' + width + ' ' + height">
      <defs>
        <pattern :id="'valpat' + pKey" patternUnits="userSpaceOnUse" :patternTransform="pKey%2 == 0 ? 'scale(1, -1)' : null" :width="13" :height="13" v-for="(pObj, pKey) in pattern" :key="'valpat' + pKey">
          <template v-if="blackWhite">
            <rect x="-1" y="-1" width="15" height="15" :fill="(blackWhiteFill && diagrammData.rowsLen <= 1) ? '#000' : '#fff'" v-if="diagrammData.rowsLen <= 1 || pKey == 0" />
            <template v-else>
              <line :x1="-13 / 2" :y1="13 / 2" :x2="13 / 2" :y2="13 * 1.5" :style="'stroke:black; stroke-width:' + 1" />
              <line x1="0" y1="0" :x2="13" :y2="13" :style="'stroke:black; stroke-width:' + 1" />
              <line :x1="13 / 2" :y1="-13 / 2" :x2="13 * 1.5" :y2="13 / 2" :style="'stroke:black; stroke-width:' + 1" />
            </template>
          </template>
          <rect x="-1" y="-1" width="15" height="15" :fill="pObj" v-else />
        </pattern>
      </defs>
      <SvgText
        :x="dLeft + dWidth / 2"
        :y="top - fontSize + 3" :fill="getCssRGB(textFarbe)" :font-family="fontFamily" :font-size="fontSize + 1" center
        :text="excelJSON.optionen['einheitenart']"
      />
      <g v-for="dg in Array(einteilungen + 1).keys()" :key="'gb' + dg">
         <line
          :x1="dLeft + dWidth / einteilungen * dg" :y1="top"
          :x2="dLeft + dWidth / einteilungen * dg" :y2="top + dHeight + 5" :stroke="secColor" stroke-width="1"
        />
        <SvgText
          :x="dLeft + dWidth / einteilungen * dg"
          :y="top + dHeight + fontSize - 1 + lSpacer" :fill="getCssRGB(textFarbe)" :font-family="fontFamily" :font-size="fontSize" center
          :max-right="width"
          :text="localString(maxVal / einteilungen * dg)"
        />
      </g>
      <g v-for="(line, lKey) in diagrammData.data" :key="'gl' + lKey">
        <SvgText
          :x="dLeft - 10"
          :y="top + (size + lSpacer) * lKey + fontSize + (size + lSpacer - fontSize) / 2 - 3" :fill="getCssRGB(textFarbe)" :font-family="fontFamily" :font-size="fontSize" right
          :font-weight="line.style === 'bold' ? 'bold' : null"
          :text="line.titel"
        />
        <line :x1="dLeft - 15" :y1="top + (size + lSpacer) * lKey" :x2="dLeft + dWidth" :y2="top + (size + lSpacer) * lKey" :stroke-width="1" :stroke="secColor" />
        <rect
          :x="dLeft + (dWidth / maxVal * line.valsData[aKey].valLeft)" :y="top + (size + lSpacer) * lKey + lSpacer / 2"
          :width="dWidth / maxVal * (line.vals[aKey].val === '---' ? 0 : line.vals[aKey].val || 0)" :height="size"
          :stroke-width="blackWhite && (!blackWhiteFill || diagrammData.rowsLen > 1) ? 1 : 0" stroke="#000"
          :fill="'url(#valpat' + aKey + ')'"
          @mouseover="hoverVal(lKey, aKey, true)"
          @mouseleave="hoverVal(lKey, aKey, false)"
          class="cur-point"
          v-for="(aVal, aKey) in line.vals" :key="'vr' + aKey"
        />
      </g>
      <line :x1="dLeft - 15" :y1="top + dHeight" :x2="dLeft + dWidth" :y2="top + dHeight" :stroke="secColor" stroke-width="1" />
      <!-- Durchschnittswerte -->
      <g v-for="(schnitt, sKey) in diagrammData.schnitt" :key="'gs' + sKey">
        <line
          :x1="dLeft + dWidth / maxVal * schnitt.vals[0].val" :y1="top"
          :x2="dLeft + dWidth / maxVal * schnitt.vals[0].val" :y2="top + dHeight + 40 + ((fontSize + 5) * sKey)" :stroke="schnittColor"
          :stroke-width="(typeof schnitt.titel === 'string' && schnitt.titel.toLowerCase().indexOf('wissensregion') > -1) ? 3 : 2"
        />
        <SvgText
          :x="dLeft + dWidth / maxVal * schnitt.vals[0].val - 5"
          :y="top + dHeight + 40 + ((fontSize + 5) * sKey) - 3" :fill="getCssRGB(textFarbe)" :font-family="fontFamily" :font-size="fontSize" right
          :text="schnitt.titel + ' (' + localString(schnitt.vals[0].val, 'schnitt') + ')'"
          :font-weight="(typeof schnitt.titel === 'string' && schnitt.titel.toLowerCase().indexOf('wissensregion') > -1) ? 'bold' : null"
        />
      </g>
      <g v-for="(line, lKey) in diagrammData.data" :key="'glt' + lKey">
        <g
          :transform="'translate(' +
            (
              line.vals.length > 1 ?
              (dLeft + (dWidth / maxVal * line.valsData[aKey].valLeft) + (dWidth / maxVal * (line.vals[aKey].val === '---' ? 0 : line.vals[aKey].val || 0) + 2) / 2) :
              (dLeft + (dWidth / maxVal * line.valsData[aKey].valLeft) + dWidth / maxVal * (line.vals[aKey].val === '---' ? 0 : line.vals[aKey].val || 0) - 5)
            ) + ', ' +
            (top + (size + lSpacer) * lKey + lSpacer / 2 + size - size * 0.3) + ')'"
            v-for="(aVal, aKey) in line.vals" :key="'glt' + lKey + 'vt' + aKey"
        >
          <SvgText
            :x="0"
            :y="0" :font-family="fontFamily"
            :right="line.vals.length <= 1"
            :center="line.vals.length > 1"
            :max-width="dWidth / maxVal * line.vals[aKey].val + 1"
            style="pointer-events:none;"
            :font-size="size * 0.6"
            :fill="blackWhite ? ((blackWhiteFill && diagrammData.rowsLen <= 1) ? '#fff' : '#000') : '#eee'"
            :shadow="blackWhite ? ((blackWhiteFill && diagrammData.rowsLen <= 1) ? '#000' : '#fff') : null"
            :text="localString(line.vals[aKey].val, 'main', true)"
            v-if="line.vals[aKey].val > 0"
          />
        </g>
      </g>
      <!-- Legende -->
      <g :transform="'translate(' + (dLeft + dWidth / 2) + ', ' + (top + dHeight + 30 + ((fontSize + 5) * diagrammData.schnitt.length) + 10) + ')'" v-if="rowsData">
        <g
          :transform="'translate(' + ((rowsData.rowLeft[lVal.row] ? rowsData.rowLeft[lVal.row] : 0) + lVal.left - 5) + ', ' + (lVal.top) + ')'"
          ref="legende"
          :data-text="lVal.titel"
          v-for="(lVal, lKey) in rowsData.data" :key="'leg' + lKey"
        >
          <rect
            :x="0" :y="0"
            :width="fontSize" :height="fontSize"
            :stroke-width="1" stroke="#000"
            :fill="'url(#valpat' + lKey + ')'"
          />
          <SvgText
            :x="fontSize + 5"
            :y="fontSize * 0.8" :font-family="fontFamily"
            :font-size="fontSize"
            :fill="getCssRGB(textFarbe)"
            :text="lVal.titel"
          />
        </g>
      </g>
    </svg>
    <div v-else>
      Daten nicht mit Ansicht kompatibel!
    </div>
    <div ref="tooltip" class="diagramm-tooltip" :style="'left:' + mX + 'px;top:' + mY + 'px;'" v-if="hVal.l !== null && hVal.v !== null">
      <div>
        <h3>{{ diagrammData.data[hVal.l].titel }}</h3>
        <div v-for="(vVal, vKey) in diagrammData.data[hVal.l].vals" :key="'dtt' + vKey">
          <span :class="'diagramm-tooltip-value' + (vKey === hVal.v ? ' selected' : '')">{{ vVal.name }}:</span>
          {{ localString(vVal.val) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgText from '../Svg/SvgText';

export default {
  name: 'HorizontalesBalkenDiagramm',
  props: {
    daten: Object,
    excelJSON: Object,
    jahr: [Number, String],
    fontSize: { type: Number, default: 14 },
    size: { type: Number, default: 20 },
    lSpacer: { type: Number, default: 5 },
    fontFamily: { type: String, default: 'Source Sans Pro, sans-serif' },
    secColor: { type: String, default: '#ccc' },
    schnittColor: { type: String, default: '#444' },
    farbenSet: { type: Number, default: 0 },
    basisFarbe: { type: Object, default: function () { return { r: 207, g: 190, b: 185 } } },
    farbe: { type: Object, default: function () { return { r: 94, g: 72, b: 65 } } },
    textFarbe: { type: Object, default: function () { return { r: 0, g: 0, b: 0 } } },
    blackWhite: { type: Boolean, default: false },
    blackWhiteFill: { type: Boolean, default: false },
    sortierung: { type: String, default: null},
    update: { type: Boolean, default: false},
    active: { type: Boolean, default: true},
    exportieren: { type: Boolean, default: false}
  },
  data: () => ({
    mTeiler: [1, 2, 5, 10, 20, 25, 50, 100, 500, 1000, 2000, 5000, 10000, 50000, 100000, 250000, 500000, 1000000, 2000000, 5000000, 10000000, 25000000, 50000000],
    width: 100,
    refresh: true,
    rowsData: { height: 0, data: [], rowLeft: [] },
    rowsDataTrys: 0,
    mX: 200,
    mY: 10,
    hVal: { l: null, v: null }
  }),
  mounted () {
    this.$root.$el.addEventListener('resize', this.resize)
    this.$root.$el.addEventListener('mousemove', this.mouseMove)
    this.resize()
    this.$nextTick(() => {
      this.refresh = false
      this.$emit('isOk', 'hbalken', (this.diagrammData) ? true : false)
    })
    // console.log(this.diagrammData)
  },
  beforeDestroy () {
    this.$root.$el.removeEventListener('mousemove', this.mouseMove)
    this.$root.$el.removeEventListener('resize', this.resize)
  },
  methods: {
    updateRowsData () {
      if (this.diagrammData) {
        // console.log('updateRowsData')
        let reDo = false
        let aData = []
        let dg = 0
        let allWidth = 0
        this.diagrammData.rows.forEach(r => {
          let left = 0
          let top = 0
          let width = 0
          if (this.$refs.legende && this.$refs.legende[dg] && this.$refs.legende[dg].dataset && this.$refs.legende[dg].dataset.text === r) {
            width = this.$refs.legende[dg].getBBox().width + 10
            allWidth += width
          } else {
            reDo = true
          }
          aData.push({ titel: r, left, top, width, row: 0})
          dg += 1
        })
        if (allWidth < 1) {
          reDo = true
        }
        this.rowsData = { height: 0, data: aData, rowLeft: [] }
        if (reDo && this.rowsDataTrys < 10) {
          this.rowsDataTrys += 1
          this.$nextTick(() => {
            this.updateRowsData()
          })
        } else {
          // console.log('rowsDataTrys', this.rowsDataTrys)
          let aTop = 0
          let aLeft = 0
          let aRow = 0
          this.rowLeft = []
          this.rowsData.data.forEach(rd => {
            if (aLeft + rd.width > this.dWidth) {
              this.rowsData.rowLeft.push((this.dWidth - aLeft) / 2)
              aLeft = 0
              aTop += this.fontSize + 8
              aRow += 1
            }
            rd.top = aTop
            rd.left = aLeft - this.dWidth / 2
            rd.row = aRow
            aLeft += rd.width
          })
          this.rowsData.rowLeft.push((this.dWidth - aLeft) / 2)
          this.rowsData.height = aTop + this.fontSize + 8
          // console.log(this.rowsData)
        }
      } else {
        this.rowsData = { height: 0, data: [], rowLeft: [] }
      }
    },
    getCssRGB (rgb) {
      return 'rgb(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ')'
    },
    setMinMax (dData) {
      dData.data.forEach(row => {
        if (row) {
          let sum = 0
          row.vals.forEach(val => { sum += (val ? val.val : 0) || 0 })
          if (sum < dData.min) { dData.min = sum }
          if (sum > dData.max) { dData.max = sum }
        }
      })
      dData.min = parseFloat(dData.min.toFixed(2))
      dData.max = parseFloat(dData.max.toFixed(2))
      if (dData.min > 99.8 && dData.max < 100.2) {
        dData.min = 100
        dData.max = 100
      }
      return dData
    },
    setValsData (dData) {
      dData.data.forEach(aData => {
        let aLeft = 0
        for (let i = 0; i < aData.vals.length; i++) {
          aData.valsData[i] = {
            valLeft: aLeft
          }
          aLeft += (aData.vals[i] ? (aData.vals[i].val === '---' ? 0 : aData.vals[i].val) : 0) || 0
        }
      })
      return dData
    },
    resize () {
      this.rowsDataTrys = 0
      this.updateWidth()
      this.updateRowsData()
    },
    updateWidth () {
      if (this.exportieren) {
        this.width = 1400
      } else {
        this.width = 400
        this.$nextTick(() => {
          this.width = this.$refs.svgContainer ? this.$refs.svgContainer.clientWidth : 400
          if (this.width < 600) {
            this.width = 600
          }
        })
      }
    },
    localString (val, dType = 'main', kek = false) {
      let fVal = val
      if (typeof val === 'undefined') {
        return 'Keine Daten vorhanden'
      }
      if (typeof fVal === 'string' && fVal === '---') {
        return '---'
      }
      // if (typeof val === 'undefined' && this.excelJSON.optionen['zahlen formatierung'] && this.excelJSON.optionen['zahlen formatierung'].indexOf('float') > -1) {
      //   fVal = 0
      // }
      if (typeof fVal === 'number') {
        if ((dType === 'main' || dType === 'schnitt') && this.excelJSON.optionen['zahlen formatierung'] && this.excelJSON.optionen['zahlen formatierung'].indexOf('float') > -1) {
          let aDigits = parseInt(this.excelJSON.optionen['zahlen formatierung'].substr(5))
          fVal = fVal.toLocaleString(undefined, {minimumFractionDigits: aDigits, maximumFractionDigits: aDigits})
        } else {
          fVal = fVal.toLocaleString()
        }
      }
      if (!(kek && this.excelJSON.optionen.spezial && this.excelJSON.optionen.spezial.hbalken && this.excelJSON.optionen.spezial.hbalken.indexOf('kek') > -1)) {
        let eOption = 'einheitenart kurz'
        if (dType === 'schnitt') {
          eOption = 'einheitenart kurz schnitt'
        }
        if (dType === 'summe') {
          eOption = 'einheitenart kurz summen'
        }
        if (fVal && this.excelJSON.optionen[eOption]) {
          if (!((typeof val === 'string') && this.excelJSON.optionen[eOption] === '%')) {
            fVal += ' ' + this.excelJSON.optionen[eOption]
          }
        }
      }
      return fVal ? fVal : ''
    },
    SpaltenBeiJahr (row) {
      if (this.excelJSON.jahr && (this.excelJSON.jahr.length > 1 || (this.excelJSON.optionen['datenart'] === 'Jahresaufstellung' && this.excelJSON.jahr.length > 0)) && !(this.jahr === 'Mehrere Jahre' && this.excelJSON.optionen['mehrere jahre'])) {
        return [row.obj[this.jahr]]
      }
      return row.list
    },
    sortList(sortierung, list) {
      if (sortierung) {
        if (sortierung === '!zeilenbeschriftung') {
          return list.slice().sort((a, b) => {
            let aV = this.daten.mainData.functions.regionen.getByVal(a.name, this.excelJSON.optionen['zeilenbeschriftung']).name
            let bV = this.daten.mainData.functions.regionen.getByVal(b.name, this.excelJSON.optionen['zeilenbeschriftung']).name
            return aV > bV ? 1 : aV < bV ? -1 : 0
          })
        } else if (sortierung === '!wert') {
          return list.slice().sort((a, b) => {
            let aV = this.SpaltenBeiJahr(a.spalten)[0].val
            let bV = this.SpaltenBeiJahr(b.spalten)[0].val
            return aV > bV ? 1 : aV < bV ? -1 : 0
          })
        } else {
          return list.slice().sort((a, b) => {
            let aV = a.spalten.obj[sortierung].val
            let bV = b.spalten.obj[sortierung].val
            return aV > bV ? 1 : aV < bV ? -1 : 0
          })
        }
      } else {
        return list
      }
    },
    hoverVal (lKey, vKey, stat) {
      this.hVal = stat ? { l: lKey, v: vKey } : { l: null, v: null }
    },
    mouseMove (e) {
      this.mX = e.clientX
      this.mY = e.clientY
      if (this.$refs.tooltip && this.$refs.tooltip.children && this.$refs.tooltip.children[0]) {
        if (this.mY - this.$refs.tooltip.children[0].clientHeight < 10) {
          this.mY = this.$refs.tooltip.children[0].clientHeight + 10
        }
        let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        if (vw > 0 && this.mX + this.$refs.tooltip.children[0].clientWidth > vw - 10) {
          this.mX = vw - this.$refs.tooltip.children[0].clientWidth - 10
        }
      }
    }
  },
  computed: {
    sortedList () {
      return this.sortList(this.sortierung, this.excelJSON.daten.mainData.list)
    },
    sortedSchnitt () {
      return this.sortList(this.sortierung, this.excelJSON.daten.mainData.schnitt.list)
    },
    sortedSumme () {
      return this.sortList(this.sortierung, this.excelJSON.daten.mainData.summe.list)
    },
    diagrammData () {
      let dData = null
      if (this.excelJSON.jahr && this.excelJSON.jahr.length > 0) {
        dData = { data: [], schnitt: [], min: Infinity, max: -Infinity, multipleData: false, rows: [], rowsLen: 1 }
        if ((this.excelJSON.jahr.length > 1 || (this.excelJSON.optionen['datenart'] === 'Jahresaufstellung' && this.excelJSON.jahr.length > 0)) && !(this.jahr === 'Mehrere Jahre' && this.excelJSON.optionen['mehrere jahre'])) {
          this.sortedList.forEach(row => {
            dData.data.push({ titel: this.daten.mainData.functions.regionen.getByVal(row.name, this.excelJSON.optionen['zeilenbeschriftung']).name, style: row.style, vals: [row.spalten.obj[this.jahr]], valsData: [] })
          })
          this.sortedSchnitt.forEach(row => {
            dData.schnitt.push({ titel: row.name, vals: [row.spalten.obj[this.jahr]] })
          })
        } else {
          [...this.sortedList, ...this.excelJSON.daten.mainData.schnitt.list].forEach(row => {
            dData.data.push({ titel: this.daten.mainData.functions.regionen.getByVal(row.name, this.excelJSON.optionen['zeilenbeschriftung']).name, style: row.style, vals: row.spalten.list, valsData: [] })
            row.spalten.list.forEach(spalte => {
              if (dData.rows.indexOf(spalte.name) < 0) {
                dData.rows.push(spalte.name)
              }
            })
            if (row.spalten.list.length > dData.rowsLen) {
              dData.rowsLen = row.spalten.list.length
            }
          })
          dData.multipleData = true
        }
        dData.schnitt.sort((a, b) => (a.vals && a.vals[0] !== undefined && b.vals && b.vals[0] !== undefined ? (a.vals[0].val < b.vals[0].val ? -1 : (a.vals[0].val > b.vals[0].val ? 1 : 0)) : 0))
        dData = this.setMinMax(dData)
        dData = this.setValsData(dData)
      }
      return dData
    },
    pattern () {
      let aPat = []
      if (this.diagrammData.multipleData) {
        // farbenSet
        let useFarbensets = this.daten.mainData.basis.farbensets
        let cycleFarbenSet = this.farbenSet || 0
        if (this.excelJSON.optionen.farben && this.excelJSON.optionen.farben.length > 0) {
          useFarbensets = this.excelJSON.optionen.farben.map(f => this.daten.mainData.basis.farbensets[f] || this.daten.mainData.basis.farbensets[0])
          cycleFarbenSet = 0
          // console.log('Farben', this.excelJSON.optionen.farben, useFarbensets)
        }
        for (let i = 0; i < this.diagrammData.data.length; i++) {
          aPat.push(useFarbensets[cycleFarbenSet].a)
          cycleFarbenSet += 1
          if (cycleFarbenSet >= useFarbensets.length) {
            cycleFarbenSet = 0
          }
        }
      } else {
        aPat.push(this.daten.mainData.basis.farbensets[this.farbenSet || 0].a)
      }
      return aPat
    },
    maxSchritte () {
      return  this.width > 800 ? 12 : this.width > 500 ? 8 : 5
    },
    teiler () {
      return this.mTeiler.filter(aTeiler => this.diagrammData.max / aTeiler <= this.maxSchritte)[0]
    },
    einteilungen () {
      return this.maxVal / this.teiler > 0 ? this.maxVal / this.teiler : 1
    },
    maxVal () {
      return Math.ceil(this.diagrammData.max / this.teiler) * this.teiler
    },
    lines () {
      return this.diagrammData.data.length
    },
    dLeft () {
      return this.width > 500 ? 250 : 130
    },
    dWidth () {
      let dW = this.width - this.dLeft - 25
      return dW > 150 ? dW : 150
    },
    dHeight () {
      return (this.size + this.lSpacer) * this.lines
    },
    top () {
      return 50
    },
    height () {
      return this.diagrammData ? (this.top + this.dHeight + ((this.fontSize + 5) * this.diagrammData.schnitt.length) + 30 + (this.rowsData ? 20 + this.rowsData.height : 0)) : 200
    }
  },
  watch: {
    diagrammData () {
      // console.log('diagrammData (w)', this.diagrammData)
      this.updateRowsData()
      this.rowsDataTrys = 0
      this.refresh = true
    },
    update (nVal) {
      if (nVal) {
        this.refresh = true
      }
    },
    active (nVal) {
      if (nVal) {
        this.$nextTick(() => {
          this.refresh = true
          this.rowsDataTrys = 0
        })
      }
    },
    refresh (nVal) {
      if (nVal) {
        this.$nextTick(() => {
          this.refresh = false
          this.resize()
        })
      }
    }
  },
  components: {
    SvgText
  }
}
</script>

<style scoped>
.svgContainer {
  position: relative;
}
.diagramm-tooltip {
  position: fixed;
  top: 10px;
  left: 200px;
  width: 33vw;
  pointer-events: none;
  color: #666;
  font-size: 1.5rem;
  z-index: 10;
}
.diagramm-tooltip a, .diagramm-tooltip span {
  font-size: 1.5rem;
}
.diagramm-tooltip h3 {
  font-size: 1.8rem;
  color: #666;
  margin-bottom: 0.5rem;
}
.diagramm-tooltip > div {
  position: absolute;
  left: 5px;
  bottom: 5px;
  background: #fff;
  box-shadow: 2px 2px 6px 2px rgba(0,0,0,0.3);
  padding: 4px 8px;
  max-width: 33vw;
}
.diagramm-tooltip-value.selected {
  font-weight: bold;
}
@media (max-width: 700px) {
  .svgContainer {
    overflow: auto;
  }
}
</style>