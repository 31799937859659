<template>
  <div>
    <div class="v-data-table theme--light" v-if="!refresh && excelJSON.daten && excelJSON.daten.mainData">
      <div class="v-data-table__wrapper">
        <table :id="exportieren ? 'export-table' : null" :style="'color:' + cssRGB(textFarbe) + ';'">
          <thead :style="'color:' + cssRGB(basisFarbe) + ';'">
            <tr>
              <th v-if="!excelJSON.optionen['jahr']"></th>
              <th v-if="excelJSON.jahr && excelJSON.jahr.length > 1 && !(this.jahr === 'Mehrere Jahre' && this.excelJSON.optionen['mehrere jahre'])" class="text-right">{{ jahr }}</th>
              <th v-else v-for="(cell, cKey) in filterSpaltenTitel(excelJSON.daten.mainData.spalten)" :key="'tth' + cKey" :class="excelJSON.optionen['jahr'] && cKey === 0 ? '' : 'text-right'">{{ cell }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, rKey) in sortedList" :key="'ttr' + rKey" class="tr-werte">
              <th v-if="sortedListFirstRow[rKey].rowSpan > 0 || exportieren" :rowspan="exportieren ? 1 : sortedListFirstRow[rKey].rowSpan"
                :class="row.style || 'normal'"
              >{{ (typeof row.name === 'string' && row.name.substring(0, 4) === 'nnr-') ? '' : daten.mainData.functions.regionen.getByVal(row.name, excelJSON.optionen['zeilenbeschriftung']).name }}</th>
              <td v-for="(cell, cKey) in filterSpalten(spaltenBeiJahr(row.spalten))" :key="'ttr' + rKey + 'c' + cKey" :class="'text-right ' + (row.style || 'normal')" v-html="localString(cell.val, isSumme(cell.name) ? 'summe' : 'main')"></td>
            </tr>
            <tr v-for="(row, rKey) in sortedSchnitt" :key="'ttrs' + rKey" class="tr-schnitt">
              <th :class="row.style || 'normal'">{{ (typeof row.name === 'string' && row.name.substring(0, 4) === 'nnr-') ? '' : daten.mainData.functions.regionen.getByVal(row.name, excelJSON.optionen['zeilenbeschriftung']).name }}</th>
              <td v-for="(cell, cKey) in filterSpalten(spaltenBeiJahr(row.spalten))" :key="'ttrs' + rKey + 'cs' + cKey" :class="'text-right ' + (row.style || 'normal')" v-html="localString(cell.val, isSumme(cell.name) ? 'summe' : 'schnitt')"></td>
            </tr>
            <tr v-for="(row, rKey) in sortedSumme" :key="'ttrss' + rKey" class="tr-summe">
              <th :class="row.style || 'bold'">{{ (typeof row.name === 'string' && row.name.substring(0, 4) === 'nnr-') ? '' : daten.mainData.functions.regionen.getByVal(row.name, excelJSON.optionen['zeilenbeschriftung']).name }}</th>
              <td v-for="(cell, cKey) in filterSpalten(spaltenBeiJahr(row.spalten))" :key="'ttrss' + rKey + 'cg' + cKey" :class="'text-right ' + (row.style || 'bold')" v-html="localString(cell.val, isSummeFloat(cell.name) ? 'summe' : 'summeFloat')"></td>
            </tr>
            <tr></tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else>
      Keine Daten !!!
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabelle',
  props: {
    daten: Object,
    excelJSON: Object,
    jahr: [Number, String],
    sortierung: { type: String, default: null},
    basisFarbe: { type: Object, default: function () { return { r: 207, g: 190, b: 185 } } },
    farbe: { type: Object, default: function () { return { r: 94, g: 72, b: 65 } } },
    textFarbe: { type: Object, default: function () { return { r: 0, g: 0, b: 0 } } },
    blackWhite: { type: Boolean, default: false },
    update: { type: Boolean, default: false},
    exportieren: { type: Boolean, default: false}
  },
  data: () => ({
    refresh: true
  }),
  mounted () {
    this.$nextTick(() => {
      this.refresh = false
      this.$emit('isOk', 'tabelle', (this.excelJSON.daten && this.excelJSON.daten.mainData) ? true : false)
    })
    // console.log(this.excelJSON.daten.mainData.list)
  },
  methods: {
    filterSpaltenTitel (row) {
      return row.filter(r => (!r || typeof r !== 'string' || ['koy', 'kox'].indexOf(r.toLowerCase()) < 0))
    },
    filterSpalten (row) {
      return row.filter(r => (!r.name || typeof r.name !== 'string' || ['koy', 'kox'].indexOf(r.name.toLowerCase()) < 0))
    },
    spaltenBeiJahr (row) {
      if (this.excelJSON.jahr && this.excelJSON.jahr.length > 1 && !(this.jahr === 'Mehrere Jahre' && this.excelJSON.optionen['mehrere jahre'])) {
        return [row.obj[this.jahr]]
      }
      return row.list
    },
    isSummeFloat(aTxt) {
      return typeof aTxt !== 'string' || (aTxt.toLowerCase().indexOf('%') < 0)
    },
    isSumme(aTxt) {
      return typeof aTxt === 'string' && (aTxt.toLowerCase().indexOf('%') < 0) && (aTxt.toLowerCase().indexOf('absolut') > -1 || aTxt.toLowerCase().indexOf('summe') > -1 || aTxt.toLowerCase().indexOf('gesamt') > -1)
    },
    fxShortIt (str) {
      return typeof str === 'string' ? str.match(/.{1,40}/g).join('<br>') : str
    },
    localString (val, dType = 'main') {
      let fVal = val
      if (typeof val === 'undefined') {
        return ''
      }
      if (typeof fVal === 'string' && fVal === '---') {
        return '---'
      }
      // if (typeof val === 'undefined' && this.excelJSON.optionen['zahlen formatierung'] && this.excelJSON.optionen['zahlen formatierung'].indexOf('float') > -1) {
      //   fVal = 0
      // }
      if (typeof fVal === 'number') {
        if ((dType === 'main' || dType === 'schnitt' || dType === 'summeFloat') && this.excelJSON.optionen['zahlen formatierung'] && this.excelJSON.optionen['zahlen formatierung'].indexOf('float') > -1) {
          let aDigits = parseInt(this.excelJSON.optionen['zahlen formatierung'].substr(5))
          fVal = fVal.toLocaleString(undefined, {minimumFractionDigits: aDigits, maximumFractionDigits: aDigits})
        } else {
          fVal = fVal.toLocaleString()
        }
      } else if (typeof fVal === 'string') {
        fVal = fVal.replace(/>/g, "&gt;").replace(/</g, "&lt;")
        if (fVal.substring(0, 7) === 'http://' || val.substring(0, 8) === 'https://') {
          let linkTitel = fVal.replace(/http(s)*:\/\//g, '')
          let linkText = this.exportieren ? this.fxShortIt(linkTitel) : '<span class="icon wir-external"></span>'
          fVal = '<a href="' + fVal + '" title="' + linkTitel + '"' + (this.exportieren ? '' : ' class="tab-fx-link"') + ' target="_blank">' + linkText + '</a>'
        } else {
          if (!this.exportieren) {
            Object.keys(this.daten.mainData.akt.wordReplace).forEach(wr => {
              // console.log(fVal, fVal.replace(wr, this.daten.mainData.akt.wordReplace[wr]))
              fVal = fVal.replace(wr, this.daten.mainData.akt.wordReplace[wr])
            })
          }
        }
      }
      let eOption = 'einheitenart kurz'
      if (dType === 'schnitt') {
        eOption = 'einheitenart kurz schnitt'
      }
      if (dType === 'summe') {
        eOption = 'einheitenart kurz summen'
      }
      if (fVal && this.excelJSON.optionen[eOption]) {
        if (!((typeof val === 'string') && this.excelJSON.optionen[eOption] === '%')) {
          fVal += ' ' + this.excelJSON.optionen[eOption]
        }
      }
      return fVal ? fVal : ''
    },
    sortList(sortierung, list) {
      if (sortierung) {
        if (sortierung === '!zeilenbeschriftung') {
          return list.slice().sort((a, b) => {
            if (typeof a.name === 'string' && a.name.toLowerCase().indexOf('wissensregion') > -1) { return 1 }
            if (typeof b.name === 'string' && b.name.toLowerCase().indexOf('wissensregion') > -1) { return -1 }
            let aV = this.daten.mainData.functions.regionen.getByVal(a.name, this.excelJSON.optionen['zeilenbeschriftung']).name
            let bV = this.daten.mainData.functions.regionen.getByVal(b.name, this.excelJSON.optionen['zeilenbeschriftung']).name
            return aV > bV ? 1 : aV < bV ? -1 : 0
          })
        } else if (sortierung === '!wert') {
          return list.slice().sort((a, b) => {
            if (typeof a.name === 'string' && a.name.toLowerCase().indexOf('wissensregion') > -1) { return 1 }
            if (typeof b.name === 'string' && b.name.toLowerCase().indexOf('wissensregion') > -1) { return -1 }
            let aV = this.spaltenBeiJahr(a.spalten)[0].val
            let bV = this.spaltenBeiJahr(b.spalten)[0].val
            return aV > bV ? 1 : aV < bV ? -1 : 0
          })
        } else {
          return list.slice().sort((a, b) => {
            if (typeof a.name === 'string' && a.name.toLowerCase().indexOf('wissensregion') > -1) { return 1 }
            if (typeof b.name === 'string' && b.name.toLowerCase().indexOf('wissensregion') > -1) { return -1 }
            let aV = a.spalten.obj[sortierung].val
            let bV = b.spalten.obj[sortierung].val
            return aV > bV ? 1 : aV < bV ? -1 : 0
          })
        }
      } else {
        return list
      }
    },
    cssRGB (rgb) {
      return 'rgb(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ')'
    }
  },
  computed: {
    sortedList () {
      return this.sortList(this.sortierung, this.excelJSON.daten.mainData.list)
    },
    sortedListFirstRow() {
      let aList = this.sortedList.slice().reverse()
      let lNullCount = 0
      aList = aList.map(row => {
        lNullCount += 1
        let rowSpan = 0
        let lastRowSpanElement = false
        let aName = (typeof row.name === 'string' && row.name.substring(0, 4) === 'nnr-') ? null : row.name
        if (aName) {
          rowSpan = lNullCount
          lNullCount = 0
        } else if (lNullCount === 1) {
          lastRowSpanElement = true
        }
        return {
          name: aName,
          rowSpan: rowSpan,
          lastRowSpanElement: lastRowSpanElement
        }
      }).reverse()
      // console.log('sortedListFirstRow', aList)
      return aList
    },
    sortedSchnitt () {
      return this.excelJSON.daten.mainData.schnitt.list // this.sortList(this.sortierung, this.excelJSON.daten.mainData.schnitt.list)
    },
    sortedSumme () {
      return this.excelJSON.daten.mainData.summe.list // this.sortList(this.sortierung, this.excelJSON.daten.mainData.summe.list)
    }
  },
  watch: {
    update (nVal) {
      if (nVal) {
        this.refresh = true
      }
    },
    refresh (nVal) {
      if (nVal) {
        this.$nextTick(() => {
          this.refresh = false
        })
      }
    }
  }
}
</script>

<style scoped>
  table {
    width:100%;
  }
  td >>> a {
    line-break: anywhere;
  }
  .tr-werte th:not(.bold) {
    font-weight: normal;
  }
  .bold {
    font-weight: bold;
  }
  .normal, .notbold {
    font-weight: normal;
  }
  td {
    text-align: right;
  }
  table td, table th {
    padding: 0 0.5rem;
    border-bottom: 2px solid;
    border-spacing: 5px;
  }
  td >>> .tab-fx-link {
    display: block;
    text-align: center;
  }
</style>