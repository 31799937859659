<template>
  <div id="generator-content">
    <div class="inhalt" v-if="selectedDarstellungObj">
      <div class="gc-top mb-1">
        <div class="gc-header">
          <h2 :style="'color:' + farbeA + ';'">{{ selectedDataObj.optionen.titel }}</h2>
          <h3 :style="'color:' + textFarbe + ';'">{{ selectedDataObj.optionen.untertitel }}</h3>
          <h4 :style="'color:' + textFarbe + ';'">{{ daten.auswahl.zeitbezug }}</h4>
        </div>
      </div>
      <div>
        <Karte v-if="daten.auswahl.darstellung === 'karte'"
          :daten="daten"
          :excelJSON="selectedDataObj"
          :jahr="daten.auswahl.zeitbezug"
          :klasseneinteilung="daten.optionen.showKlasseneinteilung"
          :blackWhite="daten.optionen.farben === 1 || daten.optionen.farben === 2"
          :blackWhiteFill="daten.optionen.farben === 2"
          :farbenSet="daten.optionen.farben >= 3 ? daten.optionen.farben - 3 : selectedDataObj ? selectedDataObj.optionen['farbenset'] || 0 : 0"
          :basisFarbe="hex2rgb(farbeB)"
          :farbe="hex2rgb(farbeA)"
          :textFarbe="hex2rgb(textFarbe)"
        />
        <Kreisdiagramm v-else-if="daten.auswahl.darstellung === 'kreis'"
          :daten="daten"
          :excelJSON="selectedDataObj"
          :jahr="daten.auswahl.zeitbezug"
          :blackWhite="daten.optionen.farben === 1 || daten.optionen.farben === 2"
          :blackWhiteFill="daten.optionen.farben === 2"
          :farbenSet="daten.optionen.farben >= 3 ? daten.optionen.farben - 3 : selectedDataObj ? selectedDataObj.optionen['farbenset'] || 0 : 0"
          :basisFarbe="hex2rgb(farbeA)"
          :farbe="hex2rgb(farbeB)"
          :textFarbe="hex2rgb(textFarbe)"
          :sortierung="daten.optionen.sortierung"
        />
        <Liniendiagramm v-else-if="daten.auswahl.darstellung === 'linien'"
          :daten="daten"
          :excelJSON="selectedDataObj"
          :jahr="daten.auswahl.zeitbezug"
          :blackWhite="daten.optionen.farben === 1 || daten.optionen.farben === 2"
          :blackWhiteFill="daten.optionen.farben === 2"
          :farbenSet="daten.optionen.farben >= 3 ? daten.optionen.farben - 3 : selectedDataObj ? selectedDataObj.optionen['farbenset'] || 0 : 0"
          :basisFarbe="hex2rgb(farbeA)"
          :farbe="hex2rgb(farbeB)"
          :textFarbe="hex2rgb(textFarbe)"
          :sortierung="daten.optionen.sortierung"
        />
        <div v-else-if="daten.auswahl.darstellung === 'vbalken'">ToDo: Vertikales Diagramm</div>
        <HorizontalesBalkenDiagramm v-else-if="daten.auswahl.darstellung === 'hbalken'"
          :daten="daten"
          :excelJSON="selectedDataObj"
          :jahr="daten.auswahl.zeitbezug"
          :blackWhite="daten.optionen.farben === 1 || daten.optionen.farben === 2"
          :blackWhiteFill="daten.optionen.farben === 2"
          :farbenSet="daten.optionen.farben >= 3 ? daten.optionen.farben - 3 : selectedDataObj ? selectedDataObj.optionen['farbenset'] || 0 : 0"
          :basisFarbe="hex2rgb(farbeA)"
          :farbe="hex2rgb(farbeB)"
          :textFarbe="hex2rgb(textFarbe)"
          :sortierung="daten.optionen.sortierung"
        />
        <Tabelle v-else-if="daten.auswahl.darstellung === 'tabelle'"
          :daten="daten"
          :excelJSON="selectedDataObj"
          :jahr="daten.auswahl.zeitbezug"
          :sortierung="daten.optionen.sortierung"
          :blackWhite="daten.optionen.farben === 1 || daten.optionen.farben === 2"
          :basisFarbe="hex2rgb(farbeA)"
          :farbe="hex2rgb(farbeB)"
          :textFarbe="hex2rgb(textFarbe)"
        />
        <div v-else>Unbekannte Darstellung!</div>
      </div>
      <div class="g-info" v-if="glossar || anmerkung || quelle || (daten.auswahl.darstellung === 'karte' && selectedDataObj && selectedDataObj.optionen['zeilenbeschriftung'] === 'FrmRegionen')">
        <div class="g-glossar" v-if="glossar">
          <b>Glossar:</b>
          <div class="g-g-link-frm" v-for="(gVal, gKey) in glossar" :key="'gig' + gKey">
            <a :href="'#' + gVal.id" @click.prevent="showGlossar(gVal)" :class="'g-g-link' + (gVal.show ? ' open' : '')">{{ gVal.titel }}</a>
            <div class="g-g-info" v-if="gVal.show">
              <b>{{ gVal.titel }}</b>
              <div v-if="daten.glossar.loading"><p>Glossar wird geladen ...</p></div>
              <div v-else v-html="daten.glossar.obj && daten.glossar.obj[gVal.id] ? daten.glossar.obj[gVal.id].content : '<p>Eintrag nicht gefunden!</p>'" />
              <a :href="'https://www.wissensportal-frankfurtrheinmain.de/webseiten/wir/aktivitaeten/publikationen/glossar#' + gVal.id" target="_blank" class="g-g-link" v-if="daten.glossar.obj && daten.glossar.obj[gVal.id]">Im Glossar öffnen</a>
              <img class="close" @click="showGlossar(gVal, false)" :src="daten.sys.publicPath + 'diagrammgenerator/close.svg'" style="height:1rem">
            </div>
          </div>
        </div>
        <div class="g-kartenlegende" v-if="daten.auswahl.darstellung === 'karte' && selectedDataObj && selectedDataObj.optionen['zeilenbeschriftung'] === 'FrmRegionen'">
          <b>Kartenlegende:</b> <a href="https://www.wissensportal-frankfurtrheinmain.de/fileadmin/201016_WiR_Kartenlegende.pdf" target="_blank">Wissensregion FrankfurtRheinMain</a> (PDF-Datei)
        </div>
        <div class="g-anmerkung" v-if="anmerkung">
          <b>Anmerkung:</b> {{ anmerkung }}
        </div>
        <div class="g-quelle" v-if="quelle">
          <b>Quelle:</b> {{ quelle }}
        </div>
      </div>
    </div>
    <div v-else>
      <div class="ueberblick">
        <h2>Übersicht</h2>
        <div :class="'ueb-bereiche' + (daten.auswahl.bereich === bVal.id ? ' selected' : '')"
          :style="'border-color:' + bereichColor(bVal.id) + ';color:' + bereichColor(bVal.id) + ';' + (daten.auswahl.bereich === bVal.id ? ' background-color:' + bereichColor(bVal.id) + ';' : '')"
          v-for="(bVal, bKey) in bereiche" :key="'uebb' + bKey"
        >
          <h3 @click="setBereich(bVal.id)">{{ bVal.title }}</h3>
          <div class="ueb-indikatoren"
            v-for="(iVal, iKey) in sortedDataList(daten.mainData.akt.bereiche[bVal.id].indikatoren)" :key="'uebb' + bKey + 'i' + iKey"
          >
            <h4 @click="setIndikator(bVal.id, iVal.id)">{{ iVal.title }}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Karte from './Display/Karte';
import Kreisdiagramm from './Display/Kreisdiagramm';
import Liniendiagramm from './Display/Liniendiagramm';
import HorizontalesBalkenDiagramm from './Display/HorizontalesBalkenDiagramm';
import Tabelle from './Display/Tabelle';

export default {
  name: 'GeneratorContent',
  props: {
    daten: Object
  },
  data () {
    return {
      showOptions: false,
      optionsWidth: 100,
      optionsHeight: 100,
      glossar: null
    }
  },
  mounted () {
    this.updateGlossar()
  },
  methods: {
    bereichColor(bId) {
      let col = '#0099eb'
      let colCount = {}
      Object.keys(this.daten.mainData.akt.dateien).map(d => {
        return {
          bereich: this.daten.mainData.akt.dateien[d].content.optionen.bereich,
          colSet: this.daten.mainData.akt.dateien[d].content.optionen.farbenset
        }
      }).filter(d => d.bereich === bId && typeof d.colSet === 'number').forEach(d => {
        if (!colCount[d.colSet]) {
          colCount[d.colSet] = 0
        }
        colCount[d.colSet] += 1
      })
      let colSet = Object.keys(colCount).map(c => { return {colSet: c, count: colCount[c]} }).sort((a,b) => b.count - a.count)
      colSet = colSet && colSet.length > 0 ? parseInt(colSet[0].colSet) : 0
      if (this.farbensets[colSet]) {
        col = this.farbensets[colSet].a
      }
      return col
    },
    setBereich(bId) {
      this.daten.auswahl.bereich = bId
    },
    setIndikator(bId, iId) {
      this.daten.auswahl.bereich = bId
      this.$nextTick(() => {
        this.daten.auswahl.indikator = iId
      })
    },
    showGlossar (aGlossar, set=null) {
      if (this.glossar) {
        let aSet = set !== null ? set : !aGlossar.show
        this.glossar.forEach(g => { g.show = false })
        aGlossar.show = aSet
      }
    },
    getProp (obj, props) {
      if (props.length > 1) {
        if (obj[props[0]]) {
          return this.getProp(obj[props[0]], props.slice(1))
        } else {
          return undefined
        }
      } else {
        return obj[props[0]]
      }
    },
    hex2rgb (aHex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(aHex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },
    updateGlossar () {
      // console.log('updateGlossar')
      let aId = null
      if (this.selectedDataObj && this.selectedDataObj.optionen && this.selectedDataObj.optionen.glossar) {
        let aGlossar = this.selectedDataObj.optionen.glossar
        aId = []
        aGlossar.split(',').forEach(aVal => {
          let tVal = aVal.trim()
          if (tVal.length > 0) {
            if (tVal.indexOf('https://') > -1) {
              tVal = decodeURIComponent(tVal.split('#')[1]).replace(/[„“]/g, '').trim()
              aId.push({
                titel: this.daten.glossar.obj && this.daten.glossar.obj[tVal] ? this.daten.glossar.obj[tVal].titel : tVal.replace(/_/g, ' ').trim(),
                id: tVal,
                show: false
              })
              // console.log(tVal)
            } else {
              aId.push({titel: aVal.trim(), id: aVal.trim().toLowerCase().replace(/ /g, '_').replace(/[„“]/g, ''), show: false})
            }
          }
        })
      }
      this.glossar = aId
    },
    sortedDataList (obj) {
      return Object.keys(obj).map(aKey => {
        return { id: aKey, title: aKey, sort: obj[aKey].sort }
      }).sort((a, b) => ((a.sort || a.sort === 0) && (b.sort || b.sort === 0)) ? ((a.sort > b.sort) ? 1 : (a.sort === b.sort) ? ((a.id > b.id) ? 1 : -1) : -1) : ((a.id > b.id) ? 1 : -1))
    }
  },
  computed: {
    bereiche () {
      return this.sortedDataList(this.daten.mainData.akt.bereiche)
    },
    anmerkung () {
      return this.selectedDataObj && this.selectedDataObj.optionen ? this.selectedDataObj.optionen.anmerkungen : null
    },
    quelle () {
      return this.selectedDataObj && this.selectedDataObj.optionen ? this.selectedDataObj.optionen.quelle : null
    },
    selectedDarstellungObj () {
      return this.getProp(this.daten.mainData.akt.bereiche, [this.daten.auswahl.bereich, 'indikatoren', this.daten.auswahl.indikator, 'auspraegungen', (this.daten.auswahl.bezug || this.daten.auswahl.auspraegung), 'raumbezuege', this.daten.auswahl.raumbezug, 'zeitbezuege', this.daten.auswahl.zeitbezug, 'darstellungen', this.daten.auswahl.darstellung])
    },
    selectedDataObj () {
      return this.selectedDarstellungObj && this.daten.mainData.akt.dateien[this.selectedDarstellungObj.dataId] ? this.daten.mainData.akt.dateien[this.selectedDarstellungObj.dataId].content : null
    },
    farbensets () {
      return this.daten.mainData.basis.farbensets
    },
    farbeA () {
      if ([1,2].indexOf(this.daten.optionen.farben) > -1) {
        return '#000000'
      }
      return this.daten.optionen.farben >= 3 ? this.farbensets[this.daten.optionen.farben - 3].a : this.farbensets[this.selectedDataObj ? this.selectedDataObj.optionen['farbenset'] || 0 : 0].a
    },
    farbeB () {
      if ([1,2].indexOf(this.daten.optionen.farben) > -1) {
        return '#eeeeee'
      }
      return this.daten.optionen.farben >= 3 ? this.farbensets[this.daten.optionen.farben - 3].b : this.farbensets[this.selectedDataObj ? this.selectedDataObj.optionen['farbenset'] || 0 : 0].b
    },
    textFarbe () {
      return [1,2].indexOf(this.daten.optionen.farben) > -1 ? '#000000' : '#555555'
    }
  },
  watch: {
    'selectedDataObj.optionen.glossar' () {
      this.updateGlossar()
    },
    'daten.glossar.loading' () {
      this.updateGlossar()
    }
  },
  components: {
    Karte,
    Kreisdiagramm,
    Liniendiagramm,
    HorizontalesBalkenDiagramm,
    Tabelle
  }
}
</script>

<style scoped>
#generator-content {
  width: 100%;
  background-color: #fff;
  position: relative;
  overflow: auto;
  padding:  3rem 4rem;
}
.gc-top {
  position: relative;
  display: flex;
}
.gc-header {
  flex-grow: 1;
}
h3 {
  color: #0099e7;
}
h4 {
  font-size: 1.75rem;
  color: #0099e7;
}
.g-info {
  border: 3px solid #0099e7;
  padding: 0.5rem 1rem;
  margin-top: 2rem;
}
.g-glossar, .g-anmerkung, .g-quelle, .g-kartenlegende {
  position: relative;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #000;
}
.g-glossar a, .g-anmerkung a, .g-quelle a, .g-kartenlegende a {
  font-size: 1.5rem;
  line-height: 2rem;
  color: #0099e7;
}
.g-glossar > .g-g-link-frm {
  margin-left: 0.3rem;
}
.g-glossar > .g-g-link-frm:not(:last-child)::after {
  content: ', '
}
.g-g-link-frm {
  display: inline-block;
}
.g-g-link.open {
  font-weight: bold;
}
.g-g-info {
  position: absolute;
  bottom: 5rem;
  left: -1.3rem;
  right: -1.3rem;
  background: #fff;
  padding: 0.5rem 1rem;
  border: 3px solid #0099e7;
  font-size: 1.5rem;
  z-index: 5;
}
.g-g-info .g-g-link {
  display: block;
  margin-top: 0.5rem;
  text-align: right;
}
.g-g-info b {
  display: block;
  color: #0099e7;
  font-size: 1.8rem;
  line-height: 2.5rem;
  margin-bottom: 1rem;
}
.g-g-info >>> p {
  font-size: 1.7rem;
  line-height: 2.2rem;
}
.g-g-info .close {
  position: absolute;
  display: block;
  right: 0.75rem;
  top: 0.75rem;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.ueberblick {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  justify-content: center;
}
.ueberblick h2 {
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;;
}
.ueberblick h3 {
  cursor: pointer;
  margin-bottom: 1rem;
  color: inherit;
}
.ueberblick h4 {
  cursor: pointer;
  padding: 0.2rem 0.1rem;
  color: inherit;
}
.ueberblick h4:hover, .ueberblick h4:focus {
  text-decoration: underline;
}
.ueb-bereiche {
  border: 1px solid #0099eb;
  margin: 1rem 1%;
  padding: 0.5rem 1.5rem;
  padding-bottom: 1rem;
  width: 31%;
  text-align: center;
}
.ueb-bereiche.selected {
  background-color: #0099eb;
  color: #fff;
}
.ueb-bereiche.selected h3, .ueb-bereiche.selected h4 {
  color: #fff;
}
@media (max-width: 900px) {
  .ueb-bereiche {
    width: 48%;
  }
}
@media (max-width: 700px) {
  #generator-content {
    padding: 4rem 2rem;
  }
  .g-g-info >>> p {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .ueb-bereiche {
    width: 98%;
  }
}
</style>
