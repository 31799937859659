<template>
  <div :class="'g-options-frm' + (show ? ' show' : '')">
    <div class="g-options" :style="show ? ('height:' + optionsHeight + 'px;') : ''">
      <div ref="gOptions" class="g-options-content">
        <div class="g-select">
          <span>Farbe</span>
          <div class="select">
            <select v-model="daten.optionen.farben">
              <option :value="0">Standard</option>
              <option :value="1">Schwarz/Weiß</option>
              <option :value="2">Schwarz/Weiß - Füllmodus</option>
              <option :value="3 + fsKey" v-for="(fs, fsKey) in farbensets" :key="'fso' + fsKey">Farbset - {{ fs.titel }}</option>
            </select>
            <span :class="'icon wir-arrow-down'"></span>
          </div>
        </div>
        <div class="g-select my-1">
          <span>Sortierung</span>
          <div class="select">
            <select v-model="daten.optionen.sortierung">
              <option :value="null">Standard</option>
              <option value="!zeilenbeschriftung">Zeilenbeschriftung</option>
              <option :value="zbVal.val" v-for="(zbVal, zbKey) in zeilenbeschriftungen" :key="'zbo' + zbKey">{{ zbVal.titel }}</option>
            </select>
            <span :class="'icon wir-arrow-down'"></span>
          </div>
        </div>
        <a href="#" @click.prevent="daten.optionen.showKlasseneinteilung = !daten.optionen.showKlasseneinteilung" v-if="daten.auswahl.darstellung === 'karte'" class="g-check">
          <img :src="daten.sys.publicPath + 'diagrammgenerator/' + (daten.optionen.showKlasseneinteilung ? 'check-box-outline' : 'checkbox-blank-outline') + '.svg'" class="g-check-img"> Klasseneinteilung
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GeneratorOptionen',
  props: {
    daten: Object,
    show: Boolean
  },
  data () {
    return {
    optionsWidth: 100,
    optionsHeight: 100
    }
  },
  mounted () {
    this.getGcOptSize()
  },
  methods: {
    getGcOptSize () {
      this.$nextTick(() => {
        if (this.$refs.gOptions) {
          this.optionsWidth = this.$refs.gOptions.clientWidth
          this.optionsHeight = this.$refs.gOptions.clientHeight
        }
      })
    },
    getProp (obj, props) {
      if (props.length > 1) {
        if (obj[props[0]]) {
          return this.getProp(obj[props[0]], props.slice(1))
        } else {
          return undefined
        }
      } else {
        return obj[props[0]]
      }
    },
    hex2rgb (aHex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(aHex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },
  },
  computed: {
    selectedDarstellungObj () {
      return this.getProp(this.daten.mainData.akt.bereiche, [this.daten.auswahl.bereich, 'indikatoren', this.daten.auswahl.indikator, 'auspraegungen', (this.daten.auswahl.bezug || this.daten.auswahl.auspraegung), 'raumbezuege', this.daten.auswahl.raumbezug, 'zeitbezuege', this.daten.auswahl.zeitbezug, 'darstellungen', this.daten.auswahl.darstellung])
    },
    selectedDataObj () {
      return this.selectedDarstellungObj && this.daten.mainData.akt.dateien[this.selectedDarstellungObj.dataId] ? this.daten.mainData.akt.dateien[this.selectedDarstellungObj.dataId].content : null
    },
    zeilenbeschriftungen () {
      if (this.selectedDataObj) {
        if (!(this.selectedDataObj.jahr && this.selectedDataObj.jahr.length > 1)) {
          return this.selectedDataObj.daten.mainData.spalten.filter(s => s).map(s => { return {val: s, titel: s}})
        }
      }
      return [{val: '!wert', titel: 'Wert'}]
    },
    farbensets () {
      return this.daten.mainData.basis.farbensets.filter(fs => fs.selectable)
    }
  },
  watch: {
    selectedDarstellungObj (nVal) {
      if (nVal) {
        this.getGcOptSize()
      }
    },
    zeilenbeschriftungen (nVal) {
      let aVals = nVal ? nVal.map(s => s.val) : []
      if ([null, '!zeilenbeschriftung', ...aVals].indexOf(this.daten.optionen.sortierung) < 0) {
        this.daten.optionen.sortierung = this.daten.optionen.sortierung === '!wert' ? aVals[0] : '!wert'
      }
    }
  },
  components: {
  }
}
</script>

<style scoped>
.g-options-frm {
  position: relative;
  padding-bottom: 2rem;
}
.g-options-frm:after {
  content: '';
  display: block;
  background: #0099e7;
  bottom: 0;
  left: -10rem;
  width: 200%;
  height: 6px;
  position: relative;
  pointer-events: none;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}
.g-options-frm.show:after {
  opacity: 1;
}
.g-options {
  position: relative;
  height: 0px;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  z-index: 5;
  background: #d9f0fb;
}
.g-options-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0;
  padding: 1rem 0;
  background: #d9f0fb;
  transition: all 0.4s ease-in-out;
}
.g-options-frm.show .g-options .g-options-content {
  opacity: 1;
}
.g-check {
  display: block;
  white-space: nowrap;
  font-size: 2rem;
  padding: 0.5rem 0;
}
.g-check-img {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  top: -2px;
  position: relative;
}
.g-select > span {
  display: block;
  font-size: 1.4rem;
  margin-bottom: 0.75rem;
}
.select > select {
  width: 100%;
  background: #fff;
  padding: 0.75rem 4rem 0.75rem 1rem;
  min-width: 20rem;
}
.select {
  position: relative;
}
.select > .icon {
  position: absolute;
  right: 1.2rem;
  pointer-events: none;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.select > .icon.just-one {
  background: #0099e7;
  height: 1px;
  width: 1.5rem;
}
</style>
