<template>
  <div id="generator-export">
    <div class="generator-export-content">
      <div>PDF wird erstellt ...</div>
      <div v-if="pdfStep">Schritt: {{ pdfStep }}</div>
    </div>
    <div class="generator-export-dev" v-if="dev">
      <iframe :src="docDataUriString">
      </iframe>
      <button @click="pdfDevC">pdfDev</button>
      <button @click="pdfDownload">pdfDownload</button>
      <button @click="$emit('done')">Close</button>
    </div>
    <div id="generator-export-svg-cont-diagramm" :style="dev ? 'width:800px;height:600px;top:200px;overflow:auto;' : ''">
      <img :src="daten.sys.publicPath + 'diagrammgenerator/L_WIR_RGB.png'" ref="logo" class="gescd-l" @load="logoLoaded = true"/>
      <Karte v-if="daten.auswahl.darstellung === 'karte'"
        :daten="daten"
        :excelJSON="selectedDataObj"
        :jahr="daten.auswahl.zeitbezug"
        :klasseneinteilung="daten.optionen.showKlasseneinteilung"
        :blackWhite="daten.optionen.farben === 1 || daten.optionen.farben === 2"
        :blackWhiteFill="daten.optionen.farben === 2"
        :farbenSet="daten.optionen.farben >= 3 ? daten.optionen.farben - 3 : selectedDataObj ? selectedDataObj.optionen['farbenset'] || 0 : 0"
        :basisFarbe="hex2rgb(farbeB)"
        :farbe="hex2rgb(farbeA)"
        :textFarbe="hex2rgb(textFarbe)"
        :fontFamily="pdfFontFamily"
        :exportieren="true"
        ref="diagramm"
      />
      <Kreisdiagramm v-else-if="daten.auswahl.darstellung === 'kreis'"
        :daten="daten"
        :excelJSON="selectedDataObj"
        :jahr="daten.auswahl.zeitbezug"
        :blackWhite="daten.optionen.farben === 1 || daten.optionen.farben === 2"
        :blackWhiteFill="daten.optionen.farben === 2"
        :farbenSet="daten.optionen.farben >= 3 ? daten.optionen.farben - 3 : selectedDataObj ? selectedDataObj.optionen['farbenset'] || 0 : 0"
        :basisFarbe="hex2rgb(farbeA)"
        :farbe="hex2rgb(farbeB)"
        :textFarbe="hex2rgb(textFarbe)"
        :sortierung="daten.optionen.sortierung"
        :fontFamily="pdfFontFamily"
        :exportieren="true"
        ref="diagramm"
      />
      <Liniendiagramm v-else-if="daten.auswahl.darstellung === 'linien'"
        :daten="daten"
        :excelJSON="selectedDataObj"
        :jahr="daten.auswahl.zeitbezug"
        :blackWhite="daten.optionen.farben === 1 || daten.optionen.farben === 2"
        :blackWhiteFill="daten.optionen.farben === 2"
        :farbenSet="daten.optionen.farben >= 3 ? daten.optionen.farben - 3 : selectedDataObj ? selectedDataObj.optionen['farbenset'] || 0 : 0"
        :basisFarbe="hex2rgb(farbeA)"
        :farbe="hex2rgb(farbeB)"
        :textFarbe="hex2rgb(textFarbe)"
        :sortierung="daten.optionen.sortierung"
        :fontFamily="pdfFontFamily"
        :exportieren="true"
        ref="diagramm"
      />
      <HorizontalesBalkenDiagramm v-else-if="daten.auswahl.darstellung === 'hbalken'"
        :daten="daten"
        :excelJSON="selectedDataObj"
        :jahr="daten.auswahl.zeitbezug"
        :blackWhite="daten.optionen.farben === 1 || daten.optionen.farben === 2"
        :blackWhiteFill="daten.optionen.farben === 2"
        :farbenSet="daten.optionen.farben >= 3 ? daten.optionen.farben - 3 : selectedDataObj ? selectedDataObj.optionen['farbenset'] || 0 : 0"
        :basisFarbe="hex2rgb(farbeA)"
        :farbe="hex2rgb(farbeB)"
        :textFarbe="hex2rgb(textFarbe)"
        :sortierung="daten.optionen.sortierung"
        :fontFamily="pdfFontFamily"
        :exportieren="true"
        ref="diagramm"
      />
      <Tabelle
        :daten="daten"
        :excelJSON="selectedDataObj"
        :jahr="daten.auswahl.zeitbezug"
        :sortierung="daten.optionen.sortierung"
        :blackWhite="daten.optionen.farben === 1 || daten.optionen.farben === 2"
        :basisFarbe="hex2rgb(farbeA)"
        :farbe="hex2rgb(farbeB)"
        :textFarbe="hex2rgb(textFarbe)"
        :fontFamily="pdfFontFamily"
        :exportieren="true"
        ref="tabelle"
      />
    </div>
  </div>
</template>

<script>
import Karte from './Display/Karte';
import Kreisdiagramm from './Display/Kreisdiagramm';
import Liniendiagramm from './Display/Liniendiagramm';
import HorizontalesBalkenDiagramm from './Display/HorizontalesBalkenDiagramm';
import Tabelle from './Display/Tabelle';
import { jsPDF } from 'jspdf'
// import '../functions/font-SourceSansPro-normal.js'
// import '../functions/font-SourceSansPro-bold.js'
import 'svg2pdf.js'
import 'jspdf-autotable'

export default {
  name: 'GeneratorExportPdf',
  props: {
    daten: Object,
  },
  data () {
    return {
      dev: window.location.href === 'http://127.0.0.1:8080/',
      logoLoaded: false,
      waitLogo: false,
      pdfDev: false,
      doc: null,
      docDataUriString: null,
      pdf: {
        w: null,
        h: null,
        border: 15,
        borderBottom: 10,
        headerHeight: 25,
        footerHeight: 18,
        seiten: 1
      },
      pdfStep: null,
      pdfSeite: 1,
      pdfDone: false,
      pdfFontFamily: 'helvetica',
      anmerkungenAnhaengen: false,
      pdfTableData: {}
    }
  },
  mounted () {
    if (this.logoLoaded) {
      this.$nextTick(() => {
        this.updatePDF()
      })
    } else {
      this.waitLogo = true
    }
  },
  methods: {
    showPDF () {
      if (this.dev) {
        this.docDataUriString = this.doc.output('datauristring')
      } else {
        this.pdfDownload()
        this.$nextTick(() => {
          this.$emit('done')
        })
      }
      this.pdfStep = null
      this.pdfDone = true
    },
    pdfDownload () {
      this.doc.save('wir_' + this.selectedDarstellungObj.dataId + '_' + this.getDateTimeFormated(true) + '.pdf')
    },
    updatePDF () {
      console.log('updatePDF')
      this.pdfDone = false
      this.doc = new jsPDF({
        orientation: "landscape"
      })
      console.log(this.doc)
      this.pdf.w = this.doc.getPageWidth() - this.pdf.border * 2
      this.pdf.h = this.doc.getPageHeight() - this.pdf.border - this.pdf.headerHeight - this.pdf.footerHeight - this.pdf.borderBottom
      this.pdfSeite = 1
      this.pdf.seiten = this.$refs.diagramm && this.$refs.diagramm.$refs.svg ? 2 : 1
      this.$nextTick(() => {
        this.pdfStep = 1
      })
    },
    pdfCreator () {
      console.log('pdfCreator - Step', this.pdfStep)
      if (this.pdfStep) {
        if (this.pdfStep === 1) {
          if (this.$refs.diagramm && this.$refs.diagramm.$refs.svg) {
            // console.log(this.$refs.diagramm.$refs.svg)
            this.pdfHeader(this.doc, this.pdf)
            this.pdfFooter(this.doc, this.pdf, 1)
            // this.doc.rect(this.pdf.border, this.pdf.border + this.pdf.headerHeight, this.pdf.w, this.pdf.h)
            this.doc.svg(this.$refs.diagramm.$refs.svg, { x: this.pdf.border, y: this.pdf.border + this.pdf.headerHeight, width: this.pdf.w, height: this.pdf.h }).then(() => {
              console.log('SVG verarbeitet ...')
              this.pdfSeite += 1
              this.pdfStep = 2
            })
          } else {
            this.pdfStep = 2
          }
        } else if (this.pdfStep > 1) {
          if (this.pdfSeite > this.pdf.seiten) {
            if (this.anmerkung && this.anmerkungenAnhaengen) {
              // console.log('Anmerkungen?', this.pdfTableData)
              let aTop = this.pdf.border + this.pdf.headerHeight
              let anmerkungLines = this.doc.setFont(this.doc.getFont().fontName, 'normal').setFontSize(12).splitTextToSize(this.anmerkung, this.pdf.w)
              let anmerkungHeight = 10 + anmerkungLines.length * 4.4
              console.log('Höhe ...', anmerkungHeight)
              if (this.pdfTableData && this.pdfTableData.table && this.pdfTableData.table.finalY && (this.pdfTableData.table.finalY + 10 + anmerkungHeight) <= this.pdf.border + this.pdf.headerHeight + this.pdf.h) {
                aTop = this.pdfTableData.table.finalY + 10
              } else {
                this.doc.addPage()
                this.pdfHeader(this.doc, this.pdf)
                this.pdfFooter(this.doc, this.pdf, this.pdfSeite)
                this.pdfSeite += 1
              }
              this.doc.setTextColor('#000')
              this.pdfFuncSetText('Anmerkung: ', this.pdf.border, aTop, this.doc, {s: 12, fs: 'bold'})
              aTop += 10
              this.doc.setFont(this.doc.getFont().fontName, 'normal').setFontSize(12).text(anmerkungLines, this.pdf.border, aTop)
              // this.doc.rect(this.pdf.border, this.pdf.border + this.pdf.headerHeight, this.pdf.w, anmerkungHeight)
            }
            this.pdfStep = 1000
          } else {
            if (!(this.pdfSeite === 1 && this.pdfStep === 2)) {
              this.doc.addPage()
            }
            if (this.pdfStep === 2) {
              this.pdfTable(this.doc, this.pdf)
            } else {
              this.pdfHeader(this.doc, this.pdf)
              this.pdfInhalt(this.doc, this.pdf, this.pdfSeite)
              this.pdfFooter(this.doc, this.pdf, this.pdfSeite)
              this.pdfSeite += 1
            }
            this.pdfStep += 1
          }
        }
        if (this.pdfStep >= 1000) {
          this.showPDF()
        }
      }
    },
    pdfInhalt (doc, pdf, seite) {
      console.log('pdfInhalt', seite)
      if (this.dev && this.pdfDev) {
        doc.setDrawColor('#aaa')
        doc.rect(pdf.border, pdf.border + pdf.headerHeight, pdf.w, pdf.h)
      }
    },
    pdfTable (doc, pdf) {
      doc.autoTable({
        theme: 'plain',
        styles: {
          font: this.pdfFontFamily,
          valign: 'middle',
          cellPadding: 1.4,
          fontSize: 10
        },
        margin: { top: pdf.border + pdf.headerHeight + 5, bottom: pdf.borderBottom + pdf.footerHeight},
        willDrawCell: (hd) => {
          let drawLine = true
          if (hd.section === 'head' ) {
            doc.setTextColor(this.farbeA)
            doc.setLineWidth(0.5)
            doc.setDrawColor(this.farbeA)
          } else {
            doc.setLineWidth(0.3)
            doc.setDrawColor('#666')
            doc.setTextColor('#000')
            if (hd.cell.raw.className && hd.cell.raw.className.indexOf('bold') > -1) {
              this.doc.setFont(this.doc.getFont().fontName, 'bold')
            } else {
              this.doc.setFont(this.doc.getFont().fontName, 'normal')
            }
            if (hd.column.index > 0) {
              hd.cell.styles.halign = 'right'
            } else {
              drawLine = !this.$refs.tabelle.sortedListFirstRow[hd.row.index] || this.$refs.tabelle.sortedListFirstRow[hd.row.index].rowSpan === 1 || this.$refs.tabelle.sortedListFirstRow[hd.row.index].lastRowSpanElement
            }
          }
          if (drawLine) {
            doc.line(hd.cell.x + 0.75, hd.cell.y + hd.cell.height - 0.2, hd.cell.x + hd.cell.width - 0.75, hd.cell.y + hd.cell.height - 0.2)
          }
        },
        didDrawPage: (pdfTab) => {
          this.pdfTableData = pdfTab
          // console.log('didDrawPage', pdfTab.table)
          this.pdfHeader(this.doc, this.pdf)
          this.pdfFooter(this.doc, this.pdf, this.pdfSeite)
          this.pdfSeite += 1
        },
        html: '#export-table'
      })
    },
    pdfHeader (doc, pdf) {
      let imgW = 500 / 30
      let imgH = 433 / 30
      let aTop = pdf.border
      doc.setTextColor(this.farbeA)
      this.pdfFuncSetText(this.selectedDataObj.optionen.titel, pdf.border, aTop, doc, {s: 28, fs: 'bold', mw: pdf.w - imgW - 10})
      aTop += 11
      doc.setTextColor('#000')
      if (this.selectedDataObj.optionen.untertitel) {
        this.pdfFuncSetText(this.selectedDataObj.optionen.untertitel, pdf.border, aTop, doc, {s: 20, fs: 'normal', mw: pdf.w - imgW - 10})
        aTop += 8.5
      }
      if (this.daten.auswahl.zeitbezug) {
        this.pdfFuncSetText(this.daten.auswahl.zeitbezug, pdf.border, aTop, doc, {s: 15, fs: 'normal', mw: pdf.w - imgW - 10})
      }
      doc.addImage(this.$refs.logo, 'PNG', pdf.border + pdf.w - imgW, pdf.border, imgW, imgH)
      if (this.dev && this.pdfDev) {
        doc.setDrawColor('#aaa')
        doc.rect(pdf.border, pdf.border, pdf.w, pdf.headerHeight)
      }
    },
    pdfFooter (doc, pdf, seite=1) {
      let aLeft = pdf.border
      let aTop = pdf.h + pdf.border + pdf.headerHeight + 3
      // Quelle & Anmerkung
      let aTxtW = aLeft
      doc.setTextColor('#000')
      if (this.quelle) {
        aTxtW += this.pdfFuncSetText('Quelle: ', aTxtW, aTop, doc, {s: 12, fs: 'bold'})
        aTxtW += this.pdfFuncSetText(this.quelle, aTxtW, aTop, doc, {s: 12, fs: 'normal'})
        aTxtW += 5
      }
      if (this.anmerkung && seite === 1) {
        // console.log('Breiten', pdf.w, aTxtW + this.pdfFuncSetText('Anmerkung: ', aTxtW, aTop, doc, {s: 12, fs: 'bold'}, true) + this.pdfFuncSetText(this.anmerkung, aTxtW, aTop, doc, {s: 12, fs: 'normal'}, true))
        aTxtW += this.pdfFuncSetText('Anmerkung: ', aTxtW, aTop, doc, {s: 12, fs: 'bold'})
        if (pdf.w >= aTxtW + this.pdfFuncSetText('Anmerkung: ', aTxtW, aTop, doc, {s: 12, fs: 'bold'}, true) + this.pdfFuncSetText(this.anmerkung, aTxtW, aTop, doc, {s: 12, fs: 'normal'}, true)) {
          aTxtW += this.pdfFuncSetText(this.anmerkung, aTxtW, aTop, doc, {s: 12, fs: 'normal'})
          this.anmerkungenAnhaengen = false
        } else {
          aTxtW += this.pdfFuncSetText('Siehe unten', aTxtW, aTop, doc, {s: 12, fs: 'normal'})
          this.anmerkungenAnhaengen = true
        }
        aTxtW += 5
      }
      console.log(aTxtW)
      // Zeitstempel, Copyright und Seitenzahl
      doc.setLineWidth(0.4)
      doc.line(aLeft - 1, aTop + 6, aLeft + pdf.w + 1, aTop + 5.5)
      doc.setTextColor('#666')
      this.pdfFuncSetText(this.getDateTimeFormated(), aLeft, aTop + 7.5, doc, {s: 12, fs: 'normal'})
      aTxtW = 0
      if (this.kartenlegende && doc.internal.getNumberOfPages() === 1) {
        aTxtW += this.pdfFuncSetText('https://www.wissensportal-frankfurtrheinmain.de/fileadmin/201016_WiR_Kartenlegende.pdf', aLeft + pdf.w, aTop + 7.5, doc, {s: 12, fs: 'normal', a: 'right'})
        aTxtW += this.pdfFuncSetText('Kartenlegende: ', aLeft + pdf.w - aTxtW, aTop + 7.5, doc, {s: 12, fs: 'bold', a: 'right'})
      }
      this.pdfFuncSetText('© Initiative Wissensregion FrankfurtRheinMain (WiR)', aLeft, aTop + 13, doc, {s: 12, fs: 'normal'})
      this.pdfFuncSetText(doc.internal.getNumberOfPages().toString(), aLeft + pdf.w, aTop + 12.5, doc, {s: 15, fs: 'normal', a: 'right'})
      if (this.dev && this.pdfDev) {
        doc.setDrawColor('#aaa')
        doc.rect(pdf.border, aTop, pdf.w, pdf.footerHeight)
      }
    },
    getDateTimeFormated (file=false) {
      var d = new Date()
      var dT = d.getDate()
      var dM = d.getMonth() + 1
      var dH = d.getHours()
      var dMi = d.getMinutes()
      if (file) {
        return (dT < 10 ? '0' + dT : dT) + '-' + (dM < 10 ? '0' + dM : dM) + '-' + d.getFullYear() + '_' + (dH < 10 ? '0' + dH : dH) + '-' + (dMi < 10 ? '0' + dMi : dMi)
      }
      return (dT < 10 ? '0' + dT : dT) + '.' + (dM < 10 ? '0' + dM : dM) + '.' + d.getFullYear() + ', ' + (dH < 10 ? '0' + dH : dH) + ':' + (dMi < 10 ? '0' + dMi : dMi) + ' Uhr'
    },
    pdfFuncSetText (txt, x, y, doc, opt, justGetWidth) { // s = size, f = [fontName, fontStyle], fs = fontStyle
      let aOpt = { baseline: 'top' }
      if (opt) {
        if (opt.s) { doc.setFontSize(opt.s) }
        if (opt.f) { doc.setFont(opt.f[0], opt.f[1] || 'normal') }
        if (opt.fs) { doc.setFont(doc.getFont().fontName, opt.fs) }
        if (opt.a) { aOpt.align = opt.a }
        if (opt.mw) {
          let maxDg = doc.getFontSize() * 0.66
          while (maxDg > 0 && doc.getTextWidth(txt) > opt.mw) {
            doc.setFontSize(doc.getFontSize() - 0.5)
            maxDg -= 0.5
          }
        }
      }
      if (!justGetWidth) {
        doc.text(txt, x, y, aOpt)
      }
      return doc.getTextWidth(txt)
    },
    pdfDevC () {
      this.pdfDev = !this.pdfDev
      this.updatePDF()
    },
    getProp (obj, props) {
      if (props.length > 1) {
        if (obj[props[0]]) {
          return this.getProp(obj[props[0]], props.slice(1))
        } else {
          return undefined
        }
      } else {
        return obj[props[0]]
      }
    },
    hex2rgb (aHex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(aHex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    }
  },
  computed: {
    anmerkung () {
      // return 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet'
      return this.selectedDataObj && this.selectedDataObj.optionen ? this.selectedDataObj.optionen.anmerkungen : null
    },
    quelle () {
      return this.selectedDataObj && this.selectedDataObj.optionen ? this.selectedDataObj.optionen.quelle : null
    },
    kartenlegende () {
      return this.selectedDataObj && this.daten.auswahl.darstellung === 'karte' && this.selectedDataObj.optionen && this.selectedDataObj.optionen.zeilenbeschriftung === 'FrmRegionen'
    },
    selectedDarstellungObj () {
      return this.getProp(this.daten.mainData.akt.bereiche, [this.daten.auswahl.bereich, 'indikatoren', this.daten.auswahl.indikator, 'auspraegungen', (this.daten.auswahl.bezug || this.daten.auswahl.auspraegung), 'raumbezuege', this.daten.auswahl.raumbezug, 'zeitbezuege', this.daten.auswahl.zeitbezug, 'darstellungen', this.daten.auswahl.darstellung])
    },
    selectedDataObj () {
      return this.selectedDarstellungObj && this.daten.mainData.akt.dateien[this.selectedDarstellungObj.dataId] ? this.daten.mainData.akt.dateien[this.selectedDarstellungObj.dataId].content : null
    },
    farbensets () {
      return this.daten.mainData.basis.farbensets
    },
    farbeA () {
      if ([1,2].indexOf(this.daten.optionen.farben) > -1) {
        return '#000000'
      }
      return this.daten.optionen.farben >= 3 ? this.farbensets[this.daten.optionen.farben - 3].a : this.farbensets[this.selectedDataObj ? this.selectedDataObj.optionen['farbenset'] || 0 : 0].a
    },
    farbeB () {
      if ([1,2].indexOf(this.daten.optionen.farben) > -1) {
        return '#eeeeee'
      }
      return this.daten.optionen.farben >= 3 ? this.farbensets[this.daten.optionen.farben - 3].b : this.farbensets[this.selectedDataObj ? this.selectedDataObj.optionen['farbenset'] || 0 : 0].b
    },
    textFarbe () {
      return [1,2].indexOf(this.daten.optionen.farben) > -1 ? '#000000' : '#555555'
    }
  },
  watch: {
    logoLoaded (nVal) {
      if (nVal && this.waitLogo) {
        this.$nextTick(() => {
          this.updatePDF()
        })
        this.waitLogo = false
      }
    },
    pdfStep () {
      this.$nextTick(() => {
        this.pdfCreator()
      })
    }
  },
  components: {
    Karte,
    Kreisdiagramm,
    Liniendiagramm,
    HorizontalesBalkenDiagramm,
    Tabelle
  }
}
</script>

<style scoped>
#generator-export {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0,0,0,0.3);
  z-index: 100;
}
.generator-export-content {
  background: #fff;
  padding: 1rem 2rem;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.75);
  font-size: 2rem;
  text-align: center;
}
.generator-export-dev {
  position: absolute;
  right: 5vh;
  top: 5vh;
  width: 82vh;
  height: 90vh;
  background: #666;
  border: 3px solid #0099e7;
}
.generator-export-dev iframe {
  width: 100%;
  height: 100%;
  border: none;
}
#generator-export-svg-cont-diagramm {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 1px;
  height: 1px;
  background: #fff;
}
#generator-export-svg-cont-diagramm > div {
  width: 1200px;
}
.gescd-l {
  width: 50px;
  height: auto;
}
</style>
