<template>
  <div id="select-field">
    <div class="title" v-if="title">
      <span v-if="displaySchritt">Schritt {{ schritt }}:</span>
      {{ title }}
    </div>
    <div class="select" v-if="options">
      <select v-model="aValue">
        <option disabled hidden :value="null">Bitte wählen</option>
        <option
          v-for="(option, oKey) in options"
          :key="'sf' + oKey"
          :value="option.id">
          {{ option.title }}
        </option>
      </select>
      <span :class="'icon' + (options.length > 1 ? ' wir-arrow-down' : ' just-one')"></span>
    </div>
    <div class="gs-darstellungen" v-if="darstellung">
      <a href="#"
        :class="(darstellung && darstellung[aKey]) ? (aKey === aValue ? ' selected' : '') : ' disabeld'"
        :title="aVal.titel"
        @click.prevent="selDarstellung(aKey)"
        v-for="(aVal, aKey) in darstellungsIcons" :key="'d' + aKey"
      >
        <img :src="daten.sys.publicPath + 'diagrammgenerator/' + aVal.file" :alt="aVal.titel">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectField',
  props: {
    title: [String, null],
    schritt: null,
    options: [Array, null],
    darstellung: [Object, null],
    value: null,
    daten: [Object, null]
  },
  data () {
    return {
      aValue: null,
      darstellungsIcons: {
        karte: { file: 'map.svg', titel: 'Karte' },
        kreis: { file: 'circle-slice-7.svg', titel: 'Kreisdiagramm' },
        linien: { file: 'triangle-wave.svg', titel: 'Liniendiagramm' },
        vbalken: { file: 'signal.svg', titel: 'Vertikales Balkendiagramm' },
        hbalken: { file: 'sort-reverse-variant.svg', titel: 'Horizontales Balkendiagramm' },
        tabelle: { file: 'table.svg', titel: 'Tabelle' }
      }
    }
  },
  mounted () {
    this.aValue = this.value
  },
  methods: {
    selDarstellung (aDarstellung) {
      if (this.darstellung && this.darstellung[aDarstellung]) {
        this.aValue = this.darstellung[aDarstellung].id
      }
    }
  },
  computed: {
    displaySchritt () {
      return typeof this.schritt === 'number' || (typeof this.schritt === 'string' && this.schritt.trim().length > 0)
    }
  },
  watch: {
    value () {
      this.aValue = this.value
    },
    aValue () {
      if (this.aValue !== this.value) {
        this.$emit('input', this.aValue)
      }
    }
  }
}
</script>

<style scoped>
#select-field {
  position: relative;
  margin-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.select > select {
  width: 100%;
  background: #fff;
  padding: 0.75rem 4rem 0.75rem 1rem;
}
.select {
  position: relative;
}
.select > .icon {
  position: absolute;
  right: 1.2rem;
  pointer-events: none;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.select > .icon.just-one {
  background: #0099e7;
  height: 1px;
  width: 1.5rem;
}
.title {
  position: relative;
  font-size: 1.8rem;
  margin-bottom: 2.5rem;
  text-transform: uppercase;
  font-weight: bold;
}
.title > span {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1.4rem;
  -webkit-transform: translateY(-1.4rem);
  transform: translateY(-1.4rem);
  font-weight: 400;
}
.title:after {
  border-top: 0.5rem solid #0099e7;
  content: '';
  width: 16rem;
  position: absolute;
  left: -7.2rem;
  bottom: -1rem;
  height: 1.1px;
}
.gs-darstellungen {
  margin-bottom: 1rem;
}
.gs-darstellungen > a {
  display: inline-block;
  background: #fff;
  width: 13.3%;
  margin-right: 4%;
  padding: 0.75rem;
  border: 2px solid #fff;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
.gs-darstellungen > a:hover {
  background-color: #0099e7;
}
.gs-darstellungen > a.selected {
  border-color: #0099e7;
}
.gs-darstellungen > a.disabeld {
  border-color: #fff;
  background-color: #fff;
  opacity: 0.3;
  cursor: default;
}
.gs-darstellungen > a:last-child {
  margin-right: 0;
}
.gs-darstellungen > a > img {
  display: block;
  width: 100%;
  height: auto;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
.gs-darstellungen > a:not(.disabeld):hover > img {
  filter: invert(1);
}
@media (max-width: 1280px) {
  .gs-darstellungen > a {
    width: 30%;
    margin-bottom: 0.5rem;
  }
  .gs-darstellungen > a:nth-child(3) {
    margin-right: 0;
  }
  .select > select {
    padding: 0.75rem 3rem 0.75rem 1rem;
  }
  .select > .icon {
    right: 0.75rem;
    font-size: 2rem;
    line-height: 0;
  }
  .gs-darstellungen > a.disabeld {
    display: none;
  }
}
</style>
