<template>
  <div ref="svgContainer" class="svgContainer" :style="'min-height:' + height +'px'">
    <svg ref="svg" :width="width" :height="height" v-if="active && !refresh && diagrammData" :viewBox="'0 0 ' + width + ' ' + height">
      <defs>
        <pattern :id="'valpat' + pKey" patternUnits="userSpaceOnUse" :patternTransform="pKey%2 == 0 ? 'scale(1, -1)' : null" :width="13" :height="13" v-for="(pObj, pKey) in pattern" :key="'valpat' + pKey">
          <template v-if="blackWhite">
            <rect x="-1" y="-1" width="15" height="15" :fill="'#000'" />
          </template>
          <rect x="-1" y="-1" width="15" height="15" :fill="pObj" :data-xxx="pObj + ' - ' + pKey" v-else />
        </pattern>
      </defs>
      <text ref="txtWidth" x="0" y="-20" fill="transparent" :font-family="fontFamily" :font-size="fontSize">{{ localString(minVal + maxVal )}}</text>
      <SvgText
        :x="dLeft + dWidth / 2"
        :y="top - fontSize + 3" :fill="getCssRGB(textFarbe)" :font-family="fontFamily" :font-size="fontSize + 1" center
        :text="excelJSON.optionen['einheitenart']"
      />
      <template v-if="minVal >= 0">
        <g v-for="dg in Array(einteilungen + 1).keys()" :key="'gb' + dg">
          <line
            :x1="dLeft - 8" :y1="top + dHeight - dHeight / einteilungen * dg"
            :x2="dLeft + dWidth" :y2="top + dHeight - dHeight / einteilungen * dg" :stroke="secColor" stroke-width="1"
          />
          <SvgText
            :x="dLeft - 12"
            :y="top + dHeight - dHeight / einteilungen * dg + (dg === 0 ? 0 : (dg === einteilungen ? fontSize * 0.8 : fontSize * 0.3))" :fill="getCssRGB(textFarbe)" :font-family="fontFamily" :font-size="fontSize" right
            :text="localString(minVal + maxVal / einteilungen * dg)"
          />
        </g>
      </template>
      <g v-for="(rVal, rKey) in diagrammData.rows" :key="'r' + rKey">
        <line
          :x1="dLeft + dWidth / (diagrammData.rowsLen - 1) * rKey" :y1="top"
          :x2="dLeft + dWidth / (diagrammData.rowsLen - 1) * rKey" :y2="top + dHeight + 6" :stroke="secColor" stroke-width="1"
        />
        <SvgText
          :x="dLeft + dWidth / (diagrammData.rowsLen - 1) * rKey + (rKey === 0 ? -10 : (rKey === diagrammData.rowsLen - 1 ? 10 : 0))"
          :y="top + dHeight + 8 + fontSize" :fill="getCssRGB(textFarbe)" :font-family="fontFamily" :font-size="fontSize"
          :center="rKey > 0 && rKey < diagrammData.rowsLen - 1"
          :right="rKey === diagrammData.rowsLen - 1"
          :text="rVal"
        />
      </g>
      <g class="ld-data" v-for="(row, lKey) in diagrammData.rows" :key="'gl' + lKey">
        <g v-for="(vVal, vKey) in diagrammData.valsData[row]" :key="'v' + vKey">
          <template v-if="vVal.vals.val !== null && vVal.vals.val !== undefined && !hiddenCols[vKey] && minVal >= 0">
            <line
              :x1="dLeft + dWidth / (diagrammData.rowsLen - 1) * (lKey - 1)"
              :y1="top + dHeight - dHeight / maxVal * ((diagrammData.valsData[diagrammData.rows[vVal.lVals]][vKey].vals.val || 0) - minVal)"
              :x2="dLeft + dWidth / (diagrammData.rowsLen - 1) * lKey"
              :y2="top + dHeight - dHeight / maxVal * ((vVal.vals.val || 0) - minVal)"
              :stroke="blackWhite ? '#000' : pattern[vKey]" stroke-width="2.5"
              :stroke-dasharray="blackWhite ?(vKey > 0 ? Math.pow(2, vKey) + (vKey === 1 ? ' 4' : '') : null) : null"
              stroke-linecap="round"
              v-if="vVal.lVals > -1 && diagrammData.valsData[diagrammData.rows[vVal.lVals]][vKey].vals.val !== null && diagrammData.valsData[diagrammData.rows[vVal.lVals]][vKey].vals.val !== undefined"
            />
            <circle
              :cx="dLeft + dWidth / (diagrammData.rowsLen - 1) * lKey"
              :cy="top + dHeight - dHeight / maxVal * ((vVal.vals.val || 0) - minVal)"
              :r="hCircle.l === lKey && hCircle.v === vKey ? 10 : 4"
              :fill="'url(#valpat' + vKey + ')'"
            />
            <circle
              :cx="dLeft + dWidth / (diagrammData.rowsLen - 1) * lKey"
              :cy="top + dHeight - dHeight / maxVal * ((vVal.vals.val || 0) - minVal)"
              @mouseover="hoverCircle(lKey, vKey, true)"
              @mouseleave="hoverCircle(lKey, vKey, false)"
              class="cur-point"
              r="10"
              fill="transparent"
            />
          </template>
        </g>
      </g>
      <!-- Legende -->
      <g :transform="'translate(' + (dLeft + dWidth / 2) + ', ' + (top + dHeight + 30 + ((fontSize + 5) * diagrammData.schnitt.length) + 10) + ')'" class="legende" v-if="rowsData">
        <g
          :transform="'translate(' + ((rowsData.rowLeft[lVal.row] ? rowsData.rowLeft[lVal.row] : 0) + lVal.left - 5) + ', ' + (lVal.top) + ')'"
          ref="legende"
          :data-text="lVal.titel"
          v-for="(lVal, lKey) in rowsData.data" :key="'leg' + lKey"
          :class="hiddenCols[lKey] ? 'unselected' : ''"
          @click="toggleCol(lKey, rowsData.data.length, $event)"
        >
          <rect
            :x="0" :y="0"
            :width="fontSize" :height="fontSize"
            :stroke-width="1" stroke="#000"
            :fill="'url(#valpat' + lKey + ')'"
          />
          <SvgText
            :x="fontSize + 5"
            :y="fontSize * 0.8" :font-family="fontFamily"
            :font-size="fontSize"
            :fill="getCssRGB(textFarbe)"
            :text="lVal.titel"
          />
        </g>
      </g>
    </svg>
    <div v-else>
      Daten nicht mit Ansicht kompatibel!
    </div>
    <div class="dg-hint-text" v-if="rowsData">Links Klick - Element an-/abwählen | Strg + Links Klick - Nur dieses Element auswählen.</div>
    <div ref="tooltip" class="diagramm-tooltip" :style="'left:' + mX + 'px;top:' + mY + 'px;'" v-if="hCircle.l !== null && hCircle.v !== null">
      <div>
        <h3>{{ diagrammData.rows[hCircle.l] }}</h3>
        <template v-if="diagrammData.valsData[diagrammData.rows[hCircle.l]].length < 6">
          <div v-for="(vVal, vKey) in diagrammData.valsData[diagrammData.rows[hCircle.l]]" :key="'dtt' + vKey">
            <span :class="'diagramm-tooltip-value' + (vVal.titel === diagrammData.valsData[diagrammData.rows[hCircle.l]][hCircle.v].titel ? ' selected' : '')">{{ vVal.titel }}:</span>
            {{ localString(vVal.vals.val || 0) }}
          </div>
        </template>
        <div v-else>
          <span class="diagramm-tooltip-value selected">{{ diagrammData.valsData[diagrammData.rows[hCircle.l]][hCircle.v].titel }}:</span>
          {{ localString(diagrammData.valsData[diagrammData.rows[hCircle.l]][hCircle.v].vals.val) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgText from '../Svg/SvgText';

export default {
  name: 'Liniendiagramm',
  props: {
    daten: Object,
    excelJSON: Object,
    jahr: [Number, String],
    fontSize: { type: Number, default: 14 },
    lSpacer: { type: Number, default: 5 },
    fontFamily: { type: String, default: 'Source Sans Pro, sans-serif' },
    secColor: { type: String, default: '#ccc' },
    schnittColor: { type: String, default: '#444' },
    farbenSet: { type: Number, default: 0 },
    basisFarbe: { type: Object, default: function () { return { r: 207, g: 190, b: 185 } } },
    farbe: { type: Object, default: function () { return { r: 94, g: 72, b: 65 } } },
    textFarbe: { type: Object, default: function () { return { r: 0, g: 0, b: 0 } } },
    blackWhite: { type: Boolean, default: false },
    blackWhiteFill: { type: Boolean, default: false },
    sortierung: { type: String, default: null},
    update: { type: Boolean, default: false},
    active: { type: Boolean, default: true},
    exportieren: { type: Boolean, default: false}
  },
  data: () => ({
    mTeiler: [1, 2, 5, 10, 20, 25, 50, 100, 500, 1000, 2000, 5000, 10000, 50000, 100000, 250000, 500000, 1000000, 2000000, 5000000, 10000000, 25000000, 50000000],
    width: 100,
    dLeft: 50,
    refresh: true,
    hCircle: { l: null, v: null },
    mX: 200,
    mY: 10,
    rowsData: { height: 0, data: [], rowLeft: [] },
    rowsDataTrys: 0,
    hiddenCols: {}
  }),
  mounted () {
    this.$root.$el.addEventListener('resize', this.resize)
    this.$root.$el.addEventListener('mousemove', this.mouseMove)
    this.resize()
    this.$nextTick(() => {
      this.refresh = false
      this.$emit('isOk', 'linien', (this.diagrammData) ? true : false)
    })
    // console.log('diagrammData', this.diagrammData)
  },
  beforeDestroy () {
    this.$root.$el.removeEventListener('mousemove', this.mouseMove)
    this.$root.$el.removeEventListener('resize', this.resize)
  },
  methods: {
    toggleCol (k, l, e) {
      if (e.ctrlKey) {
        let showAll = !this.hiddenCols[k] && Object.keys(this.hiddenCols).filter(h => this.hiddenCols[h]).length > l - 2
        for (let i = 0; i < l; i++) {
          this.$set(this.hiddenCols, i, !(showAll || k === i))
        }
      } else {
        this.$set(this.hiddenCols, k, !this.hiddenCols[k])
      }
      // console.log('toggleCol', k, this.hiddenCols, e)
    },
    updateRowsData () {
      if (this.diagrammData) {
        // console.log('updateRowsData')
        let reDo = false
        let aData = []
        let dg = 0
        let allWidth = 0
        this.diagrammData.data.forEach(r => {
          let left = 0
          let top = 0
          let width = 0
          if (this.$refs.legende && this.$refs.legende[dg] && this.$refs.legende[dg].dataset && this.$refs.legende[dg].dataset.text === r.titel) {
            width = this.$refs.legende[dg].getBBox().width + 10
            allWidth += width
          } else {
            reDo = true
          }
          aData.push({ titel: r.titel, left, top, width, row: 0})
          dg += 1
        })
        if (allWidth < 1) {
          reDo = true
        }
        this.rowsData = { height: 0, data: aData, rowLeft: [] }
        if (reDo && this.rowsDataTrys < 10) {
          this.rowsDataTrys += 1
          this.$nextTick(() => {
            this.updateRowsData()
          })
        } else {
          // console.log('rowsDataTrys', this.rowsDataTrys)
          let aTop = 0
          let aLeft = 0
          let aRow = 0
          this.rowLeft = []
          this.rowsData.data.forEach(rd => {
            if (aLeft + rd.width > this.dWidth) {
              this.rowsData.rowLeft.push((this.dWidth - aLeft) / 2)
              aLeft = 0
              aTop += this.fontSize + 8
              aRow += 1
            }
            rd.top = aTop
            rd.left = aLeft - this.dWidth / 2
            rd.row = aRow
            aLeft += rd.width
          })
          this.rowsData.rowLeft.push((this.dWidth - aLeft) / 2)
          this.rowsData.height = aTop + this.fontSize + 12
          this.$nextTick(() => {
            this.dLeft = 50
            if (this.diagrammData && this.diagrammData.rows && this.$refs.txtWidth) {
              this.dLeft = this.$refs.txtWidth.getBBox().width + 20
              if (this.dLeft < 50) {
                this.dLeft = 50
              }
            }
          })
          // console.log(this.rowsData)
        }
      } else {
        this.rowsData = { height: 0, data: [], rowLeft: [] }
      }
    },
    hoverCircle (lKey, vKey, stat) {
      this.hCircle = stat ? { l: lKey, v: vKey } : { l: null, v: null }
    },
    mouseMove (e) {
      this.mX = e.clientX
      this.mY = e.clientY
      if (this.$refs.tooltip && this.$refs.tooltip.children && this.$refs.tooltip.children[0]) {
        if (this.mY - this.$refs.tooltip.children[0].clientHeight < 10) {
          this.mY = this.$refs.tooltip.children[0].clientHeight + 10
        }
        let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        if (vw > 0 && this.mX + this.$refs.tooltip.children[0].clientWidth > vw - 10) {
          this.mX = vw - this.$refs.tooltip.children[0].clientWidth - 10
        }
      }
    },
    getCssRGB (rgb) {
      return 'rgb(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ')'
    },
    setMinMax (dData) {
      dData.data.forEach(row => {
        row.vals.forEach(val => {
          let aVal = val.val || 0
          if (aVal < dData.min) { dData.min = aVal }
          if (aVal > dData.max) { dData.max = aVal }
        })
      })
      dData.min = parseFloat(dData.min.toFixed(2))
      dData.max = parseFloat(dData.max.toFixed(2))
      if (dData.min > 99.8 && dData.max < 100.2) {
        dData.min = 100
        dData.max = 100
      }
      return dData
    },
    setValsData (dData) {
      dData.rows.forEach(aRow => {
        dData.valsData[aRow] = []
      })
      dData.data.forEach(aData => {
        for (let i = 0; i < aData.vals.length; i++) {
          dData.valsData[aData.vals[i].name].push({vals: aData.vals[i], lVals: i - 1, titel: aData.titel})
        }
      })
      return dData
    },
    resize () {
      this.updateWidth()
      this.updateRowsData()
    },
    updateWidth () {
      this.width = 400
      this.$nextTick(() => {
        this.width = this.$refs.svgContainer ? this.$refs.svgContainer.clientWidth : 400
        if (this.width < 600) {
          this.width = 600
        }
      })
    },
    localString (val, dType = 'main') {
      let fVal = val
      if (typeof val === 'undefined' && this.excelJSON.optionen['zahlen formatierung'] && this.excelJSON.optionen['zahlen formatierung'].indexOf('float') > -1) {
        fVal = 0
      }
      if (typeof fVal === 'number') {
        if ((dType === 'main' || dType === 'schnitt') && this.excelJSON.optionen['zahlen formatierung'] && this.excelJSON.optionen['zahlen formatierung'].indexOf('float') > -1) {
          let aDigits = parseInt(this.excelJSON.optionen['zahlen formatierung'].substr(5))
          fVal = fVal.toLocaleString(undefined, {minimumFractionDigits: aDigits, maximumFractionDigits: aDigits})
        } else {
          fVal = fVal.toLocaleString()
        }
      }
      let eOption = 'einheitenart kurz'
      if (dType === 'schnitt') {
        eOption = 'einheitenart kurz schnitt'
      }
      if (dType === 'summe') {
        eOption = 'einheitenart kurz summen'
      }
      if (fVal && this.excelJSON.optionen[eOption]) {
        if (!((typeof val === 'string') && this.excelJSON.optionen[eOption] === '%')) {
          fVal += ' ' + this.excelJSON.optionen[eOption]
        }
      }
      return fVal ? fVal : ''
    },
    SpaltenBeiJahr (row) {
      if (this.excelJSON.jahr && this.excelJSON.jahr.length > 1) {
        return [row.obj[this.jahr]]
      }
      return row.list
    },
    sortList(sortierung, list) {
      if (sortierung) {
        if (sortierung === '!zeilenbeschriftung') {
          return list.slice().sort((a, b) => {
            let aV = this.daten.mainData.functions.regionen.getByVal(a.name, this.excelJSON.optionen['zeilenbeschriftung']).name
            let bV = this.daten.mainData.functions.regionen.getByVal(b.name, this.excelJSON.optionen['zeilenbeschriftung']).name
            return aV > bV ? 1 : aV < bV ? -1 : 0
          })
        } else if (sortierung === '!wert') {
          return list.slice().sort((a, b) => {
            let aV = this.SpaltenBeiJahr(a.spalten)[0].val
            let bV = this.SpaltenBeiJahr(b.spalten)[0].val
            return aV > bV ? 1 : aV < bV ? -1 : 0
          })
        } else {
          return list.slice().sort((a, b) => {
            let aV = a.spalten.obj[sortierung].val
            let bV = b.spalten.obj[sortierung].val
            return aV > bV ? 1 : aV < bV ? -1 : 0
          })
        }
      } else {
        return list
      }
    }
  },
  computed: {
    sortedList () {
      return this.sortList(this.sortierung, this.excelJSON.daten.mainData.list)
    },
    sortedSchnitt () {
      return this.sortList(this.sortierung, this.excelJSON.daten.mainData.schnitt.list)
    },
    sortedSumme () {
      return this.sortList(this.sortierung, this.excelJSON.daten.mainData.summe.list)
    },
    diagrammData () {
      let dData = null
      if (this.excelJSON.jahr && this.excelJSON.jahr.length === 1 || this.jahr === 'Mehrere Jahre' && this.excelJSON.optionen['mehrere jahre']) {
        dData = { data: [], schnitt: [], min: Infinity, max: -Infinity, multipleData: false, rows: [], rowsLen: 1, valsData: {} }
        this.sortedList.forEach(row => {
          dData.data.push({
            titel: this.daten.mainData.functions.regionen.getByVal(row.name, this.excelJSON.optionen['zeilenbeschriftung']).name,
            vals: row.spalten.list
          })
          row.spalten.list.forEach(spalte => {
            if (dData.rows.indexOf(spalte.name) < 0) {
              dData.rows.push(spalte.name)
            }
          })
          if (row.spalten.list.length > dData.rowsLen) {
            dData.rowsLen = row.spalten.list.length
          }
        })
        dData.multipleData = true
        dData.schnitt.sort((a, b) => a.vals[0].val < b.vals[0].val ? -1 : (a.vals[0].val > b.vals[0].val ? 1 : 0))
        dData = this.setMinMax(dData)
        dData = this.setValsData(dData)
        if (dData.rowsLen < 2) {
          return null
        }
      }
      return dData
    },
    pattern () {
      let aPat = []
      if (this.diagrammData.multipleData) {
        // farbenSet
        let useFarbensets = this.daten.mainData.basis.farbensets
        let cycleFarbenSet = this.farbenSet || 0
        if (this.excelJSON.optionen.farben && this.excelJSON.optionen.farben.length > 0) {
          useFarbensets = this.excelJSON.optionen.farben.map(f => this.daten.mainData.basis.farbensets[f] || this.daten.mainData.basis.farbensets[0])
          cycleFarbenSet = 0
          // console.log('Farben', this.excelJSON.optionen.farben, useFarbensets)
        }
        for (let i = 0; i < this.diagrammData.data.length; i++) {
          aPat.push(useFarbensets[cycleFarbenSet].a)
          cycleFarbenSet += 1
          if (cycleFarbenSet >= useFarbensets.length) {
            cycleFarbenSet = 0
          }
        }
      } else {
        aPat.push(this.daten.mainData.basis.farbensets[this.farbenSet || 0].a)
      }
      return aPat
    },
    maxSchritte () {
      return  this.width > 800 ? 12 : this.width > 500 ? 8 : 5
    },
    teiler () {
      return this.mTeiler.filter(aTeiler => this.diagrammData.max / aTeiler <= this.maxSchritte)[0]
    },
    einteilungen () {
      return this.maxVal / this.teiler > 0 ? this.maxVal / this.teiler : 1
    },
    minVal () {
      return Math.floor(this.diagrammData.min / this.teiler) * this.teiler
    },
    maxVal () {
      return Math.ceil(this.diagrammData.max / this.teiler) * this.teiler - this.minVal
    },
    lines () {
      return this.diagrammData.data.length
    },
    dWidth () {
      let dW = this.width - this.dLeft - 20
      return dW > 150 ? dW : 150
    },
    dHeight () {
      return this.lSpacer + this.width * 0.5
    },
    top () {
      return 50
    },
    height () {
      // console.log(this.rowsData, this.rowsData.height, this.top + this.dHeight + ((this.fontSize + 5) * this.diagrammData.schnitt.length) + 30 + (this.rowsData ? this.rowsData.height : 0))
      return this.diagrammData ? (this.top + this.dHeight + ((this.fontSize + 5) * (this.diagrammData ? this.diagrammData.schnitt.length : 0)) + 35 + (this.rowsData ? this.rowsData.height : 0)) : 200
    }
  },
  watch: {
    diagrammData () {
      // console.log('diagrammData (w)', this.diagrammData)
      this.updateRowsData()
      this.rowsDataTrys = 0
      this.hiddenCols = {}
      this.refresh = true
    },
    update (nVal) {
      if (nVal) {
        this.refresh = true
      }
    },
    active (nVal) {
      if (nVal) {
        this.$nextTick(() => {
          this.refresh = true
          this.rowsDataTrys = 0
        })
      }
    },
    refresh (nVal) {
      if (nVal) {
        this.$nextTick(() => {
          this.refresh = false
          this.resize()
        })
      }
    }
  },
  components: {
    SvgText
  }
}
</script>

<style scoped>
.svgContainer {
  position: relative;
}
.ld-data > g > line {
  pointer-events: none;
}
.diagramm-tooltip {
  position: fixed;
  top: 10px;
  left: 200px;
  width: 33vw;
  pointer-events: none;
  color: #666;
  font-size: 1.5rem;
  z-index: 10;
}
.diagramm-tooltip a, .diagramm-tooltip span {
  font-size: 1.5rem;
}
.diagramm-tooltip h3 {
  font-size: 1.8rem;
  color: #666;
  margin-bottom: 0.5rem;
}
.diagramm-tooltip > div {
  position: absolute;
  left: 5px;
  bottom: 5px;
  background: #fff;
  box-shadow: 2px 2px 6px 2px rgba(0,0,0,0.3);
  padding: 4px 8px;
  max-width: 33vw;
}
.diagramm-tooltip-value.selected {
  font-weight: bold;
}
.legende > g {
  cursor: pointer;
}
.legende > g:hover > text {
  cursor: pointer;
  text-decoration: underline;
}
.legende > g.unselected > g >>> text {
  text-decoration: line-through;
}
.legende > g.unselected > rect {
  opacity: 0.33;
}
@media (max-width: 700px) {
  .svgContainer {
    overflow: auto;
  }
}
</style>