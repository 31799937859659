<template>
  <div ref="svgContainer" class="svgContainer" :style="'min-height:' + height + 'px;'">
    <svg ref="svg" :width="width" :height="height" v-if="active && !refresh && diagrammData" :viewBox="'0 0 ' + width + ' ' + height">
      <defs>
        <pattern :id="'valpat' + pKey" patternUnits="userSpaceOnUse" :patternTransform="pKey%2 == 0 ? 'scale(1, -1)' : null" :width="13" :height="13" v-for="(pObj, pKey) in pattern" :key="'valpat' + pKey">
          <template v-if="blackWhite">
            <line :x1="-13 / 2" :y1="13 / 2" :x2="13 / 2" :y2="13 * 1.5" :style="'stroke:black; stroke-width:' + 1" />
            <line x1="0" y1="0" :x2="13" :y2="13" :style="'stroke:black; stroke-width:' + 1" />
            <line :x1="13 / 2" :y1="-13 / 2" :x2="13 * 1.5" :y2="13 / 2" :style="'stroke:black; stroke-width:' + 1" />
          </template>
          <rect x="-1" y="-1" width="15" height="15" :fill="pObj" v-else />
        </pattern>
      </defs>
      <SvgText
        :x="dLeft + dWidth / 2"
        :y="top - fontSize + 3" :fill="getCssRGB(textFarbe)" :font-family="fontFamily" :font-size="fontSize + 1" center
        :text="excelJSON.optionen['einheitenart']"
        v-if="excelJSON.optionen['einheitenart']"
      />
      <g class="ld-data" v-for="(row, lKey) in diagrammData.rows" :key="'gl' + lKey">
        <g v-for="(vVal, vKey) in diagrammData.valsData[row]" :key="'v' + vKey">
          <path
            :d="svgArc(dLeft + dWidth / 2, top + dHeight / 2, radius.outer, radius.inner, vVal.winkel.left, vVal.winkel.right)"
            :fill="'url(#valpat' + vKey + ')'"
            :stroke="blackWhite ? '#000' : null"
            :stroke-width="1"
            @mouseover="hoverPath(lKey, vKey, true)"
            @mouseleave="hoverPath(lKey, vKey, false)"
          />
          <line
            :x1="dLeft + dWidth / 2 + vVal.text.vLine.x" :y1="top + dHeight / 2 + vVal.text.vLine.y"
            :x2="dLeft + dWidth / 2 + vVal.text.bLine.x" :y2="top + dHeight / 2 + vVal.text.bLine.y"
            stroke="#000" :stroke-width="1"
          />
          <line
            :x1="dLeft + dWidth / 2 + vVal.text.bLine.x" :y1="top + dHeight / 2 + vVal.text.bLine.y"
            :x2="dLeft + dWidth / 2 + vVal.text.bLine.x + (vVal.winkel.center > 180 ? -30 : 30)" :y2="top + dHeight / 2 + vVal.text.bLine.y"
            stroke="#000" :stroke-width="1"
          />
          <SvgText
            :x="dLeft + dWidth / 2 + vVal.text.bLine.x + (vVal.winkel.center > 180 ? -3 : 3)"
            :y="top + dHeight / 2 + vVal.text.bLine.y - 3" :fill="getCssRGB(textFarbe)" :font-family="fontFamily" :font-size="fontSize + 1" :right="vVal.winkel.center > 180"
            :text="vVal.winkel.center <= 180 ? localString(vVal.vals.val) + ' - ' + vVal.row : vVal.row + ' - ' + localString(vVal.vals.val)"
            :multiLineWidth="(vVal.winkel.center > 180 ? (dLeft + dWidth / 2 + vVal.text.bLine.x) : ((dLeft + dWidth) - (dLeft + dWidth / 2 + vVal.text.bLine.x)))"
          />
        </g>
      </g>
    </svg>
    <div v-else>
      Daten nicht mit Ansicht kompatibel!
    </div>
    <div ref="tooltip" class="diagramm-tooltip" :style="'left:' + mX + 'px;top:' + mY + 'px;'" v-if="hCircle.l !== null && hCircle.v !== null">
      <div>
        <h3>{{ diagrammData.valsData[diagrammData.rows[hCircle.l]][hCircle.v].titel }}</h3>
        <div>
          <span class="diagramm-tooltip-value selected">{{ diagrammData.rows[hCircle.l] }}:</span>
          {{ localString(diagrammData.valsData[diagrammData.rows[hCircle.l]][hCircle.v].vals.val) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgText from '../Svg/SvgText';

export default {
  name: 'Kreisdiagramm',
  props: {
    daten: Object,
    excelJSON: Object,
    jahr: [Number, String],
    fontSize: { type: Number, default: 14 },
    lSpacer: { type: Number, default: 5 },
    fontFamily: { type: String, default: 'Source Sans Pro, sans-serif' },
    secColor: { type: String, default: '#ccc' },
    schnittColor: { type: String, default: '#444' },
    farbenSet: { type: Number, default: 0 },
    basisFarbe: { type: Object, default: function () { return { r: 207, g: 190, b: 185 } } },
    farbe: { type: Object, default: function () { return { r: 94, g: 72, b: 65 } } },
    textFarbe: { type: Object, default: function () { return { r: 0, g: 0, b: 0 } } },
    blackWhite: { type: Boolean, default: false },
    blackWhiteFill: { type: Boolean, default: false },
    sortierung: { type: String, default: null},
    update: { type: Boolean, default: false},
    active: { type: Boolean, default: true},
    exportieren: { type: Boolean, default: false}
  },
  data: () => ({
    width: 100,
    refresh: true,
    hCircle: { l: null, v: null },
    mX: 200,
    mY: 10
  }),
  mounted () {
    this.$root.$el.addEventListener('resize', this.resize)
    this.$root.$el.addEventListener('mousemove', this.mouseMove)
    this.resize()
    this.$nextTick(() => {
      this.refresh = false
      this.$emit('isOk', 'kreis', (this.diagrammData) ? true : false)
    })
    // console.log('diagrammData', this.diagrammData)
  },
  beforeDestroy () {
    this.$root.$el.removeEventListener('mousemove', this.mouseMove)
    this.$root.$el.removeEventListener('resize', this.resize)
  },
  methods: {
    hoverPath (lKey, vKey, stat) {
      this.hCircle = stat ? { l: lKey, v: vKey } : { l: null, v: null }
    },
    mouseMove (e) {
      this.mX = e.clientX
      this.mY = e.clientY
      if (this.$refs.tooltip && this.$refs.tooltip.children && this.$refs.tooltip.children[0]) {
        if (this.mY - this.$refs.tooltip.children[0].clientHeight < 10) {
          this.mY = this.$refs.tooltip.children[0].clientHeight + 10
        }
        let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        if (vw > 0 && this.mX + this.$refs.tooltip.children[0].clientWidth > vw - 10) {
          this.mX = vw - this.$refs.tooltip.children[0].clientWidth - 10
        }
      }
    },
    svgArcP2C (cX, cY, r, angDeg) {
      var angRad = (angDeg - 90) * Math.PI / 180.0
      return { x: cX + (r * Math.cos(angRad)), y: cY + (r * Math.sin(angRad)) }
    },
    svgArc (x, y, rad, rad2, sA, eA, addRad=false) {
      if (addRad) {
        eA += sA < 0 ? 0.1 : sA
      }
      let s1 = this.svgArcP2C(x, y, rad, eA)
      let e1 = this.svgArcP2C(x, y, rad, sA)
      let s2 = this.svgArcP2C(x, y, rad2, sA)
      let e2 = this.svgArcP2C(x, y, rad2, eA - 0.5)
      let lAf = eA - sA <= 180 ? '0' : '1'
      return [
          'M', s1.x, s1.y,
          'A', rad, rad, 0, lAf, 0,
          e1.x, e1.y,
          'L', s2.x, s2.y,
          'A', rad2, rad2, 0, lAf, 1,
          e2.x, e2.y,
          'z'
      ].join(' ')
    },
    getCssRGB (rgb) {
      return 'rgb(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ')'
    },
    setMax (dData) {
      dData.max = 0
      dData.data.forEach(row => {
        let sum = 0
        row.vals.forEach(val => { sum += val.val || 0 })
        dData.max += sum
      })
      return dData
    },
    setValsData (dData) {
      dData.rows.forEach(aRow => {
        dData.valsData[aRow] = []
      })
      let lVals = null
      let valLeft = 0
      let bLineLast = null
      dData.data.forEach(aData => {
        for (let i = 0; i < aData.vals.length; i++) {
          if (lVals) {
            valLeft += lVals.val
          }
          let mDg = 0
          let wLeft = 360 / dData.max * valLeft
          let wWidth = 360 / dData.max * aData.vals[i].val - 1
          if (wWidth <= 0) {
            wWidth = 0
          }
          let bLine = this.svgArcP2C(0, 0, this.radius.text, wLeft + wWidth / 2)
          if (bLineLast && (bLineLast.x >= 0 && bLine.x >= 0 || bLineLast.x < 0 && bLine.x < 0)) {
            let bLineDir = bLine.x >= 0 && bLine.y <= 0 || bLine.x < 0 && bLine.y > 0 ? -1 : 1
            let bLineDirX = bLine.x >= 0 && bLine.y <= 0 || bLine.x > 0 && bLine.y > 0 ? 1 : -1
            while(mDg < 25 && ((bLineDirX > 0 ? bLine.y - bLineLast.y : bLineLast.y - bLine.y) < this.fontSize * 1.1)) {
              bLine = this.svgArcP2C(0, 0, this.radius.text + mDg * 2 * bLineDir, wLeft + wWidth / 2)
              mDg += 1
            }
            // console.log(aData.titel, bLineLast.y, bLine.y, (bLineDirX > 0 ? Math.abs(bLine.y - bLineLast.y) : Math.abs(bLineLast.y - bLine.y)))
          }
          dData.valsData[aData.vals[i].name].push({
            row: aData.titel,
            vals: aData.vals[i],
            valLeft: valLeft,
            winkel: {
              left: wLeft,
              width: wWidth,
              right: wLeft + wWidth,
              center: wLeft + wWidth / 2
            },
            text: {
              vLine: this.svgArcP2C(0, 0, this.radius.outer * 1.05, wLeft + wWidth / 2),
              bLine: bLine
            },
            titel: aData.titel
          })
          lVals = aData.vals[i]
          bLineLast = bLine
        }
      })
      return dData
    },
    resize () {
      this.updateWidth()
    },
    updateWidth () {
      this.width = 400
      this.$nextTick(() => {
        this.width = this.$refs.svgContainer ? this.$refs.svgContainer.clientWidth : 400
        if (this.width < 600) {
          this.width = 600
        }
      })
    },
    localString (val, dType = 'main') {
      let fVal = val
      if (typeof val === 'undefined' && this.excelJSON.optionen['zahlen formatierung'] && this.excelJSON.optionen['zahlen formatierung'].indexOf('float') > -1) {
        fVal = 0
      }
      if (typeof fVal === 'number') {
        if ((dType === 'main' || dType === 'schnitt') && this.excelJSON.optionen['zahlen formatierung'] && this.excelJSON.optionen['zahlen formatierung'].indexOf('float') > -1) {
          let aDigits = parseInt(this.excelJSON.optionen['zahlen formatierung'].substr(5))
          fVal = fVal.toLocaleString(undefined, {minimumFractionDigits: aDigits, maximumFractionDigits: aDigits})
        } else {
          fVal = fVal.toLocaleString()
        }
      }
      let eOption = 'einheitenart kurz'
      if (dType === 'schnitt') {
        eOption = 'einheitenart kurz schnitt'
      }
      if (dType === 'summe') {
        eOption = 'einheitenart kurz summen'
      }
      if (fVal && this.excelJSON.optionen[eOption]) {
        if (!((typeof val === 'string') && this.excelJSON.optionen[eOption] === '%')) {
          fVal += ' ' + this.excelJSON.optionen[eOption]
        }
      }
      return fVal ? fVal : ''
    },
    SpaltenBeiJahr (row) {
      if (this.excelJSON.jahr && this.excelJSON.jahr.length > 1 && !(this.jahr === 'Mehrere Jahre' && this.excelJSON.optionen['mehrere jahre'])) {
        return [row.obj[this.jahr]]
      }
      return row.list
    },
    sortList(sortierung, list) {
      if (sortierung) {
        if (sortierung === '!zeilenbeschriftung') {
          return list.slice().sort((a, b) => {
            let aV = this.daten.mainData.functions.regionen.getByVal(a.name, this.excelJSON.optionen['zeilenbeschriftung']).name
            let bV = this.daten.mainData.functions.regionen.getByVal(b.name, this.excelJSON.optionen['zeilenbeschriftung']).name
            return aV > bV ? 1 : aV < bV ? -1 : 0
          })
        } else if (sortierung === '!wert') {
          return list.slice().sort((a, b) => {
            let aV = this.SpaltenBeiJahr(a.spalten)[0].val
            let bV = this.SpaltenBeiJahr(b.spalten)[0].val
            return aV > bV ? 1 : aV < bV ? -1 : 0
          })
        } else {
          return list.slice().sort((a, b) => {
            let aV = a.spalten.obj[sortierung].val
            let bV = b.spalten.obj[sortierung].val
            return aV > bV ? 1 : aV < bV ? -1 : 0
          })
        }
      } else {
        return list
      }
    }
  },
  computed: {
    sortedList () {
      return this.sortList(this.sortierung, this.excelJSON.daten.mainData.list)
    },
    sortedSchnitt () {
      return this.sortList(this.sortierung, this.excelJSON.daten.mainData.schnitt.list)
    },
    sortedSumme () {
      return this.sortList(this.sortierung, this.excelJSON.daten.mainData.summe.list)
    },
    diagrammData () {
      let dData = null
      // console.log(this.excelJSON.jahr, this.excelJSON.jahr.length, this.excelJSON.optionen['datenart'], this.jahr)
      if (this.excelJSON.jahr && this.excelJSON.jahr.length > 0 || (this.jahr === 'Mehrere Jahre' && this.excelJSON.optionen['mehrere jahre'])) {
        dData = { data: [], schnitt: [], max: -Infinity, multipleData: false, rows: [], rowsLen: 1, valsData: {} }
        this.sortedList.forEach(row => {
          if (this.jahr && this.excelJSON.jahr.length > 1 && !(this.jahr === 'Mehrere Jahre' && this.excelJSON.optionen['mehrere jahre'])) {
            dData.data.push({ titel: this.daten.mainData.functions.regionen.getByVal(row.name, this.excelJSON.optionen['zeilenbeschriftung']).name, vals: [row.spalten.obj[this.jahr]] })
          } else {
            dData.data.push({ titel: this.daten.mainData.functions.regionen.getByVal(row.name, this.excelJSON.optionen['zeilenbeschriftung']).name, vals: row.spalten.list })
          }
          row.spalten.list.forEach(spalte => {
            if (dData.rows.indexOf(spalte.name) < 0) {
              dData.rows.push(spalte.name)
            }
          })
          if (row.spalten.list.length > dData.rowsLen) {
            dData.rowsLen = row.spalten.list.length
          }
        })
        dData.multipleData = true
        dData.schnitt.sort((a, b) => a.vals[0].val < b.vals[0].val ? -1 : (a.vals[0].val > b.vals[0].val ? 1 : 0))
        dData = this.setMax(dData)
        dData = this.setValsData(dData)
      }
      return dData
    },
    pattern () {
      let aPat = []
      if (this.diagrammData.multipleData) {
        // farbenSet
        let useFarbensets = this.daten.mainData.basis.farbensets
        let cycleFarbenSet = this.farbenSet || 0
        if (this.excelJSON.optionen.farben && this.excelJSON.optionen.farben.length > 0) {
          useFarbensets = this.excelJSON.optionen.farben.map(f => this.daten.mainData.basis.farbensets[f] || this.daten.mainData.basis.farbensets[0])
          cycleFarbenSet = 0
          // console.log('Farben', this.excelJSON.optionen.farben, useFarbensets)
        }
        for (let i = 0; i < this.diagrammData.data.length; i++) {
          aPat.push(useFarbensets[cycleFarbenSet].a)
          cycleFarbenSet += 1
          if (cycleFarbenSet >= useFarbensets.length) {
            cycleFarbenSet = 0
          }
        }
      } else {
        aPat.push(this.daten.mainData.basis.farbensets[this.farbenSet || 0].a)
      }
      return aPat
    },
    dLeft () {
      return 50
    },
    dWidth () {
      let dW = this.width - this.dLeft - 20
      return dW > 150 ? dW : 150
    },
    dHeight () {
      return this.lSpacer + this.width * 0.5
    },
    radius () {
      return {outer: this.dHeight * 0.32, inner: this.dHeight * 0.18, middle: this.dHeight * 0.275, text: this.dHeight * 0.45}
    },
    top () {
      return 50
    },
    height () {
      return this.diagrammData ? (this.top + this.dHeight + ((this.fontSize + 5) * this.diagrammData.schnitt.length) + 35) : 200
    }
  },
  watch: {
    update (nVal) {
      if (nVal) {
        this.refresh = true
      }
    },
    active (nVal) {
      if (nVal) {
        this.$nextTick(() => {
          this.refresh = true
        })
      }
    },
    refresh (nVal) {
      if (nVal) {
        this.$nextTick(() => {
          this.refresh = false
          this.resize()
        })
      }
    }
  },
  components: {
    SvgText
  }
}
</script>

<style scoped>
.svgContainer {
  position: relative;
}
.ld-data > g > line {
  pointer-events: none;
}
.diagramm-tooltip {
  position: fixed;
  top: 10px;
  left: 200px;
  width: 33vw;
  pointer-events: none;
  color: #666;
  font-size: 1.5rem;
  z-index: 10;
}
.diagramm-tooltip a, .diagramm-tooltip span {
  font-size: 1.5rem;
}
.diagramm-tooltip h3 {
  font-size: 1.8rem;
  color: #666;
  margin-bottom: 0.5rem;
}
.diagramm-tooltip > div {
  position: absolute;
  left: 5px;
  bottom: 5px;
  background: #fff;
  box-shadow: 2px 2px 6px 2px rgba(0,0,0,0.3);
  padding: 4px 8px;
  max-width: 33vw;
}
.diagramm-tooltip-value.selected {
  font-weight: bold;
}
@media (max-width: 700px) {
  .svgContainer {
    overflow: auto;
  }
}
</style>