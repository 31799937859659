<template>
  <g :transform="'translate(' + aX + ',' + y + ')'" v-if="show && arText.length > 0 && !refresh">
    <template v-if="shadow">
      <template
        v-for="(aText, aLine) in arText"
      >
        <text :x="-shadowFac" :y="-(fontSize * (arText.length - 1)) + (fontSize * aLine) + -shadowFac" :fill="shadow" :transform="texttransform" :font-family="fontFamily" :font-size="fontSize" :font-weight="fontWeight" :text-decoration="textDecoration" :key="'tls' + aLine">{{ aText }}</text>
        <text :x="shadowFac" :y="-(fontSize * (arText.length - 1)) + (fontSize * aLine) + shadowFac" :fill="shadow" :transform="texttransform" :font-family="fontFamily" :font-size="fontSize" :font-weight="fontWeight" :text-decoration="textDecoration" :key="'tls2' + aLine">{{ aText }}</text>
        <text :x="shadowFac" :y="-(fontSize * (arText.length - 1)) + (fontSize * aLine) + -shadowFac" :fill="shadow" :transform="texttransform" :font-family="fontFamily" :font-size="fontSize" :font-weight="fontWeight" :text-decoration="textDecoration" :key="'tls3' + aLine">{{ aText }}</text>
        <text :x="-shadowFac" :y="-(fontSize * (arText.length - 1)) + (fontSize * aLine) + shadowFac" :fill="shadow" :transform="texttransform" :font-family="fontFamily" :font-size="fontSize" :font-weight="fontWeight" :text-decoration="textDecoration" :key="'tls4' + aLine">{{ aText }}</text>
        <text :x="0" :y="-(fontSize * (arText.length - 1)) + (fontSize * aLine) + -shadowFac" :fill="shadow" :transform="texttransform" :font-family="fontFamily" :font-size="fontSize" :font-weight="fontWeight" :text-decoration="textDecoration" :key="'tls5' + aLine">{{ aText }}</text>
        <text :x="0" :y="-(fontSize * (arText.length - 1)) + (fontSize * aLine) + shadowFac" :fill="shadow" :transform="texttransform" :font-family="fontFamily" :font-size="fontSize" :font-weight="fontWeight" :text-decoration="textDecoration" :key="'tls6' + aLine">{{ aText }}</text>
        <text :x="shadowFac" :y="-(fontSize * (arText.length - 1)) + (fontSize * aLine) + 0" :fill="shadow" :transform="texttransform" :font-family="fontFamily" :font-size="fontSize" :font-weight="fontWeight" :text-decoration="textDecoration" :key="'tls7' + aLine">{{ aText }}</text>
        <text :x="-shadowFac" :y="-(fontSize * (arText.length - 1)) + (fontSize * aLine) + 0" :fill="shadow" :transform="texttransform" :font-family="fontFamily" :font-size="fontSize" :font-weight="fontWeight" :text-decoration="textDecoration" :key="'tls8' + aLine">{{ aText }}</text>
      </template>
    </template>
    <text :x="arWidth[aLine]" :y="-(fontSize * (arText.length - 1)) + (fontSize * aLine)" ref="txt" :fill="fill" :transform="texttransform" :font-family="fontFamily" :font-size="fontSize" :font-weight="fontWeight" :text-decoration="textDecoration"
      v-for="(aText, aLine) in arText"
      :key="'tl' + aLine"
    >{{ aText }}</text>
    <!-- <rect
      :x="0" :y="0 - fontSize"
      :width="width + 1" :height="fontSize"
      stroke="#fdd"
      fill="transparent"
      :transform="texttransform"
    /> -->
  </g>
</template>

<script>
export default {
  name: 'SvgText',
  props: {
    x: { type: [Number, String], default: 0 },
    y: { type: [Number, String], default: 0 },
    center: null,
    right: null,
    fill: null,
    fontFamily: { type: String, default: "Arial, Helvetica, sans-serif" },
    fontSize: { type: Number, default: 12 },
    fontWeight: { type: String, default: 'normal' },
    textDecoration: { type: String, default: null },
    text: null,
    texttransform: null,
    shadow: null,
    shadowFac: { type: Number, default: 1 },
    maxWidth: { type: Number, default: null },
    maxRight: {type: Number, default: null },
    multiLineWidth: {type: Number, default: null }
  },
  data: () => ({
    arText: [],
    arWidth: [],
    width: 0,
    show: true,
    refresh: false
  }),
  mounted () {
    this.arText = [this.text]
    this.updateWidth()
  },
  methods: {
    updateWidth (tryAgain=true) {
      this.show = true
      this.$nextTick(() => {
        this.width = this.$refs.txt ? this.$refs.txt[0].getBBox().width : 0
        this.arWidth = [this.width]
        if (this.text && this.multiLineWidth > 0 && this.width > this.multiLineWidth) {
          var dg = 0
          var sTxt = this.text.split(' ')
          var mWidth = 0
          this.arText = []
          this.arWidth = []
          while (dg < 10 && sTxt.length > 0) {
            this.$refs.txt[0].innerHTML = sTxt[0]
            let xdg = 1
            while (this.$refs.txt[0].getBBox().width < this.multiLineWidth && xdg < 50) {
              this.$refs.txt[0].innerHTML = sTxt.slice(0, xdg).join(' ')
              xdg += 1
            }
            if (xdg > 1) {
              xdg -= 2
            }
            let xText = sTxt.splice(0, xdg).join(' ')
            this.arText.push(xText)
            this.$refs.txt[0].innerHTML = xText
            let aWidth = this.$refs.txt[0].getBBox().width
            this.arWidth.push(aWidth)
            if (mWidth < aWidth) {
              mWidth = aWidth
            }
            dg += 1
          }
          this.width = mWidth
          this.refresh = true
        }
        for (var i = 0; i < this.arWidth.length; i++) {
          this.arWidth[i] = this.centerIt ? ((this.width / 2) - (this.arWidth[i] / 2) ) : this.rightIt ? (this.width - this.arWidth[i]) : 0
        }
        this.show = !this.maxWidth || this.maxWidth >= this.width
        if (tryAgain && this.width === 0 && this.text) {
          this.updateWidth(false)
        }
      })
    }
  },
  computed: {
    centerIt () {
      return this.center || typeof this.center === 'string'
    },
    rightIt () {
      return this.right || typeof this.right === 'string'
    },
    aX () {
      let x = this.centerIt ? this.x - this.width / 2 : this.rightIt ? this.x - this.width : this.x
      if (this.maxRight && this.maxRight < x + this.width + 3) {
        x = this.maxRight - this.width - 3
      }
      return x
    }
  },
  watch: {
    text () {
      this.arText = [this.text]
      this.updateWidth()
    },
    fontSize () {
      this.updateWidth()
    },
    fontFamily () {
      this.updateWidth()
    },
    maxWidth () {
      this.updateWidth()
    },
    refresh (nVal) {
      if (nVal) {
        this.$nextTick(() => {
          this.refresh = false
        })
      }
    }
  }
}
</script>

<style scoped>
</style>