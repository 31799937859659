<template>
  <div :style="'min-height:' + height + 'px;'">
    <div ref="svgContainer" class="svgContainer" :style="'min-height:' + height + 'px;'">
      <template v-if="active && !refresh && !refresh2 && mapData && (diagrammData || koordinatenDaten)">
        <svg ref="svg" class="svg" :width="width" :height="height" :viewBox="'0 0 ' + width + ' ' + height">
          <defs>
            <pattern :id="pObj.id" patternUnits="userSpaceOnUse" :width="pObj.size" :height="pObj.size" :patternTransform="pObj.mirror ? 'scale(' + exportScale[0] + ',' + -exportScale[1] + ')' : 'scale(' + exportScale[0] + ',' + exportScale[1] + ')'"
              v-for="(pObj) in patterns" :key="pObj.id"
            >
              <rect x="-1" y="-1" :width="pObj.size + 2" :height="pObj.size + 2" :fill="pObj.color" v-if="pObj.color" />
              <template v-if="pObj.width >= 0">
                <line :x1="-pObj.size / 2" :y1="pObj.size / 2" :x2="pObj.size / 2" :y2="pObj.size * 1.5" :style="'stroke:black; stroke-width:' + pObj.width" />
                <line x1="0" y1="0" :x2="pObj.size" :y2="pObj.size" :style="'stroke:black; stroke-width:' + pObj.width" />
                <line :x1="pObj.size / 2" :y1="-pObj.size / 2" :x2="pObj.size * 1.5" :y2="pObj.size / 2" :style="'stroke:black; stroke-width:' + pObj.width" />
              </template>
            </pattern>
            <pattern :id="'overlappat' + olKey" patternUnits="userSpaceOnUse" :width="13" :height="13" :patternTransform="'scale(' + exportScale[0] + ',' + exportScale[1] + ')'"
              v-for="(olObj, olKey) in mapData.overlap" :key="'olpat' + olKey"
            >
              <rect x="-1" y="-1" width="15" height="15" :fill="getFill(mapData.byId[olObj.olIds[0]])" />
              <rect :x="6.5 - 15" y="11" width="30" height="5" :fill="getFill(mapData.byId[olObj.olIds[1]])" transform="rotate(45 15 2)" stroke="#fff" />
              <rect :x="6.5 - 15 + 13" y="2" width="30" height="5" :fill="getFill(mapData.byId[olObj.olIds[1]])" transform="rotate(45 15 2)"  stroke="#fff"/>
            </pattern>
            <linearGradient id="kkpat" x1="0" x2="0" y1="0" y2="1" v-if="!klasseneinteilungData && diagrammData">
              <stop offset="0%" :stop-color="getFill(mapData.byId[diagrammData.maxObj])" />
              <stop offset="100%" :stop-color="getFill(mapData.byId[diagrammData.minObj])" />
            </linearGradient>
          </defs>
          <g ref="zoomArea">
            <g :id="'karte-' + excelJSON.optionen['zeilenbeschriftung'].toLowerCase()" :transform="'matrix(' + mapScale + ',0,0,-' + mapScale + ',' + mapLeft + ',' + mapTop + ')'" class="karte">
              <!-- Hintergrund -->
              <g class="bg"
                v-if="mapData.background && mapData.background.poly"
              >
                <path
                  :d="aPoly.path"
                  :transform="aPoly.transform"
                  fill="url(#bgpat)" :stroke="blackWhite ? '#999' : null"
                  :stroke-width="1 / mapScale"
                  v-for="(aPoly, bgKey) in mapData.background.poly" :key="'bgp' + bgKey"
                />
              </g>
              <!-- Bereiche -->
              <g class="mloc"
                @mouseover="hoverObj(mapData.byId[mId], true)"
                @mouseleave="hoverObj(mapData.byId[mId], false)"
                v-for="mId in mapList" :key="'mLoc' + mId"
              >
                <path :d="aPoly.path"
                  :transform="aPoly.transform"
                  :data-xxx="mId"
                  :fill="getFill(mapData.byId[mId])"
                  :stroke="getStroke()" :stroke-width="0.5 / mapScale"
                  :class="(mdFx[mapData.byId[mId].id] && mdFx[mapData.byId[mId].id].hover) ? ' hover' : ''"
                  v-for="(aPoly, pKey) in mapData.byId[mId].poly" :key="'bgp' + pKey"
                />
              </g>
              <!-- Überlappungen -->
              <template v-if="mapData.overlap && diagrammData">
                <g class="overlap"
                  @mouseover="hoverOlObj(olObj, true)"
                  @mouseleave="hoverOlObj(olObj, false)"
                  v-for="(olObj, olKey) in mapData.overlap" :key="'ol' + olKey"
                >
                  <path
                    :d="aPoly.path"
                    :transform="aPoly.transform"
                    :fill="blackWhite ? 'rgba(0,0,0,0.3)' : 'url(#overlappat' + olKey + ')'"
                    :stroke="blackWhite ? '#888' : (olObj === olHover ? '#fff' : 'transparent')" v-for="(aPoly, pKey) in olObj.poly" :key="'olp' + pKey"
                  />
                </g>
              </template>
              <!-- Beschriftung -->
              <g class="mloct"
                v-for="mId in mapList" :key="'mSk' + mId"
              >
                <SvgText
                  :x="mapData.byId[mId].x - 3" :y="mapData.byId[mId].y"
                  :fill="blackWhite || !diagrammData ? '#000' : '#fff'" :shadow="diagrammData ? blackWhite ? '#fff' : '#000' : null" :shadowFac="1 * mSize / mapScale"
                  :font-family="fontFamily" :font-size="fontSize * 1.1 / mapScale" center
                  :font-weight="diagrammData && (diagrammData.maxObj == mId || diagrammData.minObj == mId) ? 'bold' : null"
                  texttransform="scale(1 -1)"
                  :text="(mapData.byId[mId].short ? mapData.byId[mId].short : mapData.byId[mId].name)"
                />
                <!-- Sonderkarte -->
                <g :transform="'translate(' + (mapData.byId[mId].x - 5 / mapScale) + ', ' + (mapData.byId[mId].y - 5 * mSize / mapScale) + ')'" v-if="diagrammData && diagrammData.rowsLen > 1">
                  <template v-if="(diagrammData.byId[mId] ? diagrammData.byId[mId].sVals : []).length > 0">
                    <SvgText
                      :x="-1 * mSize / mapScale" :y="-(fontSize * 1.05 / mapScale / ((zoomFac + 2) / 3)) * 0.75 * (aKey + 1) - aKey - 1"
                      :fill="blackWhite ? '#000' : getOrderedFarbset(aVal.key)" :shadow="blackWhite ? '#fff' : '#fff'" :font-family="fontFamily" font-weight="bold" right
                      :font-size="fontSize * 1.1 / mapScale / ((zoomFac + 2) / 3)"
                      texttransform="scale(1 -1)"
                      :text="aVal.val"
                      :shadowFac="1 * mSize / mapScale"
                      v-for="(aVal, aKey) in (diagrammData.byId[mId] ? valG0(diagrammData.byId[mId].vals) : [])" :key="'fxd' + aKey"
                    />
                    <SvgText
                      :x="5 * mSize / mapScale" :y="-(fontSize / mapScale / ((zoomFac + 2) / 3)) * 1.7 * (sKey + 1) - sKey + 5"
                      fill="#000" shadow="#fff" :font-family="fontFamily"
                      :font-size="fontSize * 1.7 / mapScale / ((zoomFac + 2) / 3)" left
                      texttransform="scale(1 -1)"
                      :text="sVal.val"
                      :shadowFac="1 * mSize / mapScale"
                      v-for="(sVal, sKey) in (diagrammData.byId[mId] ? diagrammData.byId[mId].sVals : [])" :key="'fxds' + sKey"
                    />
                  </template>
                  <template v-else>
                    <template v-if="skCircle">
                      <g v-for="(aVal, aKey) in (diagrammData.byId[mId] ? valG0(diagrammData.byId[mId].vals) : [])" :key="'fxd' + aKey">
                        <g
                          :transform="'translate(' + (-(circleTypes(aVal.val).length / 2 * 8) + (xKey * 8) + 3) / mapScale / ((zoomFac + 2) / 3) + ',' + (-(7 / mapScale) * (aKey + 1) - aKey + 2) / ((zoomFac + 2) / 3) + ')'"
                          v-for="(xVal, xKey) in circleTypes(aVal.val)" :key="'fxd' + aKey + 'x' + xKey"
                        >
                          <circle cx="0" cy="0"
                            :r="(4 * mSize / mapScale) / ((zoomFac + 2) / 3)"
                            :fill="blackWhite ? '#000' : getOrderedFarbset(aVal.key)" :shadow="blackWhite ? '#fff' : '#fff'"
                            class="npevent"
                            v-if="xVal === 'c'"
                          />
                          <rect :x="-(2.5 * mSize / mapScale) / ((zoomFac + 2) / 3)" :y="-(2.5 * mSize / mapScale) / ((zoomFac + 2) / 3)" :width="(5 * mSize / mapScale) / ((zoomFac + 2) / 3)" :height="(5 * mSize / mapScale) / ((zoomFac + 2) / 3)"
                            :stroke="blackWhite ? '#000' : getOrderedFarbset(aVal.key)" :shadow="blackWhite ? '#fff' : '#fff'"
                            :stroke-width="1.8 * mSize / mapScale / ((zoomFac + 2) / 3)"
                            fill="#fff"
                            class="npevent"
                            v-else-if="xVal === 'q'"
                          />
                        </g>
                      </g>
                    </template>
                    <template v-else>
                      <g v-for="(aVal, aKey) in (sonderkarteTextFx[mId] ? sonderkarteTextFx[mId] : [])" :key="'fxd' + aKey">
                        <SvgText
                          :x="aVal.x" :y="aVal.y"
                          :fill="blackWhite ? '#000' : getOrderedFarbset(aVal.key)" :shadow="blackWhite ? '#fff' : '#fff'" :font-family="fontFamily" font-weight="bold" center
                          :font-size="fontSize * 1.1 / mapScale"
                          texttransform="scale(1 -1)"
                          :text="aVal.val"
                          :shadowFac="1 * mSize / mapScale"
                        />
                      </g>
                    </template>
                  </template>
                </g>
              </g>
              <!-- Koordinatendaten -->
              <template v-if="koordinatenDaten">
                <g class="koordinaten" :transform="'translate(' + (mapData.data.pxLeft + ((mapData.data.pxRight - mapData.data.pxLeft) / (mapData.data.lonRight - mapData.data.lonLeft) * (kdVal.x - mapData.data.lonLeft))) + ', ' + (mapData.data.pxTop + ((mapData.data.pxBottom - mapData.data.pxTop) / (mapData.data.latBottom - mapData.data.latTop) * (kdVal.y - mapData.data.latTop))) + ')'"
                  v-for="(kdVal, kdKey) in koordinatenDaten.list.filter(l => !hiddenRows[l.typKey])"
                  :key="'kd' + kdKey"
                  @mouseover="hoverKObj(kdKey, true)"
                  @mouseleave="hoverKObj(kdKey, false)"
                  @click="openLink(kHover.valsObj['Homepage'] && kHover.valsObj['Homepage'].val ? kHover.valsObj['Homepage'].val : null)"
                >
                  <circle
                    cx="0"
                    cy="0"
                    :r="((blackWhite ? 7 : 5) * mSize / mapScale) / zoomFac"
                    :fill="blackWhite ? '#fff' : (kdVal.valsObj['Typ'] && koordinatenDaten.types.obj[kdVal.valsObj['Typ'].val] ? koordinatenDaten.types.obj[kdVal.valsObj['Typ'].val].color : '#f00')"
                    :stroke="blackWhite ? '#000': null"
                    :stroke-width="0.8 * mSize / mapScale"
                    :class="kHover === kdVal ? ' hover' : ''"
                  />
                  <SvgText
                    :x="0" :y="-4.5 * mSize / mapScale"
                    fill="#000" :font-family="fontFamily" :font-size="fontSize * 0.8" center
                    texttransform="scale(1 -1)"
                    :text="koordinatenDaten.types.obj[kdVal.valsObj['Typ'].val].key + 1"
                    class="npevent"
                    v-if="blackWhite"
                  />
                </g>
              </template>
            </g>
          </g>
          <template v-if="koordinatenDaten">
            <g class="koordinaten-infos"
              :transform="'translate(' + (5 * mSize) + ', ' + (5 * mSize) + ')'"
              v-if="koordinatenDaten.types.list.length > 0"
            >
              <g
                :transform="'translate(0, ' + (ktKey * 22 * mSize) + ')'"
                v-for="(ktVal, ktKey) in koordinatenDaten.types.list" :key="'ki' + ktKey"
                :class="hiddenRows[ktKey] ? 'unselected' : ''"
                @click="toggleRow(ktKey, koordinatenDaten.types.list.length, $event)"
              >
                <circle
                  :cx="5 * mSize" :cy="(8 * mSize)"
                  :r="(blackWhite ? 8 : 7) * mSize"
                  :stroke="blackWhite ? '#000' : null" :fill="blackWhite ? '#fff' : (koordinatenDaten.types.obj[ktVal] ? koordinatenDaten.types.obj[ktVal].color : '#f00')"
                />
                <SvgText
                  :x="5" :y="(13 * mSize)"
                  fill="#000" :font-family="fontFamily" :font-size="fontSize * 1.1" center
                  :text="koordinatenDaten.types.obj[ktVal].key + 1"
                  class="npevent"
                  v-if="blackWhite"
                />
                <text :x="20 * mSize" :y="13 * mSize" :font-size="14 * mSize" :font-family="fontFamily" :fill="textFarbeRGB" v-if="koordinatenDaten.types.obj[ktVal]">
                  {{ koordinatenDaten.types.obj[ktVal].titel }}
                </text>
              </g>
            </g>
          </template>
          <template v-if="diagrammData">
            <template v-if="diagrammData.rowsLen > 1">
              <g class="spezial-infos"
                :transform="'translate(' + (5 * mSize) + ', ' + (5 * mSize) + ')'"
              >
                <g
                  :transform="'translate(0, ' + (kiKey * 22 * mSize) + ')'"
                  v-for="(kiVal, kiKey) in diagrammData.rows" :key="'ki' + kiKey"
                  :class="(typeof kiVal === 'string' && kiVal.indexOf('gesamt') > -1 ? 'sum' : hiddenRows[kiKey] ? 'unselected' : '')"
                  @click="toggleRow(kiKey, diagrammData.rows.length, $event)"
                >
                  <circle
                    :cx="5 * mSize" :cy="(8 * mSize)"
                    :r="(typeof kiVal === 'string' && kiVal.indexOf('gesamt') > -1 ? 9 : 6) * mSize"
                    :stroke="blackWhite || kiVal.indexOf('gesamt') > -1 ? '#000' : null"
                    :fill="blackWhite || kiVal.indexOf('gesamt') > -1 ? '#000' : getOrderedFarbset(kiKey)"
                  />
                  <text :x="20 * mSize" :y="13 * mSize" :font-size="14 * mSize" :font-family="fontFamily" :fill="textFarbeRGB">
                    {{ kiVal }}
                  </text>
                </g>
                <g :transform="'translate(0, ' + ((diagrammData.rows.length * 22 + 5) * mSize) + ')'" class="si-ai" v-if="diagrammData.hasRect">
                  <circle
                    :cx="5 * mSize" :cy="(8 * mSize)"
                    r="6"
                    :stroke="blackWhite ? '#000' : null"
                    fill="#000"
                  />
                  <rect :x="0" :y="(3 * mSize)" :width="10 * mSize" :height="10 * mSize"
                    stroke="#000" :shadow="blackWhite ? '#fff' : '#fff'"
                    :stroke-width="1.8 * mSize"
                    fill="#fff"
                  />
                  <text :x="20 * mSize" :y="13 * mSize" :font-size="14 * mSize" :font-family="fontFamily" :fill="textFarbeRGB">
                    Entspricht 10 Einheiten
                  </text>
                </g>
              </g>
            </template>
            <template v-else>
              <!-- Klasseneinteilung -->
              <g id="klasseneinteilung" class="klasseneinteilung"
                :transform="'translate(' + (5 * mSize) + ', ' + (5 * mSize) + ')'"
                v-if="klasseneinteilungData && diagrammData"
              >
                <g
                  :transform="'translate(0, ' + (kKey * 22 * mSize) + ')'"
                  v-for="(row, kKey) in klasseneinteilungData.data" :key="'ke' + kKey"
                >
                  <rect
                    x="0" :y="mapData.data.patternMirror ? 0 : -(16 * mSize / mapScale)"
                    :width="(16 * mSize / mapScale)" :height="(16 * mSize / mapScale)"
                    :stroke="blackWhite ? '#000' : null" :fill="getFill(kKey)"
                    :stroke-width="0.5 / mapScale"
                    :transform="'matrix(' + mapScale + ',0,0,' + (mapScale * (mapData.data.patternMirror ? 1 : -1)) + ',0,0)'"
                  />
                  <text :x="30 * mSize" :y="13 * mSize" :font-size="14 * mSize" :font-family="fontFamily" :fill="textFarbeRGB">
                    {{ row.klasse.operator !== '>=' ? row.klasse.operator : '' }}
                    {{ row.klasse.von ? row.klasse.von.toLocaleString(undefined, row.klasse.float) : 'err' }} - {{ row.klasse.bis ? row.klasse.bis.toLocaleString(undefined, row.klasse.float) : 'err' }}
                    {{ excelJSON.optionen['einheitenart kurz'] && !isSumme(excelJSON.daten.mainData.spalten[spalte]) ? excelJSON.optionen['einheitenart kurz'] : '' }}
                  </text>
                </g>
              </g>
              <!-- Ohne Klasseneintelung -->
              <g id="keineklasseneinteilung" class="keineklasseneinteilung"
                :transform="'translate(' + (5 * mSize) + ', ' + (5 * mSize) + ')'"
                v-else
              >
                <rect
                  x="0" :y="-(40 * mSize / mapScale)"
                  :width="(16 * mSize / mapScale)" :height="(40 * mSize / mapScale)"
                  :stroke="blackWhite ? '#000' : null" :fill="blackWhite ? '#fff' : 'url(#kkpat)'"
                  :stroke-width="0.5 / mapScale"
                  :transform="'matrix(' + mapScale + ',0,0,-' + mapScale + ',0,0)'"
                />
                  <text :x="30 * mSize" :y="13 * mSize" :font-size="14 * mSize" :font-family="fontFamily">
                    {{ diagrammData.min.toLocaleString() }}
                    {{ excelJSON.optionen['einheitenart kurz'] && !isSumme(diagrammData.minSpaltenObj.name) ? excelJSON.optionen['einheitenart kurz'] : '' }}
                  </text>
                  <text :x="30 * mSize" :y="37 * mSize" :font-size="14 * mSize" :font-family="fontFamily">
                    {{ diagrammData.max.toLocaleString() }}
                    {{ excelJSON.optionen['einheitenart kurz'] && !isSumme(diagrammData.maxSpaltenObj.name) ? excelJSON.optionen['einheitenart kurz'] : '' }}
                  </text>
              </g>
            </template>
            <!-- Schnitt und Summe -->
            <g class="infos" :transform="'translate(' + width + ', ' + (5 * mSize) + ')'" v-if="diagrammData.infos.length > 0">
              <template v-if="diagrammData.rowsLen > 1">
                <rect
                  :x="-5 * mSize" :y="0"
                  :width="5 * mSize"
                  :height="8 + (1 + fontSize * 1.3 + (6 + fontSize * 1.3) * (1 + diagrammData.infos.length * (diagrammData.rowsLen + diagrammData.sRowsLen)) + 10 * (diagrammData.infos.length - 1)) * mSize"
                  :fill="farbeARGB"
                />
                <g :transform="'translate(0, ' + ((1 + fontSize * 1.3 + (6 + fontSize * 1.3) * (iKey * (diagrammData.rowsLen + diagrammData.sRowsLen + 1)) + 10 * iKey) * mSize) + ')'"
                  v-for="(iVal, iKey) in diagrammData.infos"
                  :key="'it' + iKey"
                >
                  <SvgText
                    :x="-20 * mSize" :y="0"
                    :fill="'#000'" :font-family="fontFamily" :font-size="fontSize * 1.3 * 0.9" font-weight="bold" right
                    :text="iVal.titel"
                  />
                  <SvgText
                    :x="-20 * mSize" :y="(8 + fontSize * 1.3 + (6 + fontSize * 1.3) * sKey) * mSize"
                    :fill="'#000'" :font-family="fontFamily" :font-size="fontSize * 1.3 * 0.9" right
                    :font-weight="sVal && sVal.style === 'bold' ? 'bold' : null"
                    :text="sVal ? sVal.name + ': ' + localString(sVal.val) : ''"
                    v-for="(sVal, sKey) in [...iVal.vals, ...iVal.sVals]"
                    :key="'it' + iKey + 'st' + sKey"
                  />
                </g>
              </template>
              <template v-else>
                <rect
                  :x="-5 * mSize" :y="0"
                  :width="5 * mSize"
                  :height="8 + (1 + fontSize * 1.3 + (6 + fontSize * 1.3) * (diagrammData.infos.length - 1)) * mSize"
                  :fill="farbeARGB"
                />
                <SvgText
                  :x="-20 * mSize" :y="(1 + fontSize * 1.3 + (6 + fontSize * 1.3) * iKey) * mSize"
                  :fill="'#000'" :font-family="fontFamily" :font-size="fontSize * 1.3 * 0.9" right
                  :text="iVal.titel + ': ' + localString((diagrammData.hasSumCols ? iVal.sVals[0].val : (iVal && iVal.vals && iVal.vals[0] ? iVal.vals[0].val : '')), iVal.typ)"
                  :font-weight="iVal && iVal.style === 'bold' ? 'bold' : null"
                  v-for="(iVal, iKey) in diagrammData.infos"
                  :key="'it' + iKey"
                />
              </template>
            </g>
            <!-- Mindest-/Maximalwerte -->
            <g class="infos" :transform="'translate(' + width + ', ' + (height - (5 + fontSize * 1.3 + (6 + fontSize * 1.3) * 1 + 4 + fontSize * 1.3 + 20) * mSize) + ')'" v-if="diagrammData && diagrammData.infos.length > 0">
              <rect
                :x="-5 * mSize" :y="(-fontSize * 1.3 * 0.9 - 5) * mSize"
                :width="5 * mSize"
                :height="(5 + fontSize * 1.3 + ((6 + fontSize * 1.3 )* 2) * 1 + 4 + fontSize * 1.3 + 5) * mSize"
                :fill="farbeARGB"
              />
              <SvgText
                :x="-20 * mSize" :y="0"
                :fill="'#000'" :font-family="fontFamily" :font-size="fontSize * 1.3 * 0.9" font-weight="bold" right
                text="Niedrigster Wert"
              />
              <SvgText
                :x="-20 * mSize" :y="(4 + fontSize * 1.3) * mSize"
                :fill="'#000'" :font-family="fontFamily" :font-size="fontSize * 1.3 * 0.9" right
                :text="diagrammData.byId[diagrammData.minObj].sVals && diagrammData.byId[diagrammData.minObj].sVals[0] ? diagrammData.byId[diagrammData.minObj].titel + ': ' + localString(diagrammData.byId[diagrammData.minObj].sVals[0].val) : (diagrammData.byId[diagrammData.minObj].vals[0] ? diagrammData.byId[diagrammData.minObj].titel + ': ' + localString(diagrammData.byId[diagrammData.minObj].vals[0].val) : '')"
              />
              <SvgText
                :x="-20 * mSize" :y="(5 + fontSize * 1.3 + (6 + fontSize * 1.3) * 1) * mSize"
                :fill="'#000'" :font-family="fontFamily" :font-size="fontSize * 1.3 * 0.9" font-weight="bold" right
                text="Höchster Wert"
              />
              <SvgText
                :x="-20 * mSize" :y="(5 + fontSize * 1.3 + (6 + fontSize * 1.3) * 1 + 4 + fontSize * 1.3) * mSize"
                :fill="'#000'" :font-family="fontFamily" :font-size="fontSize * 1.3 * 0.9" right
                :text="diagrammData.byId[diagrammData.maxObj].sVals && diagrammData.byId[diagrammData.maxObj].sVals[0] ? diagrammData.byId[diagrammData.maxObj].titel + ': ' + localString(diagrammData.byId[diagrammData.maxObj].sVals[0].val) : (diagrammData.byId[diagrammData.maxObj].vals[0] ? diagrammData.byId[diagrammData.maxObj].titel + ': ' + localString(diagrammData.byId[diagrammData.maxObj].vals[0].val) : '')"
              />
            </g>
          </template>
          <!-- Quellenangabe für Karte -->
          <g v-if="mapData.quelle">
            <text :x="5 * mSize" :y="height - 5 * mSize" :font-size="12 * mSize" :fill="textFarbeRGB" :font-family="fontFamily">Kartenquelle: {{ mapData.quelle }}</text>
          </g>
          <!-- Zoom controll -->
          <g v-if="zoomMode && zoomFunc && !exportieren">
            <g @mousedown.stop.prevent="zoomFunc.resetZoom(); zoomFunc.resetPan();" class="cur-point" :transform="'matrix(' + mSize + ', 0, 0, ' + mSize + ', ' + (5 * mSize) + ', ' + (height - 45 * mSize) + ')'">
              <rect x="0" y="0" width="24" height="24" fill="#ddd" />
              <path fill="#333" d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z" />
            </g>
            <g @mousedown.stop.prevent="zoomFunc.zoomBy(1.2);" class="cur-point" :transform="'matrix(' + mSize + ', 0, 0, ' + mSize + ', ' + (35 * mSize) + ', ' + (height - 45 * mSize) + ')'">
              <rect x="0" y="0" width="24" height="24" fill="#ddd" />
              <path fill="#333" d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z" />
            </g>
            <g @mousedown.stop.prevent="zoomFunc.zoomBy(0.8);" class="cur-point" :transform="'matrix(' + mSize + ', 0, 0, ' + mSize + ', ' + (65 * mSize) + ', ' + (height - 45 * mSize) + ')'">
              <rect x="0" y="0" width="24" height="24" fill="#ddd" />
              <path fill="#333" d="M19,10H5V14H19V14Z" />
            </g>
          </g>
        </svg>
        <!-- Tooltip für Diagrammdaten -->
        <div ref="tooltip" class="karte-tooltip" :style="'left:' + mX + 'px;top:' + mY + 'px;'" v-if="diagrammData && mdHover && diagrammData.byId[mdHover.id]">
          <div>
            <h3>{{ mdHover.name }}</h3>
            <div v-for="(aVal, vKey) in diagrammData.byId[mdHover.id].vals" :key="'ktv' + vKey" :class="'karte-tooltip-value' + (spalte === vKey ? ' selected' : '')">
              <template v-if="diagrammData.rowsLen < 1 || aVal.val">
                <span v-if="datenart === 'Artenaufstellung'">{{ aVal.name }}:</span>
                {{ aVal.val === undefined || aVal.val === null ? 'Keine Daten vorhanden' : localString(aVal.val) }}
              </template>
              <template v-else-if="(aVal.val === undefined || aVal.val === null) && diagrammData.byId[mdHover.id].vals.length === 1">
                Keine Daten vorhanden
              </template>
            </div>
            <div
              v-for="(svVal, svKey) in diagrammData.byId[mdHover.id].sVals"
              :key="'kottsv' + svKey"
            >
              <b>{{ svVal.name }}:</b> {{ svVal.val }}
            </div>
          </div>
        </div>
        <!-- Tooltip für Überlappungen -->
        <div ref="tooltip" class="karte-tooltip" :style="'left:' + mX + 'px;top:' + mY + 'px;'" v-if="diagrammData && olHover && olHover.olIds">
          <div>
            Datenüberlappung von<br>
            <span v-html="'<b>' + olHover.olIds.map(mId => (mapData.byId[mId] ? mapData.byId[mId].name : mId)).join('</b><br>und<br><b>') + '</b>'" />
          </div>
        </div>
        <!-- Tooltip für Koordinaten -->
        <div ref="tooltip" class="karte-tooltip koordinaten-tooltip" :style="'left:' + mX + 'px;top:' + mY + 'px;'" v-if="koordinatenDaten && kHover">
          <div>
            <h3 :style="'color:' + (kHover.valsObj['Typ'] && koordinatenDaten.types.obj[kHover.valsObj['Typ'].val] ? koordinatenDaten.types.obj[kHover.valsObj['Typ'].val].color : '#f00') + ';'">
              {{ kHover.valsObj['Hochschule'] ? kHover.valsObj['Hochschule'].val : kHover.titel }}
            </h3>
            <div
              v-for="(vVal, vKey) in kHoverVals"
              :key="'kottv' + vKey"
            >
              <b>{{ vVal.name }}:</b> {{ vVal.val }}
            </div>
            <div class="kt-url" v-if="kHover.valsObj['Homepage'] && kHover.valsObj['Homepage'].val"><b>Homepage:</b> <a :href="kHover.valsObj['Homepage'].val" target="_blank">{{ kHover.valsObj['Homepage'].val }}</a></div>
          </div>
        </div>
      </template>
      <div v-else>
        Daten nicht mit Ansicht kompatibel!
      </div>
    </div>
  </div>
</template>

<script>
import SvgText from '../Svg/SvgText';
const svgPanZoom = require('svg-pan-zoom')

export default {
  name: 'Karte',
  props: {
    daten: Object,
    excelJSON: Object,
    jahr: [Number, String],
    fontSize: { type: Number, default: 14 },
    fontFamily: { type: String, default: 'Source Sans Pro, sans-serif' },
    farbenSet: { type: Number, default: 0 },
    basisFarbe: { type: Object, default: function () { return { r: 207, g: 190, b: 185 } } },
    farbe: { type: Object, default: function () { return { r: 94, g: 72, b: 65 } } },
    textFarbe: { type: Object, default: function () { return { r: 0, g: 0, b: 0 } } },
    blackWhite: { type: Boolean, default: false },
    blackWhiteFill: { type: Boolean, default: false },
    update: { type: Boolean, default: false},
    klasseneinteilung: { type: Boolean, default: true},
    active: { type: Boolean, default: true},
    spalte: { type: Number, default: 0 },
    exportieren: { type: Boolean, default: false},
  },
  data: () => ({
    width: 500,
    refresh: true,
    refresh2: false,
    mdFx: {},
    mdHover: null,
    olHover: null,
    kHover: null,
    mX: 200,
    mY: 10,
    zoomFunc: null,
    zoomFacN: 1,
    hiddenRows: {}
  }),
  mounted () {
    // console.log('spalte', this.spalte)
    // console.log('daten', this.daten)
    // console.log('excelJSON', this.excelJSON)
    // console.log('mapData', this.mapData)
    console.log('diagrammData', this.diagrammData)
    // console.log('patterns', this.patterns)
    // console.log('klasseneinteilungData', this.klasseneinteilungData)
    this.$root.$el.addEventListener('resize', this.resize)
    this.$root.$el.addEventListener('mousemove', this.mouseMove)
    this.$nextTick(() => {
      this.refresh = false
      this.resize()
      this.updateDdFx()
      this.$emit('isOk', 'karte', (this.mapData && (this.diagrammData || this.koordinatenDaten)) ? true : false)
    })
  },
  beforeDestroy () {
    this.$root.$el.removeEventListener('mousemove', this.mouseMove)
    this.$root.$el.removeEventListener('resize', this.resize)
  },
  methods: {
    toggleRow (k, l, e) {
      if (e.ctrlKey) {
        let showAll = !this.hiddenRows[k] && Object.keys(this.hiddenRows).filter(h => this.hiddenRows[h]).length > l - 2
        for (let i = 0; i < l; i++) {
          this.$set(this.hiddenRows, i, !(showAll || k === i))
        }
      } else {
        this.$set(this.hiddenRows, k, !this.hiddenRows[k])
      }
    },
    circleTypes (s) {
      let c = []
      let d = s
      while (d >= 10) {
        d -= 10
        c.push('q')
        this.$set(this.diagrammData, 'hasRect', true)
      }
      for (let i=0;i<d;i++) {
        c.push('c')
      }
      return c
    },
    openLink (url) {
      if (url) {
        window.open(url, '_blank')
      }
    },
    getFill (lObj) {
      if (this.koordinatenDaten || (this.diagrammData && this.diagrammData.rowsLen > 1)) {
        return this.blackWhite ? '#fff' : '#eee'
      } else if (this.diagrammData) {
        if (typeof lObj === 'object') {
          let aData = this.diagrammData.byId[lObj.id]
          if (aData && aData.vals && aData.vals[this.spalte]) {
            if (aData.vals[this.spalte].val === undefined || aData.vals[this.spalte].val === null) {
              return this.blackWhite ? '#fff' : '#ddd'
            } else {
              if (this.klasseneinteilungData && this.klasseneinteilungData.data) {
                let aIdx = this.klasseneinteilungData.data.filter((aObj) => {
                  return (aData.vals[this.spalte].val > aObj.klasse.von || (aData.vals[this.spalte].val >= aObj.klasse.von && aObj.klasse.operator === '>=')) && aData.vals[this.spalte].val <= aObj.klasse.bis
                })[0]
                return 'url(#devpat' + (aIdx && aIdx.idx || 0) + ')'
              } else {
                if (this.blackWhite) {
                  if (this.patterns[aData.vals[this.spalte].val]) {
                    return 'url(#' + this.patterns[aData.vals[this.spalte].val].id + ')'
                  }
                  return '#fff'
                } else {
                  if (aData) {
                    let dF = (this.diagrammData.hasSumCols ? aData.sVals[0].val : aData.vals[this.spalte].val) - this.diagrammData.min
                    return 'rgb(' +
                        parseInt(this.basisFarbe.r + ((this.farbe.r - this.basisFarbe.r) / (this.diagrammData.max - this.diagrammData.min)) * dF) + ',' +
                        parseInt(this.basisFarbe.g + ((this.farbe.g - this.basisFarbe.g) / (this.diagrammData.max - this.diagrammData.min)) * dF) + ',' +
                        parseInt(this.basisFarbe.b + (this.farbe.b - this.basisFarbe.b) / (this.diagrammData.max - this.diagrammData.min) * dF) +
                      ')'
                  }
                }
              }
            }
          } else {
            return '#eee'
          }
        } else if (typeof lObj === 'number') {
          if (this.klasseneinteilungData) {
            if (this.klasseneinteilungData.data) {
              return 'url(#devpat' + lObj + ')'
            }
          }
        }
        return '#f00'
      } else {
        return this.blackWhite ? '#fff' : '#ddd'
      }
    },
    getStroke () {
      if (this.blackWhite) {
        return this.blackWhiteFill ? '#666' : '#000'
      }
      return '#fff'
    },
    resize () {
      this.updateWidth()
    },
    updateWidth () {
      this.width = 100
      this.$nextTick(() => {
        this.width = this.$refs.svgContainer ? this.$refs.svgContainer.clientWidth : 500
        if (this.width < 500) {
          this.width = 500
        }
        this.refresh2 = true
      })
    },
    setMinMax (dData) {
      // console.log('dData', dData)
      dData.data.forEach(row => {
        let aObj = dData.hasSumCols ? row.sVals[0] : row.vals[this.spalte]
        let sum = aObj.val
        // console.log(aObj, sum, dData.hasSumCols, row.sVals)
        if (sum < dData.min) {
          dData.min = sum
          dData.minObj = this.getMapLocationByName(row.titel)
          dData.minSpaltenObj = aObj.val
        }
        if (sum > dData.max) {
          dData.max = sum
          dData.maxObj = this.getMapLocationByName(row.titel)
          dData.maxSpaltenObj = aObj.val
        }
      })
      return dData
    },
    getMapLocationByName (name) {
      let rId = 1
      if (this.mapData && this.mapData.byId) {
        Object.keys(this.mapData.byId).forEach(aId => {
          if (this.mapData.byId[aId].name === name) {
            rId = aId
          }
        })
      }
      return rId
    },
    valG0 (vals) {
      let k = -1
      return vals.map(v => { k +=1; return { name: v.name, val: v.val, key: k } }).filter(v => v.val > 0 && !this.hiddenRows[v.key])
    },
    updateDdFx () {
      this.mdFx = {}
      if (this.mapData && this.mapData.byId) {
        Object.keys(this.mapData.byId).forEach(aKey => {
          let aObj = this.mapData.byId[aKey]
          this.$set(this.mdFx, aObj.id, { mObj: aObj, hover: false })
        })
      }
      // console.log('mdFx', this.mdFx)
    },
    hoverObj (aObj, hover) {
      if (this.mdFx[aObj.id]) {
        this.mdFx[aObj.id].hover = hover
      }
      this.mdHover = hover ? aObj : null
    },
    hoverOlObj (aObj, hover) {
      this.olHover = hover ? aObj : null
    },
    hoverKObj (aKey, hover) {
      this.kHover = hover ? this.koordinatenDaten.list.filter(l => !this.hiddenRows[l.typKey])[aKey] : null
    },
    mouseMove (e) {
      this.mX = e.clientX
      this.mY = e.clientY
      if (this.$refs.tooltip && this.$refs.tooltip.children && this.$refs.tooltip.children[0]) {
        if (this.mY - this.$refs.tooltip.children[0].clientHeight < 10) {
          this.mY = this.$refs.tooltip.children[0].clientHeight + 10
        }
        let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        if (vw > 0 && this.mX + this.$refs.tooltip.children[0].clientWidth > vw - 10) {
          this.mX = vw - this.$refs.tooltip.children[0].clientWidth - 10
        }
      }
    },
    isSumme (aTxt) {
      return typeof aTxt === 'string' && (aTxt.toLowerCase().indexOf('%') < 0) && (aTxt.toLowerCase().indexOf('absolut') > -1 || aTxt.toLowerCase().indexOf('summe') > -1 || aTxt.toLowerCase().indexOf('gesamt') > -1)
    },
    localString (val, dType = 'main') {
      let fVal = val
      if (typeof val === 'undefined' && this.excelJSON.optionen['zahlen formatierung'] && this.excelJSON.optionen['zahlen formatierung'].indexOf('float') > -1) {
        fVal = 0
      }
      if (typeof fVal === 'number') {
        if ((dType === 'main' || dType === 'schnitt') && this.excelJSON.optionen['zahlen formatierung'] && this.excelJSON.optionen['zahlen formatierung'].indexOf('float') > -1) {
          let aDigits = parseInt(this.excelJSON.optionen['zahlen formatierung'].substr(5))
          fVal = fVal.toLocaleString(undefined, {minimumFractionDigits: aDigits, maximumFractionDigits: aDigits})
        } else {
          fVal = fVal.toLocaleString()
        }
      }
      let eOption = 'einheitenart kurz'
      if (dType === 'schnitt') {
        eOption = 'einheitenart kurz schnitt'
      }
      if (dType === 'summe') {
        eOption = 'einheitenart kurz summen'
      }
      if (fVal && this.excelJSON.optionen[eOption]) {
        if (!((typeof val === 'string') && this.excelJSON.optionen[eOption] === '%')) {
          fVal += ' ' + this.excelJSON.optionen[eOption]
        }
      }
      return fVal ? fVal : ''
    },
    getOrderedFarbset (key) {
      if (this.orderedFarbsets) {
        while(key >= this.orderedFarbsets.length) {
          key -= this.orderedFarbsets.length
        }
        return this.orderedFarbsets[key]
      }
      return '#f00'
    }
  },
  computed: {
    zoomFac () {
      return this.zoomMode && this.zoomFunc ? this.zoomFacN || 1 : 1
    },
    zoomMode () {
      return this.koordinatenDaten || this.diagrammData.rowsLen > 1
    },
    height () {
      return this.mapData ? this.mWidth * this.mapData.data.heightFac : 200
    },
    mWidth () {
      return this.width > 650 ? 650 : this.width
    },
    mSize () {
      return this.mWidth / 650
    },
    mapData () {
      if (this.excelJSON.optionen['zeilenbeschriftung'] === 'FrmRegionen') {
        return this.daten.mainData.functions.regionen.FrmRegionen
      } else if (this.excelJSON.optionen['zeilenbeschriftung'] === 'Metropolregionen') {
        return this.daten.mainData.functions.regionen.Metropolregionen
      } else {
        return null
      }
    },
    mapList () {
      let mList = []
      Object.keys(this.mapData.byId).forEach(aId => {
        if (!(this.mdHover && this.mdHover.id === parseInt(aId))) {
          mList.push(this.mapData.byId[aId].id)
        }
      })
      if(this.mdHover && this.mapData.byId[this.mdHover.id]) {
        mList.push(this.mapData.byId[this.mdHover.id].id)
      }
      // console.log('mapList', mList, this.mdHover)
      return mList
    },
    mapLeft () {
      return this.mapData.data.left
    },
    mapTop () {
      return 100 / this.mSize + this.mapData.data.top * this.mapScale
    },
    mapScale () {
      return this.mWidth / this.mapData.data.width * this.mSize
    },
    exportScale () {
      return this.exportieren ? [2.961 / this.mapScale, 2.961 / this.mapScale * (this.mapData.data.patternMirror ? -1 : 1)] : [1 / this.mapScale, 1 / this.mapScale * (this.mapData.data.patternMirror ? -1 : 1)]
    },
    koordinatenDaten () {
      if (this.datenart === 'Adressen mit Koordinaten') {
        let kData = { list: [], types: { list: [], obj: {} } }
        this.excelJSON.daten.mainData.list.forEach(row => {
          let aIdObj = this.daten.mainData.functions.regionen.getByVal(row.name, this.excelJSON.optionen['zeilenbeschriftung'])
          kData.list.push({ titel: aIdObj.name, name: row.name, vals: row.spalten.list, x: row.spalten.obj['KOX'].val, y: row.spalten.obj['KOY'].val, valsObj: row.spalten.obj, typ: row.spalten.obj['Typ'].val, typKey: 0 })
          if (row.spalten.obj['Typ'] && kData.types.list.indexOf(row.spalten.obj['Typ'].val) < 0) {
            kData.types.list.push(row.spalten.obj['Typ'].val)
            kData.types.obj[row.spalten.obj['Typ'].val] = { titel: row.spalten.obj['Typ'].val, key: 0, color: '#f00' }
          }
        })
        kData.types.list.sort()
        let dg = 0
        kData.types.list.forEach(t => {
          kData.types.obj[t].key = dg
          kData.types.obj[t].color = this.getOrderedFarbset(dg)
          dg += 1
        })
        kData.list.forEach(k => {
          k.typKey = kData.types.obj[k.typ].key
        })
        let getDistance = (k1, k2) => {
            let a = k1.x - k2.x
            let b = k1.y - k2.y
            return Math.sqrt(a * a + b * b)
        }
        let getNearest = (k) => {
          let nearest = kData.list.filter(ka => k !== ka).sort((a, b) => {
            return getDistance(k, a) - getDistance(k, b)
          })
          return nearest[0]
        }
        let cK = true
        let ckDg = 0
        while (cK && ckDg < 100) {
          cK = false
          kData.list.forEach(k => {
            let aNearest = getNearest(k)
            let aDist = getDistance(k, aNearest)
            if (aDist < 0.01) {
              k.x = k.x + (k.x - aNearest.x) / 10 || 0.001
              k.y = k.y + (k.y - aNearest.y) / 10 || 0
              // console.log(aDist, aNearest)
              cK = true
            }
          })
          ckDg += 1
        }
        // console.log('cK', ckDg)
        // console.log('koordinatenDaten', kData)
        return kData
      }
      return null
    },
    datenart () {
      return this.jahr === 'Mehrere Jahre' && this.excelJSON.optionen['mehrere jahre'] ? 'Artenaufstellung' :  this.excelJSON.optionen['datenart']
    },
    diagrammData () {
      if (this.datenart !== 'Adressen mit Koordinaten' && this.jahr && this.excelJSON.jahr && this.excelJSON.jahr.length > 0) {
        let dData = { data: [], byName: {}, byId: {}, schnitt: [], summe: [], infos: [], min: Infinity, max: -Infinity, minObj: null, maxObj: null, rows: [], rowsLen: 1, hasSumCols: null }
        this.excelJSON.daten.mainData.list.forEach(row => {
          let aIdObj = this.daten.mainData.functions.regionen.getByVal(row.name, this.excelJSON.optionen['zeilenbeschriftung'])
          let aData = null
          if (this.datenart === 'Artenaufstellung') {
            let aVals = row.spalten.list.filter(s => !(typeof s.name === 'string' && s.name.toLowerCase().indexOf('gesamt') > -1))
            let sVals = row.spalten.list.filter(s => (typeof s.name === 'string' && s.name.toLowerCase().indexOf('gesamt') > -1))
            if (typeof row.name === 'string' && (row.name.toLowerCase().indexOf('wissensregion') > -1 || row.name.toLowerCase().indexOf('kreisfrei') > -1 || row.name.toLowerCase().indexOf('landkreise') > -1)) {
              dData.infos.push({ titel: row.name, name: row.name, vals: aVals, sVals: sVals, typ: 'schnitt', style: row.style })
            } else if (typeof row.name === 'string' && (row.name.toLowerCase() === 'metropolregionen' || row.name.toLowerCase() === 'deutschland')) {
              dData.infos.push({ titel: row.name, name: row.name, vals: aVals, sVals: sVals, typ: 'summe', style: row.style })
            } else {
              if (sVals.length > 0) {
                dData.hasSumCols = true
              }
              aData = { titel: aIdObj.name, name: row.name, vals: aVals, sVals: sVals }
            }
            row.spalten.list.forEach(spalte => {
              if (dData.rows.indexOf(spalte.name) < 0) {
                dData.rows.push(spalte.name)
              }
            })
          } else {
            if (typeof row.name === 'string' && (row.name.toLowerCase().indexOf('wissensregion') > -1 || row.name.toLowerCase().indexOf('kreisfrei') > -1 || row.name.toLowerCase().indexOf('landkreise') > -1)) {
              dData.infos.push({ titel: aIdObj.name, name: row.name, vals: [row.spalten.obj[this.jahr]], typ: 'schnitt', style: row.style })
            } else {
              aData = { titel: aIdObj.name, name: row.name, vals: [row.spalten.obj[this.jahr]] }
            }
          }
          if (aData) {
            if (aData.vals.length > dData.rowsLen) {
              dData.rowsLen = aData.vals.length
              dData.sRowsLen = aData.sVals.length
            }
            dData.data.push(aData)
          }
          dData.byName[row.name] = aData
          if (aIdObj.obj) {
            dData.byId[aIdObj.obj.id] = aData
          }
        })
        this.excelJSON.daten.mainData.schnitt.list.forEach(row => {
          if (this.datenart === 'Artenaufstellung') {
            dData.schnitt.push({ titel: row.name, name: row.name, vals: row.spalten.list, style: row.style })
            dData.infos.push({ titel: row.name, name: row.name, vals: row.spalten.list, typ: 'schnitt', style: row.style })
          } else {
            dData.schnitt.push({ titel: row.name, name: row.name, vals: [row.spalten.obj[this.jahr]] })
            if (row.spalten.obj[this.jahr] && row.spalten.obj[this.jahr].val !== undefined) {
              dData.infos.push({ titel: row.name, name: row.name, vals: [row.spalten.obj[this.jahr]], typ: 'schnitt', style: row.style })
            }
          }
        })
        this.excelJSON.daten.mainData.summe.list.forEach(row => {
          if (this.datenart === 'Artenaufstellung') {
            dData.summe.push({ titel: row.name, name: row.name, vals: row.spalten.list, style: row.style })
            dData.infos.push({ titel: row.name, name: row.name, vals: row.spalten.list, typ: 'summe', style: row.style })
          } else {
            dData.summe.push({ titel: row.name, name: row.name, vals: [row.spalten.obj[this.jahr]], style: row.style })
            dData.infos.push({ titel: row.name, name: row.name, vals: [row.spalten.obj[this.jahr]], typ: 'summe', style: row.style })
          }
        })
        dData.schnitt.sort((a, b) => a.vals[this.spalte].val < b.vals[this.spalte].val ? -1 : (a.vals[this.spalte].val > b.vals[this.spalte].val ? 1 : 0))
        dData = this.setMinMax(dData)
        return dData
      }
      return null
    },
    klasseneinteilungData () {
      if (this.klasseneinteilung && this.excelJSON.jahr && (this.excelJSON.jahr.length > 1 || (this.excelJSON.optionen['datenart'] === 'Jahresaufstellung' && this.excelJSON.jahr.length > 0)) &&  this.excelJSON.daten['Klasseneinteilung'] && !(this.jahr === 'Mehrere Jahre' && this.excelJSON.optionen['mehrere jahre'])) {
        let dData = { data: [], byName: {} }
        let dg = 0
        this.excelJSON.daten['Klasseneinteilung'].list.forEach(row => {
          let aData = { titel: row.name, name: row.name, vals: [row.spalten.obj[this.jahr]], klasse: {}, idx: dg }
          let aMatch = aData.vals[this.spalte] && aData.vals[this.spalte].val ? aData.vals[this.spalte].val.trim().match(/^(>)*([0-9,.]+)[\s-]+([0-9,.]+)/) : null
          if (aMatch) {
            aData.klasse.operator = aMatch[1] === '>' ? '>' : '>='
            aData.klasse.float = (aMatch[2] + aMatch[3]).indexOf(',') > -1 || (aMatch[2] + aMatch[3]).indexOf('.') > -1 ? {minimumFractionDigits: 1, maximumFractionDigits: 1} : {}
            // console.log(aMatch[2] + aMatch[3], aData.klasse.float)
            aData.klasse.von = parseFloat(aMatch[2].replace('.', '').replace(',', '.'))
            aData.klasse.bis = parseFloat(aMatch[3].replace('.', '').replace(',', '.'))
            dData.data.push(aData)
            dData.byName[row.name] = aData
            dg += 1
          }
        })
        return dData
      }
      return null
    },
    patterns () {
      let patternMaker = (dg, val, min, max, fill) => {
        return {
          id: 'devpat' + dg,
          val: val,
          size: fill ?
            10
            : 24 - 20  / (max - min) * (val - min),
          width: fill ?
            1 + 7 / (max - min) * (val - min)
            : 1,
          mirror: dg%2 == 0
        }
      }
      let aPatterns = {
        bgpat: {
          id: 'bgpat',
          val: 'bgpat',
          size: 10,
          width: -1,
          color: this.blackWhite ? '#fff' : '#ddd'
        }
      }
      if (this.diagrammData) {
        let dg = 0
        if (this.klasseneinteilung && this.klasseneinteilungData && this.klasseneinteilungData.data) {
          let aL = this.klasseneinteilungData.data.length - 1 
          if (this.blackWhite) {
            this.klasseneinteilungData.data.forEach(aData => {
              aPatterns[aData.idx] = patternMaker(aData.idx, aData.idx, 0, aL, this.blackWhiteFill)
            })
          } else {
            this.klasseneinteilungData.data.forEach(aData => {
              aPatterns[aData.idx] = {
                id: 'devpat' + aData.idx,
                size: 10,
                width: -1,
                color: 'rgb(' +
                    parseInt(this.basisFarbe.r + ((this.farbe.r - this.basisFarbe.r) / aL) * aData.idx) + ',' +
                    parseInt(this.basisFarbe.g + ((this.farbe.g - this.basisFarbe.g) / aL) * aData.idx) + ',' +
                    parseInt(this.basisFarbe.b + ((this.farbe.b - this.basisFarbe.b) / aL) * aData.idx) +
                  ')'
              }
            })
          }
        } else {
          this.diagrammData.data.forEach(aData => {
            if (this.diagrammData.byId[aData.name]) {
              let aObj = this.diagrammData.hasSumCols ? aData.sVals[0] : aData.vals[this.spalte]
              aPatterns[aObj.val] = patternMaker(dg, aObj.val, this.diagrammData.min, this.diagrammData.max, this.blackWhiteFill)
              dg += 1
            }
          })
        }
      }
      return aPatterns
    },
    farbeARGB () {
      return 'rgb(' + this.farbe.r + ',' + this.farbe.g + ',' + this.farbe.b + ')'
    },
    textFarbeRGB () {
      return 'rgb(' + this.textFarbe.r + ',' + this.textFarbe.g + ',' + this.textFarbe.b + ')'
    },
    orderedFarbsets () {
      let aPat = []
      let useFarbensets = this.daten.mainData.basis.farbensets
      let cycleFarbenSet = this.farbenSet || 0
      if (this.excelJSON.optionen.farben && this.excelJSON.optionen.farben.length > 0) {
          useFarbensets = this.excelJSON.optionen.farben.map(f => this.daten.mainData.basis.farbensets[f] || this.daten.mainData.basis.farbensets[0])
          cycleFarbenSet = 0
          // console.log('Farben', this.excelJSON.optionen.farben, useFarbensets)
        }
      for (let i = 0; i < useFarbensets.length; i++) {
        aPat.push(useFarbensets[cycleFarbenSet].a)
        cycleFarbenSet += 1
        if (cycleFarbenSet >= useFarbensets.length) {
          cycleFarbenSet = 0
        }
      }
      // console.log('aPat', aPat)
      return aPat
    },
    kHoverVals () {
      return this.kHover.vals ? this.kHover.vals.filter(v => v.val && ['kox', 'koy', 'homepage', 'hs-schlüssel', 'hochschule'].indexOf(v.name.toLowerCase()) < 0) : []
    },
    skCircle () {
      return this.excelJSON.optionen.spezial && this.excelJSON.optionen.spezial.karte && this.excelJSON.optionen.spezial.karte.indexOf('skc') > -1
    },
    sonderkarteTextFx() {
      // console.log('sonderkarteTextFx', this.diagrammData.byId)
      let aData = {}
      let txtWidth = 10
      Object.keys(this.diagrammData.byId).forEach(mId => {
        let aVals = this.valG0(this.diagrammData.byId[mId].vals)
        let maxHeight = (this.mapData.byId[mId] && this.mapData.byId[mId].mHeight) ? this.mapData.byId[mId].mHeight : 50
        // maxHeight = 30
        if (maxHeight < (this.fontSize / this.mapScale) * 1.05 + 4) {
          maxHeight = (this.fontSize / this.mapScale) * 1.05 + 4
        }
        let y = -3
        let cols = aVals.length > 1 ? Math.ceil(Math.abs(-(this.fontSize / this.mapScale) * 1.05 * (aVals.length + 1) - aVals.length + 2 + 3) / maxHeight) : 1
        let x = -(txtWidth * (cols - 1)) / 2
        // console.log(maxHeight, Math.abs(-(this.fontSize / this.mapScale) * 1.05 * (aVals.length + 1) - aVals.length + 2 + 3), cols)
        aData[mId] = aVals.map((l) => {
          let aL = l
          y += (this.fontSize / this.mapScale) * 1.05 + 2
          if (y > maxHeight) {
            y = -3 + (this.fontSize / this.mapScale) * 1.05 + 2
            x += txtWidth
          }
          aL.x = x
          aL.y = -y
          return aL
        })
      })
      // console.log('sonderkarteTextFx', aData)
      return aData
    }
  },
  watch: {
    diagrammData () {
      this.refresh = true
    },
    excelJSON () {
      this.hiddenRows = {}
    },
    update (nVal) {
      if (nVal) {
        this.refresh = true
      }
    },
    active (nVal) {
      if (nVal) {
        this.$nextTick(() => {
          this.refresh = true
          this.rowsDataTrys = 0
        })
      }
    },
    refresh (nVal) {
      if (nVal) {
        this.$nextTick(() => {
          this.refresh = false
          this.resize()
        })
      }
    },
    refresh2 (nVal) {
      if (nVal) {
        if (this.zoomFunc) {
          this.zoomFunc.destroy()
          this.zoomFunc = null
        }
        this.$nextTick(() => {
          this.refresh2 = false
        })
      } else {
        this.$nextTick(() => {
          if (this.zoomMode) {
            if (!this.zoomFunc && this.$refs.svg) {
              this.zoomFunc = svgPanZoom('.svg', {
                viewportSelector: this.$refs.zoomArea,
                zoomEnabled: true,
                controlIconsEnabled: false,
                fit: true,
                center: true,
                minZoom: 0.5,
                maxZoom: 5,
                onZoom: (z) => {
                  this.zoomFacN = z || 1
                }
              })
            }
          } else {
            if (this.zoomFunc) {
              this.zoomFunc.destroy()
              this.zoomFunc = null
            }
          }
          // console.log('zoomFunc', this.zoomFunc, this.$refs.zoomArea)
        })
      }
    },
    mapData () {
      this.updateDdFx()
    }
  },
  components: {
    SvgText
  }
}
</script>

<style scoped>
.svgContainer {
  position: relative;
}
.svg {
  display: block;
}
.karte-tooltip {
  position: fixed;
  top: 10px;
  left: 200px;
  width: 33vw;
  pointer-events: none;
  color: #666;
  font-size: 1.5rem;
  z-index: 10;
}
.karte-tooltip a, .karte-tooltip span {
  font-size: 1.5rem;
}
.karte-tooltip h3 {
  font-size: 1.8rem;
  color: #666;
}
.karte-tooltip > div {
  position: absolute;
  left: 5px;
  bottom: 5px;
  background: #fff;
  box-shadow: 2px 2px 6px 2px rgba(0,0,0,0.3);
  padding: 4px 8px;
  max-width: 33vw;
}
/* .karte-tooltip-value.selected {
  font-weight: bold;
} */
.mloc > path.hover {
  stroke: #000;
}
.mloc >>> text, .mloct >>> text {
  pointer-events: none;
}
.npevent {
  pointer-events: none;
}
.koordinaten {
  cursor: pointer;
}
.koordinaten circle.hover {
  transform: scale(1.5);
}
.kt-url {
  line-break: anywhere;
}
.koordinaten-tooltip h3 {
  margin-bottom: 1rem;
}
.spezial-infos > g:not(.sum):not(.si-ai), .koordinaten-infos > g {
  cursor: pointer;
}
.spezial-infos > g:not(.sum):not(.si-ai):hover > text, .koordinaten-infos > g:hover > text {
  cursor: pointer;
  text-decoration: underline;
}
.spezial-infos > g.unselected:not(.sum):not(.si-ai) > text, .koordinaten-infos > g.unselected > text {
  text-decoration: line-through;
}
.spezial-infos > g.unselected:not(.sum):not(.si-ai) > circle, .koordinaten-infos > g.unselected > circle {
  opacity: 0.33;
}
@media (max-width: 700px) {
  .svgContainer {
    overflow: auto;
  }
}
</style>